<div class="native-container">
  <div class="savebtn" *ngIf="nativeSoilData && nativeSoilData.length && nativeSoilData.length > 1">
    <button mat-button class="btn-action btn-update" (click)="saveNativeSoil()">SAVE</button>
  </div>
  <mat-tab-group animationDuration="0ms" (selectedTabChange)="onTabChange($event)">
    <mat-tab *ngFor="let tab of filteredTabs">
      <ng-template mat-tab-label>
        <div class="tab-wrapper">
          <div class="tab-label" [class.active-tab]="isTabActive(tab.id)">{{ tab.tabName | uppercase }}</div>
          <div class="vertical-tab-indicator" [ngClass]="{ active: isTabActive(tab.id) }"></div>
        </div>
      </ng-template>
      <app-admin-grid-table
        [dataSource]="nativeSoilData"
        *ngIf="nativeSoilData && nativeSoilData.length && nativeSoilData.length > 1" [appRoles]="appRoles"></app-admin-grid-table>
    </mat-tab>
  </mat-tab-group>
</div>
