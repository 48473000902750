import { Component, Input, ChangeDetectionStrategy, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { IBilling } from 'src/app/shared/models/i-billing.modal';
import { ICompany } from 'src/app/shared/models/i-company.modal';
import { IProject } from 'src/app/shared/models/i-project.modal';
import { ReviewPayload } from 'src/app/shared/models/worksheet-checkbox.model';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-company-contact-information',
  templateUrl: './company-contact-information.component.html',
  styleUrls: ['./company-contact-information.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanyContactInformationComponent implements OnInit {
  @Input() data: ICompany;
  @Input() billing: IBilling;
  @Input() project: IProject;
  @Input() formData: any;
  @Input() link: string;
  @Input() enableEditButton: boolean | null;
  @Input() returnUrl: string | null;
  @Input() samples: ReviewPayload;
  userAccount: any;
  userRoles: string[] = [];
  @Output() submitForm: EventEmitter<ReviewPayload> = new EventEmitter<ReviewPayload>();

  companyPanelOpenState = false;
  panelOpenState = false;
  showHide = false;
  viewForm = false;
  form: FormGroup;
  constructor(private fb: FormBuilder, public userService: UserService) {
    this.form = this.fb.group({
      companyInfo: this.fb.group({
        companyName: [false],
        companyContactName: [false],
        companyEmail: [false],
        companyAddress: [false],
      }),
      billingInfo: this.fb.group({
        billingName: [false],
        billingContactName: [false],
        billingEmail: [false],
        billingAddress: [false],
      }),
      projectInfo: this.fb.group({
        projectName: [false],
        projectLocation: [false],
        projectJobNo: [false],
        projectNumber: [false],
      }),
    });
  }

  ngOnInit(): void {
    this.showHide = this.data?.id === null;
    const editViewSubmission = sessionStorage.getItem('editViewSubmission');
    if (this.data?.id !== null && editViewSubmission === 'true') {
      this.viewForm = true;
    } else if (editViewSubmission === 'true') {
      this.viewForm = true;
    } else {
      this.viewForm = false;
    }
    if (this.samples) {
      this.form.patchValue({
        companyInfo: {
          companyName: this.samples.companyInfo?.companyName || false,
          companyContactName: this.samples.companyInfo?.companyContactName || false,
          companyEmail: this.samples.companyInfo?.companyEmail || false,
          companyAddress: this.samples.companyInfo?.companyAddress || false,
        },
        billingInfo: {
          billingName: this.samples.billingInfo?.billingName || false,
          billingContactName: this.samples.billingInfo?.billingContactName || false,
          billingEmail: this.samples.billingInfo?.billingEmail || false,
          billingAddress: this.samples.billingInfo?.billingAddress || false,
        },
        projectInfo: {
          projectName: this.samples.projectInfo?.projectName || false,
          projectLocation: this.samples.projectInfo?.projectLocation || false,
          projectJobNo: this.samples.projectInfo?.projectJobNo || false,
          projectNumber: this.samples.projectInfo?.projectNumber || false,
        },
      });
    }
    this.form.valueChanges.subscribe((value) => {
      this.submitForm.emit(value);
    });
    this.submitForm.emit(this.form.value);

    this.userService.userRole?.subscribe(
      (roles) => {
        this.userRoles = roles || [];
      },
      (error) => console.error('userRole subscription error:', error),
    );

    this.userService.userAccount?.subscribe(
      (account) => {
        this.userAccount = account;
      },
      (error) => console.error('userAccount subscription error:', error),
    );
  }

  roleCheck(roles: string[]): boolean {
    if (!this.userAccount) {
      return true;
    }
    return roles.some((role) => this.userRoles.includes(role));
  }

  onCompanyPanelOpened() {
    this.companyPanelOpenState = true;
  }

  onCompanyPanelClosed() {
    this.companyPanelOpenState = false;
  }

  onPanelOpened() {
    this.panelOpenState = true;
  }

  onPanelClosed() {
    this.panelOpenState = false;
  }

  shouldShowCheckbox(): boolean {
    return !this.showHide && !this.viewForm && this.roleCheck(['Admin', 'Manager']);
  }
}
