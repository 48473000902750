import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ISetStatus } from '../../../features/models/istatus-response.modal';
import { messagesConfig } from '../../constants/message.constants';
import { IDropdown } from '../../models/i-dropdown-type.modal';
import { PrecastService } from '../../services/precast.service';

@Component({
  selector: 'app-set-status',
  templateUrl: './set-status.component.html',
  styleUrls: ['./set-status.component.scss'],
})
export class SetStatusComponent implements OnInit, OnDestroy {
  public setStatusSubscription = Subscription.EMPTY;
  public lookupDropdown: IDropdown[] = [];
  public statusForm!: FormGroup;
  public config: any; // TODO: Need to remove this occurence of any

  constructor(
    private precastService: PrecastService,
    private matDialogRef: MatDialogRef<SetStatusComponent>,
    @Inject(MAT_DIALOG_DATA) data: MatDialog,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
  ) {
    this.config = data;
  }

  ngOnInit(): void {
    this.statusForm = this.formBuilder?.group({
      projectId: [this.config?.projectId, []],
      statusId: ['', [Validators?.required]],
      note: [null],
    });
    this.fetchLookup();
  }

  public updateStatus(): void {
    if (this.statusForm?.valid) {
      this.setStatusSubscription = this.precastService?.setStatus(this.statusForm?.value as ISetStatus)?.subscribe({
        next: (response: ISetStatus) => {
          this.snackBar.open(messagesConfig.setStatusMessage.message, 'DONE', {
            duration: 3000,
            verticalPosition: 'top',
            panelClass: ['default-snackbar'],
          });
          this.matDialogRef?.close(response);
        },
        error: () => {},
      });
    }
  }

  public closeDialog(mode: string): void {
    this.matDialogRef?.close(mode);
  }

  private fetchLookup(): void {
    this.precastService?.fetchProjectsLookup()?.subscribe({
      next: (lookupResponse: IDropdown[]) => {
        this.lookupDropdown = lookupResponse.filter((status) => {
          if (
            status.label === 'Initial Review'
            || status.label === 'Management Review'
            || status.label === 'Management Approved'
          ) {
            return true;
          }
          return false;
        });
      },
    });
  }

  ngOnDestroy() {
    this.setStatusSubscription?.unsubscribe();
  }
}
