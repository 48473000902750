import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { INotification } from '../models/i-notification.modal';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  constructor(private http: HttpClient) {}

  public fetchNotifications(): Observable<INotification[]> {
    return this.http.get<INotification[]>(`${environment.baseUrl}notification/getall?page=1&pageSize=500`);
  }

  public markNotificationAsRead(notification: INotification): Observable<INotification> {
    return this.http.patch<INotification>(
      `${environment.baseUrl}notification/markasread/${notification.id}`,
      notification,
    );
  }
}
