import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IProject } from 'src/app/shared/models/i-project.modal';
import { ICompany } from 'src/app/shared/models/i-company.modal';
import { HttpService } from 'src/app/shared/services/http.service';
import { IProjectTestData } from 'src/app/shared/models/i-test-data.modal';
import { ReviewPayload } from 'src/app/shared/models/worksheet-checkbox.model';
import { IUser } from '../../models/iuser.modal';
import { PrecastWorksheet } from '../models/precast-worksheet.modal';
import { WorksheetResponse } from '../models/worksheet-response.modal';

@Injectable({
  providedIn: 'root',
})
export class WorksheetService {
  constructor(private http: HttpClient, private httpService: HttpService) {}

  public generatePrecastWorksheet(projectId: string): Observable<any> {
    return this.httpService.get(`precastws/generateworksheet/${projectId}`);
  }

  public generateNativeSoilWorksheet(projectId: string): Observable<any> {
    return this.httpService.get(`nativesoilws/generateworksheet/${projectId}`);
  }

  public fetchWorksheetbyId(projectId: string): Observable<PrecastWorksheet[]> {
    return this.httpService.get(`precastws/getworksheet/${projectId}`).pipe(map((w) => this.mapUser(w)));
  }

  public fetchWorksheetRowbyId(projectId: string): Observable<PrecastWorksheet[]> {
    return this.httpService.get(`precastws/getworksheetrow/${projectId}`);
  }

  public fetchNativeSoilWorksheet(projectId: string): Observable<WorksheetResponse> {
    return this.httpService.get(`worksheet/getnativesoilworksheets/${projectId}`);
  }

  public addWorksheet(payload: PrecastWorksheet): Observable<PrecastWorksheet> {
    return this.http.post<PrecastWorksheet>(`${environment.baseUrl}precastws/addworksheetrow`, payload);
  }

  public updateWorksheet(payload: PrecastWorksheet): Observable<PrecastWorksheet> {
    return this.http.post<PrecastWorksheet>(`${environment.baseUrl}precastws/updateworksheetrow`, payload);
  }

  public deleteWorksheet(worksheetId: string): Observable<object> {
    return this.http.delete(`${environment.baseUrl}precastws/deleteworksheetrow/${worksheetId}`);
  }

  public fetchLabTechs(): Observable<IUser[]> {
    return this.httpService.get('user/getlabtechs');
  }

  public fetchProjectById(projectId: string): Observable<IProject> {
    return this.httpService.get(`project/${projectId}`);
  }

  public fetchCompanyById(projectId: string): Observable<ICompany> {
    return this.httpService.get(`company/getbyprojectid/${projectId}`);
  }

  public assignToLabtech(payload: any, workSheetType: string): Observable<PrecastWorksheet> {
    let url = '';
    switch (workSheetType) {
      case 'precast':
        url = `${environment.baseUrl}precastws/assignlabtech`;
        break;
      case 'receivedSheet':
        url = `${environment.baseUrl}nativesoilws/assignasreceivedrow`;
        break;
      case 'moistureWorksheets':
        url = `${environment.baseUrl}nativesoilws/assignmoisturerow`;
        break;
      case 'workSheet':
        url = `${environment.baseUrl}nativesoilws/assignnativerow`;
        break;
      case 'proctorWorksheets':
        url = `${environment.baseUrl}nativesoilws/assignproctorrow`;
        break;
      default:
        url = '';
        break;
    }
    return this.http.post<PrecastWorksheet>(url, payload);
  }

  public assignProject(payload: any, workSheetType: string) {
    let url = '';
    if (workSheetType === 'precast') {
      url = 'precastws/assignallworksheets';
    } else {
      url = 'nativesoilws/assignallworksheets';
    }
    return this.httpService.post(url, payload);
  }

  public updateProjectData(projectId: string | undefined, payload: IProjectTestData): Observable<IProject> {
    return this.httpService.patch(`project/updateprojecttestdata/${projectId}`, payload);
  }

  private mapUser(rows: any[]): any[] {
    rows.forEach((element) => {
      if (element.assignedToId) {
        element.Tech = element.assignedTo.userName;
      }
    });
    return rows;
  }

  public getPrecastViewSubmissionWorksheet(projectId: string): Observable<ReviewPayload> {
    return this.httpService.get(`dashboard/getpcmissinginfo/${projectId}`);
  }

  public addPrecastViewSubmissionWorksheet(projectId: string, payload: ReviewPayload): Observable<ReviewPayload> {
    return this.http.post<ReviewPayload>(
      `${environment.baseUrl}dashboard/addupdatepcmissinginfo/${projectId}`,
      payload,
    );
  }

  public getNativeSoilViewSubmissionWorksheet(projectId: string): Observable<ReviewPayload> {
    return this.httpService.get(`dashboard/getnsmissinginfo/${projectId}`);
  }

  public addNativeSoilViewSubmissionWorksheet(projectId: string, payload: ReviewPayload): Observable<ReviewPayload> {
    return this.http.post<ReviewPayload>(
      `${environment.baseUrl}dashboard/addupdatensmissinginfo/${projectId}`,
      payload,
    );
  }

  public sendEmailNotification(projectId: string, payload: string): Observable<string> {
    return this.http.post<string>(`${environment.baseUrl}notification/sendmissinginfomail/${projectId}`, payload);
  }
}
