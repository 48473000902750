
<h2 mat-dialog-title class="mat-h1 warning-title">
  <div class="popup-icon">
    <!-- TODO: Why is this condition like this  -->
  <mat-icon class="alert-icon"   
    aria-hidden="false">
  </mat-icon>
</div>
Error
</h2>
<mat-dialog-content> 
  <p class="mat-subtitle-1">Sorry, Something went wrong!'
    <br>Please try refreshing the page, if issue persists contact <a  href="mailto:support@geothermusa.com">support</a> team.</p>
  <span  class="error-id" *ngIf="config?.errorID">Error ID: {{config?.errorID}}</span>
</mat-dialog-content>
<mat-divider></mat-divider>
<mat-dialog-actions align="end">
  <button mat-raised-button color="primary" (click)="closeDialog()">CLOSE</button>
</mat-dialog-actions>
