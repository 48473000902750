import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MultipleEmailValidator } from 'src/app/shared/validators/multiple-email.validator';
import { ICompanyData } from '../../../../shared/models/i-company.modal';

@Component({
  selector: 'app-company-information',
  templateUrl: './company-information.component.html',
  styleUrls: ['./company-information.component.scss'],
})
export class CompanyInformationComponent implements OnInit {
  @Output() companyData: EventEmitter<ICompanyData> = new EventEmitter();
  @Input() submissionType: string;
  @Input() data: any;
  makeSampleEditable = true;
  @Input() set makeEditable(value: boolean) {
    this.makeSampleEditable = value;
  }

  initialLoad = true;
  companyFormGroup: FormGroup;

  constructor(private fb: FormBuilder, public dialog: MatDialog) {
    this.companyFormGroup = this.fb.group({
      companyId: [''],
      projectId: [''],
      bId: [''],
      name: ['', Validators.required],
      contactNumber: ['', Validators.required],
      emails: ['', [Validators.required, MultipleEmailValidator.comaSeperatedEmailValidator()]],
      contactName: ['', Validators.required],
      streetAddress: [''],
      state: [''],
      city: [''],
      postalCode: [''],
      reportSubmissionDate: [''],
      hasBillingInfo: [false],
      invoice: [''],
      bcontactNumber: [''],
      bemails: ['', MultipleEmailValidator.comaSeperatedEmailValidator()],
      bstreetAddress: [''],
      bstate: [''],
      bcity: [''],
      bpostalCode: [''],
      projectName: ['', Validators.required],
      projectLocation: [''],
      jobNumber: [''],
      projectNumber: [''],
      geothermField: [{
        value: false, disabled: !this.makeSampleEditable,
      }],
      instructionsConfirmed: [false],
      rushTesting: [false],
      preApproval: [{
        value: false, disabled: !this.makeSampleEditable,
      }],
      projectStatusTypeId: [null],
      bContactName: ['', Validators.required],
      bContactEmail: ['', [Validators.required, MultipleEmailValidator.comaSeperatedEmailValidator()]],
      // dueDate: [''],
      inHouseIdPrefix: ['', [Validators.pattern('^[A-Z]{0,2}$')]],
    });
  }

  ngOnInit(): void {
    if (this.data) {
      if (this.data.contactNumber) {
        this.data.contactNumber = this?.data?.contactNumber.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1)-$2-$3');
      }

      if (this.data.bcontactNumber) {
        this.data.bcontactNumber = this?.data?.bcontactNumber
          .slice(0, 10)
          .replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1)-$2-$3');
      }
      this.companyFormGroup.patchValue(this.data);
      this.companyFormGroup.markAsDirty();
    }
    this.companyFormGroup?.valueChanges?.subscribe((frmGroup) => {
      let validForm = this.companyFormGroup?.valid;
      // checking null value
      const v = this.companyFormGroup?.value?.contactNumber
        ? this.companyFormGroup.value.contactNumber.replace(/[^0-9]/g, '')
        : '';

      if (v.length < 10) {
        this.companyFormGroup?.controls['contactNumber'].setErrors({
          validContactNumber: false,
        });
        validForm = false;
      }
      // checking null value
      const bv = this.companyFormGroup?.value?.bcontactNumber
        ? this.companyFormGroup.value.bcontactNumber.replace(/[^0-9]/g, '')
        : '';
      if (this.companyFormGroup.value.bcontactNumber) {
        if (bv.length > 1 && bv.length < 10) {
          this.companyFormGroup?.controls['bcontactNumber'].setErrors({
            validbContactNumber: false,
          });
          validForm = false;
        }
      }

      this.companyData?.emit({
        value: frmGroup,
        dirty: this.companyFormGroup?.dirty,
        valid: validForm,
      });
    });
    this.checkHasBillingInfo();
    this.setFormControlState();
    this.initialLoad = false;
  }

  get emailsError(): any {
    return this.companyFormGroup?.controls['emails']?.errors
      ? this.companyFormGroup?.controls['emails']?.errors
      : false;
  }

  get bemailsError(): any {
    return this.companyFormGroup?.controls['bemails']?.errors
      ? this.companyFormGroup?.controls['bemails']?.errors
      : false;
  }

  get contactEmailsError(): any {
    return this.companyFormGroup?.controls['bContactEmail']?.errors
      ? this.companyFormGroup?.controls['bContactEmail']?.errors
      : false;
  }

  get contactNumberErrors(): any {
    return this.companyFormGroup?.controls['contactNumber']?.errors
      ? this.companyFormGroup?.controls['contactNumber']?.errors
      : false;
  }

  get bcontactNumberErrors(): any {
    return this.companyFormGroup?.controls['bcontactNumber']?.errors
      ? this.companyFormGroup?.controls['bcontactNumber']?.errors
      : false;
  }

  checkHasBillingInfo(): void {
    const check = this.companyFormGroup?.controls['hasBillingInfo']?.value;
    if (check) {
      this.companyFormGroup?.controls['bContactName']?.removeValidators(Validators.required);
      this.companyFormGroup?.controls['bContactEmail']?.removeValidators(Validators.required);
      if (!this.initialLoad) {
        this.clearBillingInfo();
      }
    } else {
      this.companyFormGroup?.controls['bContactName']?.setValidators(Validators.required);
      this.companyFormGroup?.controls['bContactEmail']?.setValidators([Validators.required, MultipleEmailValidator.comaSeperatedEmailValidator()]);
    }
    this.companyFormGroup?.controls['bContactName']?.updateValueAndValidity();
    this.companyFormGroup?.controls['bContactEmail']?.updateValueAndValidity();
  }

  clearBillingInfo(): void {
    this.companyFormGroup?.controls['invoice']?.setValue('');
    this.companyFormGroup?.controls['bemails']?.setValue('');
    this.companyFormGroup?.controls['bcontactNumber']?.setValue('');
    this.companyFormGroup?.controls['bContactName']?.setValue('');
    this.companyFormGroup?.controls['bContactEmail']?.setValue('');
    this.companyFormGroup?.controls['bstreetAddress']?.setValue('');
    this.companyFormGroup?.controls['bcity']?.setValue('');
    this.companyFormGroup?.controls['bstate']?.setValue('');
    this.companyFormGroup?.controls['bpostalCode']?.setValue('');
  }

  setFormControlState(): void {
    const geothermFieldControl = this.companyFormGroup.get('geothermField') as FormControl;
    if (this.makeSampleEditable) {
      geothermFieldControl.enable();
    } else {
      geothermFieldControl.disable();
    }
  }
}
