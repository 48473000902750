<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<form #noteForm="ngForm">
    <h2 mat-dialog-title class="mat-h2">
      Missing/Issues Sample Data
      <button mat-icon-button class="close-button" (click)="closeDialog()">
        <mat-icon>close</mat-icon>
      </button>
    </h2>
    <mat-dialog-content>
      <div class="row">
        <div class="col-12 notes">
          <mat-label class="mat-subtitle-2 sub-title"> Additional Notes to the client </mat-label>
        </div>
        <div class="col-12 mt-16">
          <mat-label class="mat-subtitle-2">Additional Notes</mat-label>
          <mat-form-field appearance="outline" class="text-area">
            <textarea
              class="client-textarea-popup"
              matInput
              rows="3"
              name="additionalNotes"
              [(ngModel)]="additionalNotes"
              maxlength="500"
              
              placeholder="Max 500 characters"
              oninput="this.value = this.value.replace(/[^A-Za-z0-9-,.;'&/.() ]|^ /g,'')"></textarea>
          </mat-form-field>
        </div>
        <div class="file-position">
          <a
            href="javascript:void(0)"
            class="testing"
            (click)="fileInput.click()">
            <span><img src="/assets/icons/add_red.svg" alt="Add Attachments" />&nbsp;Add File(s)</span>
            <input
              #fileInput
              title=""
              type="file"
              multiple
              (change)="onFileSelected($event)"
              style="display: none" />
          </a>
        </div>
        <div id="fileupload">
          <!-- file upload progress-->
          <div class="files-list">
            <div class="single-file" *ngFor="let file of uploadedFiles; let i = index">
              <div class="file-icon" style="width: 50px">
                <mat-icon> insert_drive_file</mat-icon>
              </div>
              <div class="info">
                <h4 class="name">
                  {{ file.file.name }}
                </h4>
                <p class="size">
                  {{ file.file.size | fileSize }}
                </p>
    
                <div class="progress-cont">
                  <div *ngIf="!file.completed && file.progress < 100">
                    <mat-progress-bar mode="determinate" [value]="file.progress"></mat-progress-bar>
                    <p>{{ file.progress.toFixed(0) }}%</p>
                  </div>
                </div>
              </div>
              <div class="delete" (click)="cancelUpload(file)">
                <mat-icon>close</mat-icon>
              </div>
            </div>
          </div> 
          <p class="error-message" *ngIf="errorMessage">{{ errorMessage }}</p>
        </div>
      </div>
    </mat-dialog-content>
    <mat-divider></mat-divider>
    <mat-dialog-actions align="end">
      <button mat-raised-button (click)="closeDialog()">CANCEL</button>
      <button mat-raised-button color="primary" (click)="previewDialog()">
        PREVIEW
      </button>
    </mat-dialog-actions>
  </form>
  