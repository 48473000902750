import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { EventMessage, EventType } from '@azure/msal-browser';
import { MsalBroadcastService } from '@azure/msal-angular';
import { Observable, combineLatest, filter, of, switchMap, take } from 'rxjs';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class RoleGaurd {
  constructor(private userService: UserService, public msalBroadCastService: MsalBroadcastService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
  ): Observable<boolean> {
    const roles = route.data['roles'] as string[];
    const currentRole = this.userService.roleCheck(route.data?.['roles']);
    if (currentRole) {
      return of(currentRole);
    }
    return combineLatest([this.userService.userAccount, this.userService.userRole]).pipe(
      take(1),
      switchMap(([account, userRoles]) => new Observable<boolean>((observer) => {
        const subscription = this.msalBroadCastService.msalSubject$
          .pipe(filter((msg: EventMessage) => msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS))
          .subscribe({
            next: (result: any) => {
              const acc = result?.payload?.account;
              if (account && account.username.indexOf('geothermusa.com') > 1) {
                if (account) {
                  userRoles = acc.idTokenClaims?.roles;
                  if (account && Object.keys(account).length > 0) {
                    const isAuthorized = userRoles?.some((userRole) => roles.includes(userRole)) || false;
                    observer.next(isAuthorized);
                  } else {
                    observer.next(false);
                  }
                }
              }
            },
            error: () => {
              observer.next(false);
            },
            complete: () => {
              observer.complete();
            },
          });
        // Cleanup subscription on unsubscription
        return () => subscription.unsubscribe();
      })),
    );
  }
}
