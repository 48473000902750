<div class="shipment-received-wrapper">
  <h2 mat-dialog-title class="mat-h2">
    Receive Shipment
    <button mat-icon-button class="close-button" (click)="closeDialog('close')">
      <mat-icon>close</mat-icon>
    </button>
  </h2>
  <mat-dialog-content>
    <div class="shipment-received">
      <div class="header-title">
        <mat-label class="mat-h2 bold-title">
          {{ config.projectName }}
        </mat-label>
        <button
          *ngIf="this.config.projectType !== 'Pre Cast' && ShowPrintBtn()"
          mat-raised-button
          class="export-btn"
          (click)="openInNewTab()">
          <img src="/assets/icons/Print-red.svg" class="export-print-btn" alt="print" />
          PRINT
        </button>
      </div>
      <div class="table-container">
        <table
          mat-table
          [dataSource]="dataSource"
          class="mat-elevation-z8"
          *ngIf="this.config.projectType === 'Pre Cast'">
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef style="width: 20%">Status</th>
            <td mat-cell *matCellDef="let element; let i = index">
              <mat-form-field appearance="outline">
                <mat-select
                  placeholder="Please Select"
                  [(value)]="element.sampleStatusTypeId"
                  [disabled]="element.sampleStatusType === 'Received'"
                  (selectionChange)="validateForAll()">
                  <mat-option *ngFor="let sampleStatus of sampleStatuses" [value]="sampleStatus.id">
                    {{ sampleStatus.label }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </td>
          </ng-container>
          <ng-container matColumnDef="sampleId">
            <th mat-header-cell *matHeaderCellDef style="width: 25%">Sample ID</th>
            <td mat-cell *matCellDef="let element">{{ element.sampleId }}</td>
          </ng-container>
          <ng-container matColumnDef="sampleCollectionDate">
            <th mat-header-cell *matHeaderCellDef style="width: 20%">Collection Date</th>
            <td mat-cell *matCellDef="let element">{{ element.sampleCollectionDate | utcToLocal : 'date' }}</td>
          </ng-container>
          <ng-container matColumnDef="materialType">
            <th mat-header-cell *matHeaderCellDef style="width: 20%">Material Type</th>
            <td mat-cell *matCellDef="let element">{{ element.sampleType }}</td>
          </ng-container>
          <ng-container matColumnDef="mixDesign">
            <th mat-header-cell *matHeaderCellDef style="width: 15%">Mix Design</th>
            <td mat-cell *matCellDef="let element">{{ element.mixDesignCode }}</td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayPrecastColumns; sticky: true"></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayPrecastColumns"
            [class.received-row]="row.sampleStatusTypeId === 'd471cab0-df24-4a80-a670-9994fbdf5f6f'"
            [class.missing-row]="row.sampleStatusTypeId === '2320323b-65e5-4270-95ce-6fa221d3c0b5'"
            [class.damaged-row]="row.sampleStatusTypeId === '33de1f0d-8209-4d52-a4c7-18e4918d9932'"></tr>
        </table>

        <table
          mat-table
          id="exportTable"
          [dataSource]="dataSource"
          class="mat-elevation-z8"
          *ngIf="this.config.projectType !== 'Pre Cast'">
          <ng-container matColumnDef="status">
            <!-- Status Column -->
            <th mat-header-cell *matHeaderCellDef style="width: 15%">Status</th>
            <td mat-cell *matCellDef="let element; let i = index">
              <mat-form-field appearance="outline" class="status-column">
                <mat-select
                  placeholder="Please Select"
                  [formControl]="getFormControl(i, 'sampleStatusTypeId')"
                  [(value)]="element.sampleStatusTypeId"
                  (selectionChange)="onStatusChange($event, i)">
                  <mat-option *ngFor="let sampleStatus of sampleStatuses" [value]="sampleStatus.id">
                    {{ sampleStatus.label }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </td>
          </ng-container>
          <!-- SampleId Column -->
          <ng-container matColumnDef="sampleId">
            <th mat-header-cell *matHeaderCellDef style="width: 15%">Sample ID</th>
            <td
              mat-cell
              *matCellDef="let element"
              [matTooltip]="element.sampleId"
              matTooltipClass="custom-tooltip"
              [innerHTML]="element.sampleId | format : 'truncate'"></td>
          </ng-container>
          <!-- SampleType Column -->
          <ng-container matColumnDef="sampleType">
            <th mat-header-cell *matHeaderCellDef style="width: 8%">Sample Type</th>
            <td mat-cell *matCellDef="let element">
              {{ element.sampleType === '6f32ebfe-767a-4f27-a295-9dad300d9ba9' ? 'Tube' : 'Bulk' }}
            </td>
          </ng-container>
          <!-- Depth Column -->
          <ng-container matColumnDef="depth">
            <th mat-header-cell *matHeaderCellDef style="width: 8%">Depth</th>
            <td mat-cell *matCellDef="let element">
              <span *ngIf="!element.stockPile && element.sampleDepth">
                {{ element.sampleDepth }}-{{ element.sampleEndDepth }}
              </span>
              <span *ngIf="element.stockPile"> Stockpile </span>
            </td>
          </ng-container>
          <!-- WeightReceived Column -->
          <ng-container matColumnDef="weightReceived">
            <th mat-header-cell *matHeaderCellDef style="width: 10%" class="text-align">
              <div class="header-weight-received">
                Weight Received<br />
                <span>(gm)</span>
              </div>
            </th>
            <td mat-cell *matCellDef="let element; let i = index" class="text-align">
              <form [formGroup]="getFormGroup(i)">
                <mat-form-field appearance="outline" class="input-form-field">
                  <input
                    matInput
                    [formControl]="getFormControl(i, 'weightReceived')"
                    type="number"
                    (input)="onInput($event)"
                    (change)="validateForAll()"
                    [disabled]="element.sampleStatusTypeId === '2320323b-65e5-4270-95ce-6fa221d3c0b5'" />
                </mat-form-field>
              </form>
            </td>
          </ng-container>
          <!-- WeightUsed Column -->
          <ng-container matColumnDef="weightUsed">
            <th mat-header-cell *matHeaderCellDef style="width: 10%" class="text-align">
              <div class="header-weight-received">
                Weight Used<br />
                <span>(gm)</span>
              </div>
            </th>

            <td mat-cell *matCellDef="let element" class="text-align">{{ element.weightUsed }}</td>
          </ng-container>
          <!-- WeightRemaining Column -->
          <ng-container matColumnDef="weightRemaining">
            <th mat-header-cell *matHeaderCellDef style="width: 10%" class="text-align">
              <div class="header-weight-received">
                Weight Remaining<br />
                <span>(gm)</span>
              </div>
            </th>
            <td mat-cell *matCellDef="let element" class="text-align">{{ element.weightRemaining }}</td>
          </ng-container>
          <!-- Header and Row -->
          <tr mat-header-row *matHeaderRowDef="displayNativeColumns; sticky: true"></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayNativeColumns"
            [class.received-row]="row.sampleStatusTypeId === 'd471cab0-df24-4a80-a670-9994fbdf5f6f'"
            [class.missing-row]="row.sampleStatusTypeId === '2320323b-65e5-4270-95ce-6fa221d3c0b5'"
            [class.damaged-row]="row.sampleStatusTypeId === '33de1f0d-8209-4d52-a4c7-18e4918d9932'"></tr>
        </table>
      </div>
      <div class="col-12 mt-16">
        <mat-label class="mat-subtitle-2">Additional Notes</mat-label>
        <mat-form-field appearance="outline" class="text-area">
          <textarea
            class="textarea-popup"
            matInput
            rows="3"
            [(ngModel)]="additionalNotes"
            [ngModelOptions]="{ standalone: true }"
            maxlength="500"
            placeholder="Max 500 characters"
            oninput="this.value = this.value.replace(/[^A-Za-z0-9-,.;'&/.() ]|^ /g,'')"></textarea>
        </mat-form-field>
      </div>
    </div>
  </mat-dialog-content>
  <div>
    <mat-divider></mat-divider>
    <mat-dialog-actions align="end">
      <button mat-raised-button (click)="closeDialog('close')">CANCEL</button>
      <button mat-raised-button color="primary" [disabled]="!saveEnabled" (click)="updateStatus()">SAVE</button>
    </mat-dialog-actions>
  </div>
</div>
