import { Component, Input, OnInit } from '@angular/core';
import { AdminDashboard, AppRole } from 'src/app/shared/models/admin-dashboard.modal';
import { MessageModalComponent } from 'src/app/shared/components/message-modal/message-modal.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { IMessage } from 'src/app/shared/models/message.modal';
import { messagesConfig } from 'src/app/shared/constants/message.constants';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AdminService } from '../admin.service';

@Component({
  selector: 'app-precast-settings',
  templateUrl: './precast-settings.component.html',
  styleUrls: ['./precast-settings.component.scss'],
})
export class PrecastSettingsComponent implements OnInit {
  @Input() tabId: string;
  preCastData: AdminDashboard[] = [];
  appRoles: AppRole[] = [];
  successMessage: IMessage;
  public updateSuccess: MatDialogRef<MessageModalComponent>;

  constructor(
    private adminService: AdminService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
  ) {}

  ngOnInit(): void {
    this.fetchPreCast();
  }

  fetchPreCast(): void {
    this.adminService.getTabConfigItems(this.tabId).subscribe({
      next: (response: AdminDashboard[]) => {
        this.preCastData = response.sort((a, b) => a.sortOrder - b.sortOrder);
      },
    });
  }

  saveBtn(): void {
    this.adminService.updateSettings(this.preCastData, this.tabId).subscribe({
      next: (response: AdminDashboard[]) => {
        this.preCastData = response;
        this.snackBar.open(messagesConfig.changeSuccessMeaage.message, 'DONE', {
          duration: 3000,
          verticalPosition: 'top',
          panelClass: ['default-snackbar'],
        });
        this.fetchPreCast();
      },
      error: (error) => console.error('Error API:', error),
    });
  }
}
