<div class="native-soil-edit-wrapper" id="native-soil-edit-wrapper">
  <h2 mat-dialog-title class="mat-h2">
    <span>{{ title }}</span>
    <button mat-icon-button class="close-button" (click)="closeDialog()">
      <mat-icon>close</mat-icon>
    </button>
  </h2>
  <mat-dialog-content *ngIf="WTypeActive.received">
    <div [formGroup]="form">
      <div class="block color-black font-wt-500">
        <img *ngIf="workSheet.status" alt="tag" class="ml-8 mr-8 tick" src="../../../../assets/icons/Check.svg" />
        <h1 class="mat-h1">{{ headerText }}</h1>
      </div>
      <div>
        <mat-error *ngIf="titleError">In House ID is duplicate. Please enter unique In House ID.</mat-error>
      </div>
      <div>
        <mat-error *ngIf="dryWetWeighterror">Dry weight should be less than the Wet weight.</mat-error>
      </div>
      <mat-divider></mat-divider>
      <div class="block color-black font-wt-500 pl-16">
        <div class="pb-8 pt-8">
          <div class="row">
            <div class="col-md-10 pt-8">
              <img alt="tag" class="ml-8 mr-8" src="../../../../assets/icons/Tag.svg" />
              <span>In-House ID</span>
            </div>
            <div class="col-md-2 text-r">
              <input
                placeholder="0"
                matInput
                class="input-text mat-input-border"
                required
                formControlName="inHouseId"
                maxlength="50"
                (keyup)="inhouseKeyup()" />
            </div>
          </div>
        </div>
      </div>
      <mat-divider></mat-divider>
      <div class="block color-black font-wt-500 pl-16">
        <div class="pb-8 pt-8">
          <div class="row">
            <div class="col-md-10 pt-8">
              <img alt="tag" class="ml-8 mr-8" src="../../../../assets/icons/scale.svg" />
              <span>Wet Weight (gm)</span>
            </div>
            <div class="col-md-2 text-r">
              <input
                placeholder="0"
                matInput
                class="input-text mat-input-border"
                required
                formControlName="wetWeight"
                appNumbersOnly
                [allowCommas]="false"
                maxlength="5"
                (keyup)="checkForWetWightReceived()" />
            </div>
          </div>
        </div>
      </div>
      <mat-divider></mat-divider>
      <div class="block color-black font-wt-500 pl-16 pt-8 pb-8">
        <span>Please place sample in oven for next step</span>
      </div>
      <mat-divider></mat-divider>
      <div class="block color-black font-wt-500 pl-16">
        <div class="pt-8 pb-8">
          <div class="row">
            <div class="col-md-10 pt-8">
              <img alt="tag" class="ml-8 mr-8" src="../../../../assets/icons/scale.svg" />
              <span>Dry Weight (gm)</span>
            </div>
            <div class="col-md-2 text-r">
              <input
                placeholder="0"
                matInput
                class="input-text mat-input-border"
                required
                formControlName="dryWeight"
                appNumbersOnly
                [allowCommas]="false"
                maxlength="5"
                (keyup)="checkForWetWightReceived()" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-content *ngIf="WTypeActive.moisture">
    <div *ngIf="modalType === 'edit'">
      <div class="block color-black font-wt-500" style="display: flex; justify-content: space-between">
        <span class="m-h-span">
          <img *ngIf="workSheet?.status" alt="tag" class="ml-8 mr-8 tick" src="../../../../assets/icons/Check.svg" />
          <h1 class="mat-h1">{{ headerText }}</h1>
          <div class="clearfix"></div>
        </span>
        <span class="h-label bg-reuse" *ngIf="workSheet?.reUse">Re-Use Sample</span>
      </div>
      <mat-divider></mat-divider>
      <div class="block color-black font-wt-500 pl-16">
        <div class="pb-16 pt-8">
          <div class="row">
            <div class="col-md-11 pb-8 pt-8">
              <div>
                <img alt="tag" class="ml-8 mr-8" src="../../../../assets/icons/scale.svg" />
                <span>Measure {{ workSheet.drySoil }}gm of sample into container</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <mat-divider></mat-divider>
      <div class="block color-black font-wt-500 pl-16">
        <div class="pb-16 pt-8">
          <div class="row">
            <div class="col-md-11 pb-8 pt-8">
              <img alt="tag" class="ml-8 mr-8" src="../../../../assets/icons/Drop.svg" />
              <span>Add {{ workSheet.addWater }} gm Water</span>
            </div>
          </div>
        </div>
      </div>
      <mat-divider></mat-divider>
      <div class="block color-black font-wt-500 pl-16" *ngIf="workSheet?.reUse">
        <div class="pb-16 pt-8">
          <div class="row">
            <div class="col-md-11 pt-8">
              <img alt="tag" class="ml-8 mr-8" src="../../../../assets/icons/Refresh.svg" />
              <span>Place remaining soil in bag</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="modalType === 'mswt'">
      <div [formGroup]="form">
        <div class="block color-black font-wt-500">
          <h1 class="mat-h1">{{ headerText }}</h1>
        </div>
        <diV>
          <mat-error *ngIf="moistureInValid">All moisture(S) should be less than that from form.</mat-error>
        </diV>
        <div>
          <mat-error *ngIf="asReceivedInComplete"
            >Please update As recvd sheet to get correct moisture value.</mat-error
          >
        </div>
        <mat-divider></mat-divider>
        <div class="block color-black font-wt-500 pl-16">
          <div class="pb-8 pt-8">
            <div class="row">
              <div class="col-md-10 pt-8">
                <img alt="tag" class="ml-8 mr-8" src="../../../../assets/icons/scale.svg" />
                <span>Amount of dry soil to use per test (gm)</span>
              </div>
              <div class="col-md-2 text-r">
                <input
                  placeholder="0"
                  matInput
                  class="input-text mat-input-border"
                  required
                  formControlName="drySoil"
                  appNumbersOnly
                  [allowCommas]="false"
                  maxlength="5" />
              </div>
            </div>
          </div>
        </div>
        <mat-divider></mat-divider>
        <div class="block color-black font-wt-500 pl-16">
          <div class="pb-8 pt-8">
            <div class="row">
              <div class="col-md-4 pt-8">
                <img alt="tag" class="ml-8 mr-8" src="../../../../assets/icons/List.svg" />
                <span>
                  Test(s) by M/C %
                  <img
                    alt="info Icon"
                    class="star-mar-top"
                    #tooltip="matTooltip"
                    matTooltip="Max. 4 digits allowed, including one place of decimal. e.g. XXX.X"
                    src="../../../../assets/icons/Alert-grey.svg"
                /></span>
              </div>
              <div class="col-md-6 text-r">
                <span style="width: 25%; padding-left: 1rem; display: inline-block">
                  <input
                    matInput
                    class="input-text mat-input-border"
                    required
                    formControlName="moistureContent"
                    placeholder="e.g. XXX.X"
                    appRemoveLeadingZero
                    appDecimalOnly />
                </span>
                <ng-container formArrayName="multiPointMoisture">
                  <ng-container *ngFor="let sample of getMultiPointMoisture.controls; let i = index">
                    <ng-container [formGroupName]="i">
                      <span style="width: 25%; padding-left: 1rem; display: inline-block">
                        <input
                          matInput
                          class="input-text mat-input-border"
                          required
                          formControlName="moisture"
                          placeholder="e.g. XXX.X"
                          appRemoveLeadingZero
                          appDecimalOnly
                          (keyup)="validateMoisture()" />
                      </span>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </div>
              <div class="col-md-2">
                <span style="width: 40%; display: inline-block">
                  <button
                    mat-icon-button
                    [disabled]="getMultiPointMoisture.length > 2"
                    (click)="addMultiPointMoisture()"
                    style="background: #f5f5f5">
                    <img src="/assets/icons/plus.svg" class="icon-mt-1" alt="alert-icon" />
                  </button>
                </span>
                <span style="width: 40%; padding-left: 1rem; display: inline-block">
                  <button
                    mat-icon-button
                    [disabled]="getMultiPointMoisture.length === 0"
                    (click)="removeMultiPointMoisture()"
                    style="background: #f5f5f5">
                    <img src="/assets/icons/minus.svg" class="icon-mt-1" alt="alert-icon" />
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
        <mat-divider></mat-divider>
        <div class="block color-black font-wt-500 pl-16">
          <div class="pb-8 pt-8">
            <div class="row">
              <div class="col-md-10 pt-8">
                <img alt="tag" class="ml-8 mr-8" src="../../../../assets/icons/reuse_black.svg" />
                <span>Re-use soil sample for all test(s)</span>
              </div>
              <div class="col-md-2 text-r">
                <mat-slide-toggle formControlName="reUse" name="reUse" class="label-toggle"> </mat-slide-toggle>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-content *ngIf="WTypeActive.worksheet">
    <div [formGroup]="form">
      <div class="block color-black font-wt-500 header-wrapper" style="display: flex; justify-content: space-between">
        <div class="head" [ngClass]="{ partial: workSheet.reUse, full: !workSheet.reUse }">
          <img *ngIf="workSheet?.status" alt="tag" class="ml-8 mr-8 tick" src="../../../../assets/icons/Check.svg" />
          <h1 class="mat-h1" [ngClass]="{ 'h-partial': workSheet.status, 'h-full': !workSheet.status }">
            {{ headerText }}
          </h1>
          <div class="clearfix"></div>
        </div>
        <div>
          <span class="h-label bg-{{ workSheet.worksheetType | lowercase }}">{{ workSheet.worksheetType }}</span>
          <span *ngIf="workSheet?.reUse" class="h-label bg-reuse">Re-Use Sample</span>
        </div>
        <div class="clearfix"></div>
      </div>
      <div>
        <mat-error *ngIf="titleError"> In House ID is duplicate. Please enter unique In House ID.</mat-error>
      </div>
      <div>
        <mat-error *ngIf="dryPlusTareCheck">
          Dry weight cannot be higher than wet weight, please check dry weight and re-enter.</mat-error
        >
      </div>
      <div>
        <mat-error *ngIf="dryTrCheck"> Dry TR cannot be less than Wet TR, please check Dry TR and re-enter. </mat-error>
      </div>
      <div class="tab-container">
        <mat-tab-group animationDuration="0" [(selectedIndex)]="selectedIndex">
          <mat-tab label="Measurements">
            <div class="tab-height">
              <mat-divider></mat-divider>
              <div class="block color-black font-wt-500 pl-16">
                <div class="pb-8 pt-8">
                  <div class="row">
                    <div class="col-md-10 pt-8">
                      <img alt="tag" class="ml-8 mr-8" src="../../../../assets/icons/Tag.svg" />
                      <span>In-House ID<span class="required">*</span></span>
                    </div>
                    <div class="col-md-2 text-r">
                      <input
                        type="text"
                        matInput
                        class="input-text mat-input-border"
                        required
                        formControlName="inHouseId"
                        maxlength="50"
                        (keyup)="inhouseKeyup()" />
                    </div>
                  </div>
                </div>
              </div>
              <mat-divider></mat-divider>
              <div class="block color-black font-wt-500 pl-16">
                <div class="pb-8 pt-8">
                  <div class="row">
                    <div class="col-md-10 pt-8">
                      <img alt="tag" class="ml-8 mr-8" src="../../../../assets/icons/scale.svg" />
                      <span>Mould+ Probe Tare (gm)</span>
                    </div>
                    <div class="col-md-2 text-r">
                      <input
                        placeholder="0"
                        matInput
                        class="input-text mat-input-border"
                        required
                        formControlName="mouldPlusProbeTare"
                        appNumbersOnly
                        [allowCommas]="false"
                        maxlength="5" />
                    </div>
                  </div>
                </div>
              </div>
              <mat-divider></mat-divider>
              <div class="block color-black font-wt-500 pl-16">
                <div class="pb-8 pt-8">
                  <div class="row">
                    <div class="col-md-10 pt-8">
                      <img alt="tag" class="ml-8 mr-8" src="../../../../assets/icons/Measure.svg" />
                      <span>Sample Diameter (mm)</span>
                      <img
                        alt="info Icon"
                        class="star-mar-top"
                        #tooltip="matTooltip"
                        matTooltip="Max. 4 digits allowed, including one place of decimal. e.g. XXX.X"
                        src="../../../../assets/icons/Alert-grey.svg" />
                    </div>
                    <div class="col-md-2 text-r">
                      <input
                        matInput
                        class="input-text mat-input-border"
                        required
                        [class.error]="form.get('soilDiameter')?.hasError('invalidFormat')"
                        formControlName="soilDiameter"
                        placeholder="e.g. XXX.X"
                        appDecimalOnly
                        maxlength="5"
                        appOneDigitDecimaNumber
                        appRemoveLeadingZero />
                    </div>
                  </div>
                </div>
              </div>
              <mat-divider></mat-divider>
              <div class="block color-black font-wt-500 pl-16">
                <div class="pb-8 pt-8">
                  <div class="row">
                    <div class="col-md-10 pt-8">
                      <img alt="tag" class="ml-8 mr-8" src="../../../../assets/icons/Measure.svg" />
                      <span>Sample Height (mm)</span>
                      <img
                        alt="info Icon"
                        class="star-mar-top"
                        #tooltip="matTooltip"
                        matTooltip="Max. 4 digits allowed, including one place of decimal. e.g. XXX.X"
                        src="../../../../assets/icons/Alert-grey.svg" />
                    </div>
                    <div class="col-md-2 text-r">
                      <input
                        matInput
                        class="input-text mat-input-border"
                        required
                        [class.error]="form.get('tubeHeight')?.hasError('invalidFormat')"
                        formControlName="tubeHeight"
                        placeholder="e.g. XXX.X"
                        appDecimalOnly
                        maxlength="5"
                        appOneDigitDecimaNumber
                        appRemoveLeadingZero />
                    </div>
                  </div>
                </div>
              </div>
              <mat-divider></mat-divider>
              <div class="block color-black font-wt-500 pl-16">
                <div class="pb-8 pt-8">
                  <div class="row">
                    <div class="col-md-10 pt-8">
                      <img alt="tag" class="ml-8 mr-8" src="../../../../assets/icons/Measure.svg" />
                      <span>Minus (mm)</span>
                      <img
                        alt="info Icon"
                        class="star-mar-top"
                        #tooltip="matTooltip"
                        matTooltip="Max. 4 digits allowed, including one place of decimal. e.g. XXX.X"
                        src="../../../../assets/icons/Alert-grey.svg" />
                    </div>
                    <div class="col-md-2 text-r">
                      <input
                        matInput
                        class="input-text mat-input-border"
                        required
                        [class.error]="form.get('minus')?.hasError('invalidFormat')"
                        formControlName="minus"
                        placeholder="e.g. XXX.X"
                        appDecimalOnly
                        maxlength="5"
                        appOneDigitDecimaNumber />
                    </div>
                  </div>
                </div>
              </div>
              <mat-divider></mat-divider>
              <div class="block color-black font-wt-500 pl-16" *ngIf="workSheet?.worksheetType === 'Bulk'">
                <div class="pb-8 pt-8">
                  <div class="row">
                    <div class="col-md-10 pt-8">
                      <img alt="tag" class="ml-8 mr-8" src="../../../../assets/icons/scale.svg" />
                      <span>Separate into 5 trays at the specified weight (bulk )</span>
                    </div>
                    <div class="col-md-2 text-r">
                      <input
                        placeholder="0"
                        matInput
                        class="input-text mat-input-border inputdisabled"
                        required
                        formControlName="weightPerLayer"
                        appNumbersOnly
                        [allowCommas]="false"
                        tabindex="-1"
                        maxlength="5" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="block color-black font-wt-500 pl-16" *ngIf="workSheet?.worksheetType === 'Bulk'">
                <div class="pb-8 pt-8">
                  <div class="row">
                    <div class="col-md-10 pt-8">
                      <img alt="tag" class="ml-8 mr-8" src="../../../../assets/icons/Group.svg" />
                      <span>Compact into 5 equal layers (bulk )</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="Wet Tests">
            <mat-divider></mat-divider>
            <div class="block color-black font-wt-500 pl-16">
              <div class="pb-8 pt-8">
                <div class="row">
                  <div class="col-md-10 pt-8">
                    <img alt="tag" class="ml-8 mr-8" src="../../../../assets/icons/scale.svg" />
                    <span>Wet + Tare (gm)</span>
                  </div>
                  <div class="col-md-2 text-r">
                    <input
                      placeholder="0"
                      matInput
                      class="input-text mat-input-border"
                      required
                      formControlName="wetPlusTare"
                      appNumbersOnly
                      [allowCommas]="false"
                      maxlength="5"
                      (blur)="checkForDryWeightValidation()" />
                  </div>
                </div>
              </div>
            </div>
            <mat-divider></mat-divider>
            <div class="block color-black font-wt-500 pl-16">
              <div class="pb-8 pt-8">
                <div class="row">
                  <div class="col-md-1"></div>
                  <div class="col-md-9 pt-8 pl-0">
                    <img alt="tag" class="mr-8" src="../../../../assets/icons/z-scale.svg" />
                    <span>Wet TR (C-cm/W)</span>
                  </div>
                  <div class="col-md-2 text-r">
                    <input
                      placeholder="0"
                      matInput
                      class="input-text mat-input-border"
                      required
                      formControlName="wetTr"
                      appNumbersOnly
                      [allowCommas]="false"
                      maxlength="5"
                      (blur)="checkForDryTRValidation()" />
                  </div>
                </div>
              </div>
            </div>
            <div formArrayName="multiPointTests" *ngIf="workSheet?.worksheetType === 'Tube'">
              <ng-container *ngFor="let sample of getmultiPointTests.controls; let i = index">
                <ng-container [formGroupName]="i">
                  <mat-divider></mat-divider>
                  <div class="block color-black font-wt-500 pl-16">
                    <div class="pb-8 pt-8">
                      <div class="row">
                        <div class="col-md-8 pt-8">
                          <img alt="measure" class="ml-8 mr-8" src="../../../../assets/icons/scale.svg" />
                          Wet + Tare (gm)
                        </div>
                        <div class="col-md-2" align="right">
                          <button mat-icon-button (click)="removeMultiPoint(i)">
                            <mat-icon>delete_outline</mat-icon>
                          </button>
                        </div>
                        <div class="col-md-2 text-r">
                          <!-- <mat-form-field appearance="outline" class="edw-form-field"> -->
                          <input
                            matInput
                            class="input-text mat-input-border"
                            type="text"
                            placeholder="0"
                            appNumbersOnly
                            [allowCommas]="false"
                            maxlength="5"
                            formControlName="wetPlusTare"
                            required
                            (blur)="checkForDryWeightValidation()" />
                          <!-- </mat-form-field> -->
                        </div>
                      </div>
                    </div>
                  </div>
                  <mat-divider></mat-divider>
                  <div class="block color-black font-wt-500 pl-16">
                    <div class="pb-8 pt-8">
                      <div class="row">
                        <div class="col-md-1"></div>
                        <div class="col-md-9 pt-8 pl-0">
                          <img alt="scale" class="mr-8" src="../../../../assets/icons/connecting.svg" />
                          <span> Wet TR (C-cm/W) </span>
                        </div>
                        <div class="col-md-2 pt-8">
                          <input
                            matInput
                            class="input-text mat-input-border"
                            type="text"
                            placeholder="0"
                            appNumbersOnly
                            [allowCommas]="false"
                            maxlength="5"
                            formControlName="wetTr"
                            required
                            (blur)="checkForDryTRValidation()" />
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </ng-container>
              <mat-divider></mat-divider>
              <div class="block color-black font-wt-500 pl-16">
                <div class="pb-8 pt-8">
                  <div class="row">
                    <div class="col-md-9"></div>
                    <div class="col-md-3 pt-8" align="right">
                      <a
                        mat-button
                        href="javascript:void(0)"
                        (click)="addMultiPoint()"
                        *ngIf="getmultiPointTests.length < 3">
                        + Add Wet TR Test
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="Dry Tests">
            <mat-divider></mat-divider>
            <div class="block color-black font-wt-500 pl-16 r-wrap">
              <div class="pb-8 pt-8">
                <div class="row">
                  <div class="col-md-10 pt-8">
                    <img alt="tag" class="ml-8 mr-8" src="../../../../assets/icons/scale.svg" />
                    <span>Dry + Tare (gm)</span>
                  </div>
                  <div class="col-md-2 text-r">
                    <input
                      placeholder="0"
                      matInput
                      class="input-text mat-input-border"
                      required
                      formControlName="dryPlusTare"
                      appNumbersOnly
                      [allowCommas]="false"
                      maxlength="5"
                      (blur)="checkForDryWeightValidation()" />
                  </div>
                </div>
              </div>
            </div>
            <mat-divider></mat-divider>
            <div class="block color-black font-wt-500 pl-16 r-wrap">
              <div class="pb-8 pt-8">
                <div class="row">
                  <div class="col-md-1"></div>
                  <div class="col-md-9 pt-8 pl-0">
                    <img alt="tag" class="mr-8" src="../../../../assets/icons/z-scale.svg" />
                    <span>Dry TR (C-cm/W)</span>
                  </div>
                  <div class="col-md-2 text-r">
                    <input
                      placeholder="0"
                      matInput
                      class="input-text mat-input-border"
                      required
                      formControlName="dryTr"
                      appNumbersOnly
                      [allowCommas]="false"
                      maxlength="5"
                      (blur)="checkForDryTRValidation()" />
                  </div>
                </div>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-content *ngIf="WTypeActive.proctor">
    <div [formGroup]="form">
      <div *ngIf="modalType === 'edit'">
        <div class="block color-black font-wt-500">
          <img *ngIf="workSheet?.status" alt="tag" class="ml-8 mr-8 tick" src="../../../../assets/icons/Check.svg" />
          <h1 class="mat-h1">{{ headerText }}</h1>
        </div>
        <mat-divider></mat-divider>
        <div class="row block color-black font-wt-500">
          <div class="col-1 pt-12">
            <img alt="tag" class="" src="../../../../assets/icons/scale.svg" />
          </div>
          <div class="col-9 pt-16">
            <span>Proctor Mould Weight (gm)</span>
          </div>
          <div class="col-2 pt-8">
            <input
              placeholder="0"
              matInput
              class="input-text mat-input-border"
              required
              formControlName="proctorMouldWeight"
              appNumbersOnly
              [allowCommas]="false"
              maxlength="5" />
          </div>
        </div>
        <div class="col-md-12 pt-8">
          <mat-divider></mat-divider>
        </div>

        <div class="row block color-black font-wt-500">
          <div class="col-1 pt-12">
            <img alt="tag" class="" src="../../../../assets/icons/scale.svg" />
          </div>
          <div class="col-9 pt-16">
            <span>Measure {{ workSheet.drySoil }} gm of dry soil</span>
          </div>
          <div class="col-2 pt-8"></div>
          <div class="col-1"></div>
          <div class="col-md-11 pt-8">
            <mat-divider></mat-divider>
          </div>
          <div class="col-1"></div>
          <div class="col-1 pt-12">
            <img alt="tag" class="" src="../../../../assets/icons/Drop.svg" />
          </div>
          <div class="col-8 pt-16">
            <span>Add {{ workSheet.addWater }} gm of water</span>
          </div>
          <div class="col-2 pt-8"></div>
          <div class="col-md-12 pt-8">
            <mat-divider></mat-divider>
          </div>
          <div class="col-1 pt-12">
            <img alt="tag" class="ml-8 mr-8" src="../../../../assets/icons/scale.svg" />
          </div>
          <div class="col-9 pt-16">
            <span>Perform Compaction</span>
          </div>
          <div class="col-2 pt-8"></div>
          <div class="col-1"></div>
          <div class="col-md-11 pt-8">
            <mat-divider></mat-divider>
          </div>
          <div class="col-1"></div>
          <div class="col-1 pt-12">
            <img alt="tag" class="ml-8 mr-8" src="../../../../assets/icons/scale.svg" />
          </div>
          <div class="col-8 pt-16">
            <span>Weight of Mould & Soil (gm)</span>
          </div>
          <div class="col-2 pt-8">
            <input
              placeholder="0"
              matInput
              class="input-text mat-input-border"
              required
              formControlName="mouldAndSoilWeight"
              appNumbersOnly
              [allowCommas]="false"
              maxlength="5" />
          </div>
          <div class="col-1"></div>
          <div class="col-md-12 pt-8"></div>
        </div>
      </div>
      <div *ngIf="modalType === 'mswt'">
        <div [formGroup]="form">
          <div class="block color-black font-wt-500">
            <h1 class="mat-h1">{{ headerText }}</h1>
          </div>
          <mat-divider></mat-divider>
          <div class="block color-black font-wt-500 pl-16">
            <div class="pb-8 pt-8">
              <div class="row">
                <div class="col-md-10 pt-8">
                  <img alt="tag" class="ml-8 mr-8" src="../../../../assets/icons/scale.svg" />
                  <span>Amount of wet soil to use (gm)</span>
                </div>
                <div class="col-md-2 text-r">
                  <input
                    placeholder="0"
                    matInput
                    class="input-text mat-input-border"
                    formControlName="drySoil"
                    required
                    appNumbersOnly
                    [allowCommas]="false"
                    maxlength="5" />
                </div>
              </div>
            </div>
          </div>
          <mat-divider></mat-divider>
          <div class="block color-black font-wt-500 pl-16">
            <div class="pb-8 pt-8">
              <div class="row">
                <div class="col-md-10 pt-8">
                  <img alt="tag" class="ml-8 mr-8" src="../../../../assets/icons/List.svg" />
                  <span>Enter the M/C %</span>
                  <img
                    alt="info Icon"
                    class="star-mar-top"
                    #tooltip="matTooltip"
                    matTooltip="Max. 4 digits allowed, including one place of decimal. e.g. XXX.X"
                    src="../../../../assets/icons/Alert-grey.svg" />
                </div>
                <div class="col-md-2 text-r">
                  <input
                    matInput
                    class="input-text mat-input-border"
                    required
                    placeholder="e.g. XXX.X"
                    appDecimalOnly
                    appRemoveLeadingZero
                    formControlName="moistureContent" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>

  <mat-divider></mat-divider>
  <div
    class="action-button-container pt-16 pb-16"
    *ngIf="isEdit"
    [ngClass]="{ 'b-dis': dryPlusTareCheck || dryTrCheck }">
    <button
      class="ml-16"
      mat-raised-button
      [disabled]="!previousActive || dryPlusTareCheck || dryTrCheck || dryWetWeighterror || moistureInValid"
      (click)="prev()">
      <mat-icon>navigate_before</mat-icon> PREV
    </button>
    <span class="spacer"></span>
    <button
      class="mr-16"
      color="primary"
      mat-raised-button
      (click)="save()"
      [disabled]="!saveActive || titleError || dryPlusTareCheck || dryTrCheck || dryWetWeighterror || moistureInValid">
      SAVE
    </button>
    <button
      class="mr-16 c-next"
      mat-raised-button
      *ngIf="nextActive"
      [disabled]="dryPlusTareCheck || dryTrCheck || dryWetWeighterror || moistureInValid"
      (click)="next()">
      NEXT <mat-icon>navigate_next</mat-icon>
    </button>
    <button
      class="mr-16"
      mat-raised-button
      color="primary"
      [disabled]="dryPlusTareCheck || dryTrCheck || dryWetWeighterror || moistureInValid"
      *ngIf="!nextActive"
      (click)="done()">
      Done
    </button>
  </div>
</div>
