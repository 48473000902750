<h2 mat-dialog-title class="mat-h2">
  {{ config.title }}
  <button mat-icon-button class="close-button" (click)="closeDialog()">
    <mat-icon>close</mat-icon>
  </button>
</h2>
<mat-dialog-content>
  <div class="row">
    <div class="col-12">
      <mat-label class="mat-subtitle-2">Value</mat-label>
      <mat-form-field appearance="outline" class="text-area">
        <!-- TODO : Need to move the logic written in ONINput to TS file and probably a common service as this is repetitive -->
        <textarea
          class="textarea-popup"
          matInput
          rows="4"
          cols="200"
          [(ngModel)]="testValue"
          required
          [ngModelOptions]="{ standalone: true }"
          maxlength="250"
          required
          placeholder="Max 250 characters"
          formcontrolName="testValue"
          oninput="this.value = this.value.replace(/[^A-Za-z0-9-,.;'&/.() ]|^ /g,'')">
        {{ testValue }}
        </textarea>
      </mat-form-field>
    </div>
  </div>
</mat-dialog-content>
<mat-divider></mat-divider>
<mat-dialog-actions align="end" *ngIf="!this.userService?.roleCheck(['Reportwriter'])">
  <button mat-raised-button (click)="closeDialog()">Cancel</button>
  <button mat-raised-button color="primary" (click)="onSubmit()">UPDATE</button>
</mat-dialog-actions>
