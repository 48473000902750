import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MessageModalComponent } from 'src/app/shared/components/message-modal/message-modal.component';

@Component({
  selector: 'app-print-instructions',
  templateUrl: './print-instructions.component.html',
  styleUrls: ['./print-instructions.component.scss'],
})
export class PrintInstructionsComponent {
  config: any;
  enablePrint = false;
  printed = false;
  @Output() printClick = new EventEmitter<boolean>();
  constructor(private mdr: MatDialogRef<MessageModalComponent>, @Inject(MAT_DIALOG_DATA) data: MatDialog) {
    this.config = data;
    this.enablePrint = this.config?.enablePrint;
  }

  closeDialog(): void {
    this.mdr?.close({
      enablePrint: this.enablePrint,
      printed: this.printed,
    });
  }

  handleScrollDown(): void {
    this.enablePrint = true;
  }

  printAndSave(): void {
    this.printed = true;
    this.printClick.emit(true);
    this.closeDialog();
  }
}
