<h2 mat-dialog-title class="mat-h1" [ngClass]="config?.type === 'WARNING' ? 'warning-title' : 'mat-h1'">
  <div class="popup-icon">
    <mat-icon
      *ngIf="config?.type !== 'SUCCESS' || config?.type === 'WARNING'"
      aria-hidden="false"
      [ngClass]="{ 'alert-icon': config?.type === 'WARNING' }">
    </mat-icon>
  </div>
  {{ config?.title }}
</h2>
<mat-dialog-content>
  <p class="mat-subtitle-1" [innerHTML]="config?.message"></p>
</mat-dialog-content>
<mat-divider></mat-divider>
<mat-dialog-actions align="end">
  <button mat-raised-button color="primary" (click)="closeDialog()">CLOSE</button>
</mat-dialog-actions>
