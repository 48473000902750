import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-technical-instructions',
  templateUrl: './technical-instructions.component.html',
  styleUrls: ['./technical-instructions.component.scss'],
})
export class TechnicalInstructionsComponent {
  config:any; // TODO Remove this any type

  constructor(
    private mdr: MatDialogRef<TechnicalInstructionsComponent>,
    @Inject(MAT_DIALOG_DATA) data: any,
  ) {
    this.config = data;
  }

  public closeDialog():void {
    this.mdr.close(false);
  }
}
