export const nativeColumns = [
  {
    displayName: 'Status',
    name: 'sampleStatusTypeId',
    format: '',
  },
  {
    displayName: 'Sample ID',
    name: 'sampleId',
    format: 'truncate',
  },
  {
    displayName: 'Type',
    name: 'sampletype',
    format: '',
  },
  {
    displayName: 'Depth(ft)',
    name: 'displayDepth',
    format: '',
  },
  // {
  //   displayName: 'Weight Rcvd(gm)',
  //   name: 'displayDepth',
  //   format: '',
  // },
  {
    displayName: 'Collection Date',
    name: 'collectionDate',
    format: 'date',
  },
  {
    displayName: 'Soil/Backfill Description',
    name: 'soilDescription',
    format: 'truncate',
  },
  {
    displayName: 'Max Dry Density',
    name: 'maxDryDensity',
    format: '',
  },
  {
    displayName: 'Optimum M/C',
    name: 'optimumMoistureContent',
    format: '',
  },
  {
    displayName: 'Compaction Effort(s) %',
    name: 'compactionEffort',
    format: 'compaction',
  },
  {
    displayName: 'Moisture Content',
    name: 'moistureContent',
    format: '',
  },
];
