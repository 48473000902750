import { Component, OnInit } from '@angular/core';
import { AccountInfo } from '@azure/msal-browser';
import { Subscription } from 'rxjs';
import { errorMessages } from 'src/app/shared/constants/error-message.constants';
import { CustomException } from 'src/app/shared/models/i-custom-exception.modal';
import { IMessage } from 'src/app/shared/models/message.modal';
import { LoggingService } from 'src/app/shared/services/logging.service';
import { UserService } from 'src/app/shared/services/user.service';
import { generateGuid } from 'src/app/shared/util/guid-generator.utils';

@Component({
  selector: 'app-page-not-found',
  templateUrl: 'page-not-found.component.html',
  styleUrls: ['page-not-found.component.scss'],
})
export class PageNotFoundComponent implements OnInit {
  private userSubscription = Subscription.EMPTY;
  public userAccount: AccountInfo | null; // TODO Need to remove this null occurence
  constructor(private userService: UserService, private loggingService: LoggingService) {}

  ngOnInit() {
    const newGuid: string = generateGuid();
    const logError: CustomException = {
      frondendID: newGuid,
      message: 'The page you requested dose not exist. Please check your link and try again.',
      backendID: '',
      stack: {
        url: window.location.href,
      },
    };
    const errorMessage: IMessage = errorMessages.uiError;
    errorMessage.errorID = newGuid;
    this.loggingService.logEvent('UI Error', logError);
    this.userSubscription = this.userService.userAccount.subscribe({
      next: (account) => {
        this.userAccount = account;
      },
    });
  }
}
