import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { messagesConfig } from 'src/app/shared/constants/message.constants';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CleanupService } from 'src/app/shared/services/cleanup.service';
import { takeUntil } from 'rxjs';
import { ReportService } from '../../services/report.service';

interface Recommendation {
  reportRecommendation: string;
}
@Component({
  selector: 'app-recommendation',
  templateUrl: './recommendation.component.html',
  styleUrls: ['./recommendation.component.scss'],
})
export class RecommendationComponent implements OnInit, OnChanges {
  editorContent: Recommendation = {
    reportRecommendation: '',
  };

  savedDone = false;
  @Input() reportId: string;
  @Input() recommendation: Recommendation;
  @Output() savedNotify: EventEmitter<boolean> = new EventEmitter<boolean>(false);
  constructor(
    private reportService: ReportService,
    private snackBar: MatSnackBar,
    private cleanupService: CleanupService,
  ) {}

  ngOnInit(): void {
    this.getRecommendationAPi();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['recommendation'] && changes['recommendation'].currentValue) {
      this.editorContent = changes['recommendation'].currentValue;
    }
  }

  onContentChanged(content: string) {
    this.editorContent.reportRecommendation = content;
  }

  getRecommendationAPi() {
    if (this.reportId) {
      this.reportService.getRecommend(this.reportId)
        .pipe(takeUntil(this.cleanupService.unsubscribe))
        .subscribe((response:Recommendation) => {
          if (response) {
            this.editorContent.reportRecommendation = response.reportRecommendation;
          }
        });
    }
  }

  saveBtn() {
    this.reportService.saveRecommend(this.editorContent, this.reportId)
      .pipe(takeUntil(this.cleanupService.unsubscribe))
      .subscribe((data) => {
        if (data) {
          this.snackBar.open(messagesConfig.generalSubmitSuccessMessage.message, 'DONE', {
            duration: 3000,
            verticalPosition: 'top',
            panelClass: ['default-snackbar'],
          });
          this.savedDone = true;
          this.savedNoti();
        }
      });
  }

  savedNoti() {
    this.savedNotify.emit(this.savedDone);
    this.savedDone = false;
  }
}
