import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appNumberInput]',
})
export class NumberInputDirective {
  @Input() length = 5;
  constructor(private el: ElementRef, private control: NgControl) {}

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (event.key === '+' || event.key === '-') {
      event.preventDefault();
    }
  }

  @HostListener('input', ['$event']) onInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    const value = input.value.replace(/[^0-9]/g, '').slice(0, this.length);
    this.control.control?.setValue(value);
  }
}
