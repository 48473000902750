<!-- Start if already created project view submission form show this div section -->
<form [formGroup]="form">
  <div class="r-company-wrapper" formGroupName="companyInfo" *ngIf="data">
    <mat-accordion>
      <mat-expansion-panel (opened)="onCompanyPanelOpened()" (closed)="onCompanyPanelClosed()">
        <mat-expansion-panel-header class="header-panel">
          <mat-panel-title class="title-panel">
            <h2>Company Information</h2>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="panel-container">
          <div class="item">
            <mat-checkbox color="primary" formControlName="companyName" *ngIf="shouldShowCheckbox()"> </mat-checkbox>
            <div class="text">
              <div class="label">Company Name</div>
              <div class="name">{{ data.name }}</div>
            </div>
          </div>
          <div class="item">
            <mat-checkbox color="primary" formControlName="companyContactName" *ngIf="shouldShowCheckbox()"> </mat-checkbox>
            <div class="text">
              <div class="label">Contact Name</div>
              <div class="name">{{ data.contactName }}</div>
            </div>
          </div>
          <div class="item">
            <mat-checkbox color="primary" formControlName="companyEmail" *ngIf="shouldShowCheckbox()"> </mat-checkbox>
            <div class="text">
              <div class="label">Contact Email - Reports Submittal</div>
              <div class="name">{{ data.emails }}</div>
            </div>
          </div>
          <div class="item">
            <mat-checkbox color="primary" formControlName="companyAddress" *ngIf="shouldShowCheckbox()"> </mat-checkbox>
            <div class="text">
              <div class="label">Address</div>
              <div class="name">{{ data.streetAddress }}</div>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>

  <div class="r-company-wrapper billing-section" *ngIf="data">
    <div class="row">
      <div class="col-md-6 section-align" formGroupName="billingInfo">
        <mat-accordion>
          <mat-expansion-panel (opened)="onPanelOpened()" (closed)="onPanelClosed()">
            <mat-expansion-panel-header class="header-panel">
              <mat-panel-title class="title-panel">
                <h2>Billing Information</h2>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="panel-container">
              <div class="item">
                <mat-checkbox color="primary" formControlName="billingName" *ngIf="shouldShowCheckbox()"> </mat-checkbox>
                <div class="text">
                  <div class="label">Company Name</div>
                  <div class="name">{{ billing.invoice }}</div>
                </div>
              </div>
              <div class="item">
                <mat-checkbox color="primary" formControlName="billingContactName" *ngIf="shouldShowCheckbox()"> </mat-checkbox>
                <div class="text">
                  <div class="label">Contact Name</div>
                  <div class="name">{{ billing.contactName }}</div>
                </div>
              </div>
              <div class="item">
                <mat-checkbox color="primary" formControlName="billingEmail" *ngIf="shouldShowCheckbox()"> </mat-checkbox>
                <div class="text">
                  <div class="label">Contact Email - Reports Submittal</div>
                  <div class="name">{{ billing.contactEmail }}</div>
                </div>
              </div>
              <div class="item">
                <mat-checkbox color="primary" formControlName="billingAddress" *ngIf="shouldShowCheckbox()"> </mat-checkbox>
                <div class="text">
                  <div class="label">Address</div>
                  <div class="name">{{ billing.streetAddress }}</div>
                </div>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>

      <div class="col-md-6 section-align" formGroupName="projectInfo">
        <mat-accordion>
          <mat-expansion-panel (opened)="onPanelOpened()" (closed)="onPanelClosed()">
            <mat-expansion-panel-header class="header-panel">
              <mat-panel-title class="title-panel">
                <h2>Project Information</h2>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="panel-container">
              <div class="item">
                <mat-checkbox color="primary" formControlName="projectName" *ngIf="shouldShowCheckbox()"> </mat-checkbox>
                <div class="text">
                  <div class="label">Project Name</div>
                  <div class="name">{{ data.projectName }}</div>
                </div>
              </div>
              <div class="item">
                <mat-checkbox color="primary" formControlName="projectLocation" *ngIf="shouldShowCheckbox()"> </mat-checkbox>
                <div class="text">
                  <div class="label">Project Location</div>
                  <div class="name">{{ data.projectLocation }}</div>
                </div>
              </div>
              <div class="item">
                <mat-checkbox color="primary" formControlName="projectJobNo" *ngIf="shouldShowCheckbox()"> </mat-checkbox>
                <div class="text">
                  <div class="label">PO/Job Number</div>
                  <div class="name">{{ data.jobNumber }}</div>
                </div>
              </div>
              <div class="item">
                <mat-checkbox color="primary" formControlName="projectNumber" *ngIf="shouldShowCheckbox()"> </mat-checkbox>
                <div class="text">
                  <div class="label">Project Number</div>
                  <div class="name">{{ data.projectNumber }}</div>
                </div>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
  </div>
</form>
<!-- Start if already created project view submission form show this div section -->
