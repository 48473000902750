<div class="main-container" *ngIf="adminData && adminData.length && adminData.length > 1">
  <div class="savebtn">
    <button mat-button class="btn-action btn-update" (click)="adminSaveAPi()" [disabled]="saveButtonDisabled">SAVE</button>
  </div>
  <h2 class="pt-32">Default Settings</h2>
  <div class="admin-container">
    <div *ngFor="let item of InitialLevel">
      <div class="border-line">
        <!-- Exclude items with keys "contact" and "companyaddress" -->
        <ng-container *ngIf="item.key !== 'contact' && item.key !== 'companyaddress'">
          <div class="icon-column">
            <mat-icon class="icon-container">{{ item.iconPath }}</mat-icon>
          </div>
          <div class="label-column">
            <mat-label>{{ item.displayName }}</mat-label>
            <div *ngIf="item.key === 'defaultturnaroundtime'" >
              <span class="custom-label">
                Please modify the value(s) only after the working hours are over, so that the changes
                 are correctly reflected in the application.
              </span>
            </div>
          </div>
          <div class="input-column">
            <mat-form-field class="headerlevel-input" appearance="outline">
              <input
                matInput
                [(ngModel)]="item.value"
                style="text-align: end"
                (keypress)="validatenumberOnly($event)"
                (ngModelChange)="checkInputsFilled()"
                maxlength="3" />
            </mat-form-field>
          </div>
        </ng-container>
      </div>
    </div>

    <!--company address-->
    <ng-container *ngFor="let item of adminData">
      <div *ngIf="item.key === 'companyaddress'" class="sub-form">
        <mat-icon class="icon-container">{{ item.iconPath }}</mat-icon>
        <mat-label class="label">{{ item.displayName }}</mat-label>
        <div style="margin-top: 30px"></div>

        <ng-container *ngFor="let subItem of companyAddress">
          <div style="margin-top: 10px"></div>
          <div
            class="sub-input" *ngIf="showCompanyAddres(subItem.key)">
            <mat-form-field appearance="outline" class="c_label">
              <input
                matInput
                [(ngModel)]="subItem.value"
                [placeholder]="subItem.displayName"
                (ngModelChange)="checkInputsFilled()"
                maxlength="50" />
            </mat-form-field>
          </div>

          <div style="margin-top: 10px"></div>
          <div >
            <div class="state-code first" *ngIf="subItem.key === 'state'">
              <mat-form-field appearance="outline">
                <input
                  matInput
                  [(ngModel)]="subItem.value"
                  [placeholder]="subItem.displayName"
                  maxlength="50"
                  (ngModelChange)="checkInputsFilled()"
                 />
              </mat-form-field>
            </div>
            <div class="state-code last" *ngIf="subItem.key === 'zipcode'">
              <mat-form-field appearance="outline">
                <input
                  matInput
                  [(ngModel)]="subItem.value"
                  [placeholder]="subItem.displayName"
                  (keypress)="validatenumberOnly($event)"
                  maxlength="10"
                  (ngModelChange)="checkInputsFilled()"
                   />
              </mat-form-field>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>

    <!-- contact-->
    <ng-container *ngFor="let item of adminData">
      <div *ngIf="item.key === 'contact'" class="sub-form">
        <mat-icon class="icon-container">{{ item.iconPath }}</mat-icon>
        <mat-label class="label">{{ item.displayName }}</mat-label>
        <div class="margin-top-30"></div>
        <ng-container *ngFor="let subItem of contact">
          <div class="margin-top-10"></div>
          <div class="sub-input" *ngIf="showSubItem(subItem.key)">
            <mat-form-field appearance="outline" class="c_label">
              <input
                matInput
                [(ngModel)]="subItem.value"
                [placeholder]="subItem.displayName"
                (ngModelChange)="checkInputsFilled(); contactInputsFilled()"
                (keypress)="onKeyPress($event, subItem.key)"
                (blur)="onBlur($event, subItem.key)"/>
               
            </mat-form-field> 
            <div class="error-msg">
              <span id="{{subItem.key}}-error" class="error-message"></span>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </div>
</div>
