import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DateUtilService {
  convertUtcToLocal(utcDateString: string, dateOrTime?: string): string {
    if (!utcDateString) return '';

    const utcDate = new Date(utcDateString);
    const localTimeZoneOffset = new Date().getTimezoneOffset();
    const localDate = new Date(utcDate.getTime() - localTimeZoneOffset * 60000);

    if (dateOrTime === 'date') {
      return localDate.toLocaleDateString(); // Format as local date
    }
    if (dateOrTime === 'time') {
      return localDate.toLocaleTimeString(); // Format as local time
    }
    return localDate.toLocaleString(); // Format as local date and time
  }
}
