<div class="rpreview-wrapper" *ngIf="previewData">
  <div class="rpreview-header-wrapper">
    <div class="logo">
      <img alt="geotherm-logo" src="assets/images/geotherm-logo-b.svg" class="geotherm-logo" />
    </div>
    <div class="r-c">
      <span>
        {{ address.street1 }}, {{ address.street2 }} <br />
        {{ address.city }}, {{ address.state }}<br />
        Tel: {{ address.ph }}<br />
        {{ address.email }}<br />
        {{ address.weburl }}</span
      >
    </div>
    <div class="clearfix"></div>
    <div class="header-bottom-wrapper">
      <div class="d">
        <strong>{{ previewData.reportDate | date : 'mediumDate' }}</strong>
      </div>
      <!-- <div class="c-primary">*Revised - {{ previewData.revisionDate | date : 'mediumDate' }}</div> -->
      <div class="add">
        <span>
          <strong>{{ previewData.companyName }}</strong
          ><br />
          {{ previewData.streetAddress }}<br />
          {{ previewData.cityState }}<br />
        </span>
        <span class="un"
          ><strong>Attn: {{ previewData.contactName }}</strong>
        </span>
      </div>
    </div>
  </div>
  <div class="rpreview-content-wrapper">
    <div class="head">
      <span>
        <strong>{{ previewData.header }}</strong>
      </span>
      <span>
        <strong>{{ previewData.subHeader }}</strong>
      </span>
    </div>
    <div class="rpreview-result-wrapper">
      <app-precast-sample *ngIf="soilType === 'precast'" [previewData]="previewData"></app-precast-sample>
      <app-native-sample *ngIf="soilType === 'nativesoil'" [previewData]="previewData"></app-native-sample>
    </div>
    <div class="rpreview-comments-wrapper">
      <div *ngIf="soilType === 'precast'">
        <p class="fp"><strong>Client Comments:</strong></p>
        <div class="comment-block" *ngIf="previewData?.commentPart1">
          <p><strong>Concrete: </strong>{{ previewData.commentPart1 }}</p>
        </div>
        <div class="comment-block" *ngIf="previewData?.commentPart2">
          <p><strong>FTB: </strong>{{ previewData.commentPart2 }}</p>
        </div>
        <div class="comment-block" *ngIf="previewData?.commentPart3">
          <p><strong>Grout/Slurry: </strong>{{ previewData.commentPart3 }}</p>
        </div>
        <div class="comment-block" *ngIf="previewData?.commentPart4">
          <p><strong>Other: </strong>{{ previewData.commentPart4 }}</p>
        </div>
        <div class="rpreview-contact">{{ previewData.commentPart5 }}</div>
      </div>
      <div *ngIf="soilType === 'nativesoil'">
        <div class="comment-block" *ngIf="previewData?.commentPart1">
          <p class="fp"><strong>Client Comments: </strong></p>
          <div class="comment-block" *ngIf="previewData?.commentPart1">
            <p>{{ previewData.commentPart1 }}</p>
          </div>
        </div>
        <div class="rpreview-contact">{{ previewData.commentPart2 }}</div>
      </div>
      <div *ngIf="previewData?.geothermComment">
        <p class="fp"><strong>Geotherm Comments:</strong></p>
        <div [innerHTML]="geothermComment" class="ql-editor"></div>
      </div>
      <div *ngIf="previewData?.recommendations">
        <p class="fp"><strong>Recommendations:</strong></p>
        <div [innerHTML]="recommendations" class="ql-editor"></div>
      </div>
    </div>
    <div class="rpreview-siganture-wrapper">
      <span>Geotherm USA, LLC</span>
      <img alt="geotherm-logo" src="data:image/png;base64,{{ signature.signatureImage }}" class="geotherm-logo" />
      <span>{{ signature.label }} </span>
    </div>
    <div class="rpreview-address-footer">
      <span>COOL SOLUTIONS FOR UNDERGROUND POWER CABLES</span>
      <span>THERMAL SURVEYS, CORRECTIVE BACKFILLS & INSTRUMENTATION</span>
      <span>Serving the electric power industry since 1978</span>
    </div>
  </div>
</div>
<div id="canvasContainer">
  <img [src]="figures[0]?.image" alt="" />
  <div class="rpreview-wrapper" *ngFor="let canvas of lineChartDataArray; let i = index">
    <div class="logo-graph">
      <img alt="geotherm-logo" src="assets/images/geotherm-logo-b.svg" class="geotherm-logo" />
    </div>
    <div class="graphHeading">THERMAL DRYOUT CURVE - FIGURE {{ i + 1 }}</div>
    <div class="canvas-wrapper">
      <canvas #canvasRef id="canvas{{ i }}"></canvas>
    </div>
    <div class="g-spacer"></div>
    <div class="rpreview-content-wrapper">
      <div class="rpreview-address-footer">
        <span>COOL SOLUTIONS FOR UNDERGROUND POWER CABLES</span>
        <span>THERMAL SURVEYS, CORRECTIVE BACKFILLS & INSTRUMENTATION</span>
        <span>Serving the electric power industry since 1978</span>
      </div>
    </div>
  </div>
</div>
