<div class="print-confirmation-wrapper">

    <h2 mat-dialog-title class="mat-h2">
        <span *ngIf="config?.type === 'nativesoil'">Soil/Backfill Sample Form</span>
        <span *ngIf="config?.type === 'precast'">Pre Cast Sample Form</span>
        <span *ngIf="config?.type === 'instructions'">Instructions</span>
      <button mat-icon-button class="close-button" (click)="closeDialog()">
        <mat-icon>close</mat-icon>
      </button>
    </h2>

    <mat-dialog-content class="scroll-container" appScrollDown (scrolledDown)="handleScrollDown()">
        <div class="dialog-content-wrapper" >
            <div class="dialog-content" >
                <div *ngIf="config?.image">
                    <h1>Please print this record and include in your shipment</h1>
                    <div class="img-wrapper">
                        <img src="assets/icons/print-directions.svg" alt="" />        
                    </div>  
                </div>
                 
                <div class="instruction-wrapper">
                    <h2 mat-dialog-title class="mat-h2 bg-none">Instructions</h2>
                    <div class="print-instruction">
                        <div class="block">
                            <span class="dot">.</span>
                            <span class="content">
                                If these samples are for Q/A (quality assurance test program), a set of 2-3 should be taken at each station.
                            </span>
                            <div class="clear"></div>
                            
                        </div>
                        <div class="block">
                            <span class="dot">.</span>
                            <span class="content">
                                All samples should be marked with the date the samples were cast, station number or Mix ID, 
                                type of material (FTB, Grout or Concrete), etc.
                            </span>
                            <div class="clear"></div>
                        </div>
                        <div class="block">
                            <span class="dot">.</span>
                            <span class="content">
                                The samples can be shipped after 24-36 hours of curing period.  
                                Please cap and duct-tape the sample moulds to prevent loss of moisture and 
                                to minimize disturbance during transportation.
                            </span>
                            <div class="clear"></div>
                        </div>
                        <div class="block">
                            <span class="dot">.</span>
                            <span class="content">
                                Samples should be wrapped and taped in bubble wrap; 
                                shipped in cardboard box or 5-gallon bucket with proper packaging material to minimize disturbance.  
                            </span>
                            <div class="clear"></div>
                        </div>
                        <div class="block">
                            <span class="dot">.</span>
                            <span class="content">
                                Send all samples via FedEx or UPS overnight service (or 2nd day air service).  
                            </span>
                            <div class="clear"></div>
                            
                        </div>
                        <div class="block">
                            <span class="dot">.</span>
                            <span class="content">
                                Email the tracking number to <a href="mailto:info@geothermusa.com">info@geothermusa.com</a> 
                                and <a href="mailto:lab@geothermusa.com">lab@geothermusa.com</a> 
                            </span>
                            <div class="clear"></div>                    
                        </div>  
                        <div class="block">
                            <span class="dot">.</span>
                            <span class="content">
                                Please contact us if you have any questions or require further details at <b>(281) 985-9344 </b>
                            </span>
                            <div class="clear"></div>                    
                        </div> 
                        <div class="block block-c">
                            For more details instructions, please visit our 
                            <a href="https://gt-web-ftwx-lnu1tinth-daianesls-projects.vercel.app/shipping-instructions" target="new">website</a>.
                            <div class="clear"></div>                    
                        </div> 
                        
                       
                    </div>  
                    <h2 mat-dialog-title class="mat-h2 bg-none">Please ship samples to:</h2>
                    <div  class="address">
                        <div>
                            <span class="b">GeothermUSA</span>
                            <span>21239 FM529 Road, Bldg F</span>
                            <span>Cypress, Texas 77433</span>
                        </div>
                    </div>
                    
                   
                </div>
            </div>
           
        </div>

      

    </mat-dialog-content>
    <mat-divider></mat-divider>
    <div class="btn-wrapper" *ngIf="config?.buttons" >
        <button class="mar" (click)="closeDialog()" mat-raised-button >CANCEL</button>
        <button mat-raised-button color="primary" [disabled] = "!enablePrint" (click) = "printAndSave()">  
            <img *ngIf="enablePrint" src="/assets/icons/print-white.svg" alt="print" />
            <img *ngIf="!enablePrint" src="/assets/icons/Print-disabled.svg" alt="print" />            
            PRINT
        </button>
    </div>
   
</div>