import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ReplaySubject, takeUntil } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { messagesConfig } from '../../constants/message.constants';
import { PrecastService } from '../../services/precast.service';
import { UserService } from '../../services/user.service';
import { INotes } from '../../../features/models/inotes.modal';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss'],
})
export class NotesComponent implements OnInit, OnDestroy {
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  public additionalNotesError = false;
  public additionalNotes: string;
  public notes: INotes[];
  response: any;
  config: any; // TODO Need to remove this occurence of any

  constructor(
    private precastService: PrecastService,
    private mdr: MatDialogRef<NotesComponent>,
    private snackBar: MatSnackBar,
    public userService: UserService,
    @Inject(MAT_DIALOG_DATA) data: any,
  ) {
    this.config = data;
  }

  ngOnInit(): void {
    this.fetchNotes(this.config?.projectId);
  }

  public addNote(): void {
    if (this.additionalNotes) {
      this.additionalNotesError = false;
      const payload: INotes = {
        roleName: null,
        projectId: this.config?.projectId,
        text: this.additionalNotes,
      };
      this.precastService
        ?.addNotes(payload)
        ?.pipe(takeUntil(this.destroyed$))
        ?.subscribe({
          next: (response: INotes) => {
            this.snackBar.open(messagesConfig.notesSubmitSuccessMessage.message, 'DONE', {
              duration: 3000,
              verticalPosition: 'top',
              panelClass: ['default-snackbar'],
            });
            this.response = response;
            this.fetchNotes(this.config?.projectId);
            // this.additionalNotes = '';
          },
          error: () => {},
        });
    } else {
      this.additionalNotesError = true;
    }
  }

  public closeDialog(): void {
    this.mdr.close(this.response || (this.notes[0] ? this.notes[0] : undefined));
  }

  private fetchNotes(projectID: string): void {
    this.precastService
      ?.getNotesbyId(projectID)
      ?.pipe(takeUntil(this.destroyed$))
      ?.subscribe({
        next: (response: INotes[]) => {
          this.notes = response;
        },
        error: () => {},
      });
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
