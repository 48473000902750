<div class="gcontainer report-thermal-wrapper">
  <div id="drawer" class="drawer-wrapper" [ngClass]="{ 'drawer-active': drawerActive }">
    <div class="drawer-content">
      <div class="drawer-head">
        <span class="t">Figures</span>
        <button mat-icon-button class="close-button" (click)="closeNav()">
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <div class="spacer"></div>
      <div class="figure-wrapper">
        <div class="fig" *ngFor="let each of figureCountArray; let i = index">
          <button class="fi c-primary" *ngIf="i < 9" (click)="loadFigure(i)">
            Figure 0{{ i + 1 }}
            <img src="assets/icons/triagle.svg" *ngIf="figureCompletedArray[i] === false" alt />
            <img src="assets/icons/Check.svg" *ngIf="figureCompletedArray[i] === true" alt />
          </button>
          <button class="fi c-primary" *ngIf="i >= 9" (click)="loadFigure(i)">
            Figure {{ i + 1 }}
            <img src="assets/icons/triagle.svg" *ngIf="figureCompletedArray[i] === false" alt />
            <img src="assets/icons/Check.svg" *ngIf="figureCompletedArray[i] === true" alt />
          </button>
          <div class="spacer"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-11">
    <nav aria-label="breadcrumb" class="mt-8">
      <ol class="breadcrumb">
        <li class="breadcrumb-item active breadcrumb cursor" aria-current="page">
          <a class="c-primary" [routerLink]="'/dashboard'" tabindex="0"> Dashboard </a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          <a class="c-primary" [routerLink]="['/report/edit', soilType, projectId]" [queryParams]="{ edit: 'true' }"
            tabindex="0">
            Edit Report
            <span *ngIf="projectName"> - {{ projectName }}</span>
            <span *ngIf="soilType === 'precast'"> - Pre Cast</span>
            <span *ngIf="soilType === 'nativesoil'"> - Native Soil</span>
          </a>
        </li>
      </ol>
    </nav>
  </div>
  <div class="report-thermal-content-wrapper">
    <div class="left-t-content-wrapper">
      <form [formGroup]="form">
        <div class="thermal-header">
          <div class="head">
            <h1>Thermal Resistivity Report</h1>
          </div>
          <div class="button-wrapper">
            <button class="c-primary" (click)="openNav()" [ngClass]="{ 'v-op': figureCountArray.length === 0 }">
              <img alt="" src="assets/icons/arrow-r-right.svg" />View all Figures
            </button>
            <div>
              <mat-checkbox color="primary" (change)="markAllAsComplete($event)" [(ngModel)]="markAllComplete"
                [ngModelOptions]="{ standalone: true }">
                <sub class="checkbox-text">Mark all as complete</sub>
              </mat-checkbox>
            </div>
          </div>
        </div>
        <div class="sample-data-wrapper">
          <div formArrayName="items">
            <div *ngFor="let item of getControls?.controls; let i = index">
              <div [formArrayName]="i" class="box" [style.border-color]="leftBorderColor[i]">
                <div class="l">
                  <div class="dt-wrapper">
                    <span [ngClass]="{
                        'w-250': item.value[0]?.clientDescription && item.value[0]?.clientDescription.length > 30
                      }" *ngIf="soilType === 'nativesoil'" class="a" #tooltip="matTooltip"
                      matTooltip="{{item.value[0]?.clientDescription }}">
                      {{ item.value[0]?.clientDescription }}
                    </span>
                    <span *ngIf="soilType === 'precast'" class="a">{{ item.value[0]?.sampleType }}</span>
                    <img src="assets/icons/Check.svg" alt="" *ngIf="form.value.items[i][0]?.graphStatus" />
                    <div class="clearfix"></div>
                  </div>

                  <span *ngIf="soilType === 'precast'" class="b">Density: {{ item.value[0]?.density }}</span>
                  <span *ngIf="soilType === 'nativesoil'" class="b">
                    Density:
                    {{
                    item.value[0]?.clientSpecifiedDensity > 0
                    ? item.value[0]?.clientSpecifiedDensity
                    : item.value[0]?.density
                    }}
                  </span>
                  <div>
                    <mat-checkbox color="primary" [checked]="true" class="chkbox"
                      (change)="showGraph($event.checked, i)">
                      <sub class="checkbox-text">Show Curve</sub>
                    </mat-checkbox>
                    <mat-checkbox color="primary" class="chkbox" (change)="showMLGraph($event.checked, i)">
                      <sub class="checkbox-text">Show ML Data</sub>
                    </mat-checkbox>
                    <div class="clearfix"></div>
                  </div>
                </div>
                <div class="r">
                  <div class="sp div">
                    <span>M/C</span>
                    <span>TR</span>
                  </div>
                  <div class="div" *ngFor="let each of getEachControls(i)?.controls; let j = index">
                    <div [formGroupName]="j" [ngClass]="{ 'input-disabled': j === 0 }">
                      <input *ngIf="soilType === 'nativesoil' && each?.get('worksheetType')?.value === 'Bulk'" class="f"
                        type="text" appRemoveLeadingZero formControlName="labSpecMoistureContent"
                        (blur)="detectNativeWetChange(i, j)" />
                      <input *ngIf="soilType === 'nativesoil' && each?.get('worksheetType')?.value === 'Tube'" class="f"
                        type="text" appRemoveLeadingZero formControlName="moistureContent"
                        (blur)="detectNativeWetChange(i, j)" />
                      <input
                        *ngIf="soilType === 'nativesoil'"
                        type="number"
                        appRemoveLeadingZero
                        appOnlyInteger
                        formControlName="wetTr"
                        (blur)="detectNativeWetChange(i, j)" />
                      <input
                        *ngIf="soilType === 'precast'"
                        class="f"
                        type="text"
                        appRemoveLeadingZero
                        formControlName="averageMoisture"
                        (blur)="detectPrecastWetChange(i, j)" />
                      <input
                        *ngIf="soilType === 'precast'"
                        type="number"
                        appRemoveLeadingZero
                        appOnlyInteger
                        formControlName="averageWetTr"
                        (blur)="detectPrecastWetChange(i, j)" />
                    </div>
                  </div>
                  <div class="clearfix"></div>
                </div>
                <div class="float-button-wrapper">
                  <button class="float-button" mat-icon-button [matMenuTriggerFor]="menu">
                    <mat-icon>more_vert </mat-icon>
                  </button>
                  <mat-menu class="dashboard-menu mat-menu-box" #menu="matMenu" xPosition="before" yPosition="below">
                    <button mat-menu-item class="btn-hover" (click)="addTest(i)" [disabled]="getControlsLength(i) > 4">
                      <span class="gph-img-wrp ad"><img src="/assets/icons/r-plus.svg" alt="plus-icon" /></span>
                      <span class="menu-text">Add Test</span>
                    </button>
                    <button mat-menu-item class="btn-hover" *ngIf="false" (click)="removeTest(i)"
                      [disabled]="getControlsLength(i) < 3">
                      <span class="gph-img-wrp"><img src="/assets/icons/Delete.svg" alt="plus-icon" /></span>
                      <span class="menu-text">Remove Test</span>
                    </button>
                    <button mat-menu-item class="btn-hover" (click)="markAsComplete(i)"
                      [disabled]="form.value.items[i][0]?.graphStatus">
                      <span class="gph-img-wrp t" *ngIf="!form.value.items[i][0]?.graphStatus">
                        <img src="/assets/icons/tick.svg" alt="tick-icon" />
                      </span>
                      <span class="gph-img-wrp gt" *ngIf="form.value.items[i][0]?.graphStatus">
                        <img src="/assets/icons/Check.svg" alt="tick-icon" />
                      </span>
                      <span class="menu-text">Mark as Complete</span>
                    </button>
                  </mat-menu>
                </div>
                <div class="clearfix"></div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="right-t-content-wrapper">
      <div class="thermal-header">
        <div class="head">
          <h1 *ngIf="pageIndex < 9">
            Figure 0{{ getFigureCount() }}
            <img src="assets/icons/triagle.svg"
              *ngIf="graphDataProcessCompleted && figureCompletedArray[getFigureCount() - 1] === false" alt />
            <img src="assets/icons/Check.svg"
              *ngIf="graphDataProcessCompleted && figureCompletedArray[getFigureCount() - 1] === true" alt />
          </h1>
          <h1 *ngIf="pageIndex >= 9">
            Figure {{ getFigureCount() }}
            <img src="assets/icons/triagle.svg"
              *ngIf="graphDataProcessCompleted && figureCompletedArray[getFigureCount() - 1] === false" alt />
            <img src="assets/icons/Check.svg"
              *ngIf="graphDataProcessCompleted && figureCompletedArray[getFigureCount() - 1] === true" alt />
          </h1>
        </div>
      </div>
      <div class="canvas-wrapper">
        <canvas #canvas> </canvas>
      </div>
    </div>
    <div class="clearfix"></div>
  </div>
  <div class="thermal-page-button-wrapper">
    <div class="button-left">
      <button mat-raised-button (click)="prev()" [disabled]="prevDisabled">
        <mat-icon>navigate_before</mat-icon>
        PREV
      </button>
      <button mat-raised-button [routerLink]="['/report/edit', soilType, projectId]" [queryParams]="{ edit: 'true' }">
        BACK TO REPORT
      </button>
    </div>
    <div class="middle">
      <div class="progress-wrapper">
        <div class="tx-w">
          <span class="l">Samples Progress</span>
          <span class="r">{{ sampleCompletedCount }} of {{ sampleCount }}</span>
          <div class="clearfix"></div>
        </div>
        <div>
          <div class="gr" *ngIf="sampleCompletedCount !== 0"
            [style.width.%]="(sampleCompletedCount / sampleCount) * 100"></div>
          <div class="sh"></div>
        </div>
      </div>
    </div>
    <div class="middle">
      <div class="progress-wrapper">
        <div class="tx-w">
          <span class="l">Figure Progress</span>
          <span class="r">{{ figCompletedCount() }} of {{ figureCountArray.length }}</span>
          <div class="clearfix"></div>
        </div>
        <div>
          <div class="gr" *ngIf="figCompletedCount() !== 0"
            [style.width.%]="(figCompletedCount() / figureCountArray.length) * 100"></div>
          <div class="sh"></div>
        </div>
      </div>
    </div>
    <div class="button-right">
      <button mat-raised-button color="primary" [disabled]="!this.form.valid" (click)="save()">SAVE</button>
      <button mat-raised-button class="c-next" (click)="next()" [disabled]="nextDisabled">
        NEXT<mat-icon>navigate_next</mat-icon>
      </button>
    </div>
    <div class="clearfix"></div>
  </div>
</div>