import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AllAdminService {
  private adminDetails = new BehaviorSubject<any>(null);

  constructor(private http: HttpClient) {}

  public getAdmin() {
    return this.getuserConfig('admin');
  }

  public getAdminDashboard() {
    return this.getuserConfig('dashboard');
  }

  public getAdminPrecast() {
    return this.getuserConfig('Pre-Cast Worksheet');
  }

  public getNativeReceived() {
    return this.getuserConfig('As Received M/C');
  }

  public getNativeMoisture() {
    return this.getuserConfig('Moisture Conditioning Sheet');
  }

  public getNativeCompaction() {
    return this.getuserConfig('Native Soil / Compaction sheet');
  }

  public getNativeProctor() {
    return this.getuserConfig('Proctor worksheet');
  }

  public getAdminConfig() : Observable<any> {
    return this.getuserConfig('admin');
  }

  getAdminDetails(): Observable<any> {
    return this.adminDetails.asObservable();
  }

  updateConfig(newData: any): void {
    this.adminDetails.next(newData);
  }

  private getuserConfig(tabName: string) {
    let params = new HttpParams();
    params = params.set('tabName', tabName);
    return this.http.get<any>(`${environment.baseUrl}user/getuserconfigs`, {
      params,
    });
  }
}
