import { Injectable, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { ConfirmationDialogComponent } from '../components/confirmation-dialog/confirmation-dialog.component';
import { PrecastService } from './precast.service';
import { messagesConfig } from '../constants/message.constants';

@Injectable({
  providedIn: 'root',
})
export class DeleteService implements OnDestroy {
  private destroyed$ = new Subject<void>();

  constructor(
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private deleteService: PrecastService,
    private router:Router,
  ) {}

  softDelete(projectID: string, soilType: string): void {
    const deleteRecordDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      panelClass: 'confirmation-dialog',
      data: {
        title: 'Delete record',
        content:
        'This will delete all the project related data including worksheets from every location.'
        + 'Are you sure you want to delete it? Please download a copy of the Project related data in a zipped folder to avoid any loss.',
        cancelText: 'No, Do not delete permanently',
        continueText: 'Yes, Delete record permanently',
      },
      disableClose: true,
    });

    deleteRecordDialogRef.afterClosed().subscribe((response?: string) => {
      if (response === 'YES') {
        const soilValue: number = soilType === 'Native Soil' ? 1 : 0;
        this.softDeleteRecord(projectID, soilValue);
      }
    });
  }

  softDeleteRecord(projectID: string, soilValue: number): void {
    const routeTo = '/dashboard';
    this.deleteService
      ?.softDeleteRecord(projectID, soilValue)
      ?.pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (response) => {
          if (response) {
            this.snackBar.open(messagesConfig.softDeleteMessage.message, 'DONE', {
              duration: 3000,
              verticalPosition: 'top',
              panelClass: ['default-snackbar'],
            });
            this.router.navigate([routeTo]);
          }
        },
        error: (err) => console.error('Error API:', err),
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
