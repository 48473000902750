import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[appRemoveLeadingZero]',
})
export class RemoveLeadingZeroDirective {
  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event']) onInput(): void {
    const inputElement = this.el.nativeElement;
    let inputValue = inputElement.value;
    if (inputValue.startsWith('0')) {
      inputValue = inputValue.replace(/^0+/, '');
    }

    inputElement.value = inputValue;
  }
}
