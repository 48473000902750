import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { WorksheetService } from 'src/app/features/worksheet/services/worksheet.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IProjectTestData, ITestConfigObject } from '../../models/i-test-data.modal';
import { messagesConfig } from '../../constants/message.constants';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-project-details',
  templateUrl: './project-details.component.html',
  styleUrls: ['./project-details.component.scss'],
})
export class ProjectDetailsComponent implements OnInit {
  config: ITestConfigObject;
  testValue = '';

  constructor(
    private mdr: MatDialogRef<ProjectDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) data: IProjectTestData,
    private worksheetService: WorksheetService,
    private snackBar: MatSnackBar,
    public userService: UserService,
  ) {
    this.config = data;
  }

  ngOnInit(): void {
    if (this.config?.fieldName) {
      this.testValue = (this.config as any)[this.config?.fieldName];
    }
  }

  public onSubmit(): void {
    if (this.config?.fieldName) {
      (this.config as any)[this.config?.fieldName] = this.testValue;
    }
    this.updateProjectDetails();
  }

  public closeDialog(): void {
    this.mdr.close();
  }

  private updateProjectDetails(): void {
    const projectTestPayload: IProjectTestData = {
      ovenDate: this.config?.ovenDate,
      wetTestData: this.config?.wetTestData,
      dryTestData: this.config?.dryTestData,
    };
    this.worksheetService?.updateProjectData(this.config?.projectId, projectTestPayload).subscribe({
      next: () => {
        this.testValue = '';
        this.snackBar.open(messagesConfig.generalSubmitSuccessMessage.message, 'DONE', {
          duration: 3000,
          verticalPosition: 'top',
          panelClass: ['default-snackbar'],
        });
        this.mdr?.close();
      },
      error: () => {
      },
    });
  }
}
