import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'utcToLocal',
})
export class UtcToLocalPipe implements PipeTransform {
  transform(utcDateString: string, dateOrTime?: string): string {
    const utcDate = new Date(utcDateString);
    const localTimeZoneOffset = new Date().getTimezoneOffset();
    const localDate = new Date(utcDate.getTime() - localTimeZoneOffset * 60000);
    if (dateOrTime === 'date') {
      return localDate.toLocaleDateString();
    }
    if (dateOrTime === 'time') {
      return localDate.toLocaleTimeString();
    }
    return localDate.toLocaleString();
  }
}
