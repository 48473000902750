<form [formGroup]="materialTypeForm" #ngForm="ngForm">
  <div class="page-card-container">
    <p class="browser-warning" *ngIf="isBrowserWarning">
      Please use either Chrome or Microsoft Edge browsers to fill and submit the form for your sample testing.
    </p>
    <h4 class="header-title">Submit samples for testing</h4>
    <p>Thank you for choosing GeothermUSA for thermal sample testing.</p>
    <mat-card class="cards p-3 mb-3">
      <mat-card-content>
        <p style="font-size: 16px">Please choose the type of samples you wish to submit for testing.</p>
        <mat-radio-group aria-label="Select an option" formControlName="materialType" color="primary" required>
          <ul class="list-style-type-none">
            <li>
              <mat-radio-button value="native-soil">Native Soil or Backfill</mat-radio-button>
            </li>
            <li>
              <mat-radio-button value="precast">Pre Cast - Concrete, FTB, Grout/Slurry</mat-radio-button>
            </li>
          </ul>
        </mat-radio-group>
        <mat-error *ngIf="ngForm.submitted && materialTypeForm.get('materialType')?.invalid">
          Please Select Material Type.
        </mat-error>
      </mat-card-content>
    </mat-card>
    <mat-card class="cards p-3 mb-3" *ngIf="!this.userService.captchaValidated">
      <mat-card-content class="m-auto">
        <re-captcha align="center" (resolved)="resolved($event)" siteKey="{{ captchaSiteKey }}"></re-captcha>
        <mat-error *ngIf="ngForm.submitted && !isCaptchaResolved" class="font-size-14">
          Please select the check box above.
        </mat-error>
      </mat-card-content>
    </mat-card>
    <div align="right">
      <button mat-raised-button color="primary" (click)="onClickSubmit()">SUBMIT</button>
    </div>
  </div>
</form>
