import { Component, Input } from '@angular/core';
import { REPORTPREVIEW } from 'src/app/shared/models/i-report-preview.modal';

@Component({
  selector: 'app-precast-sample',
  templateUrl: './precast-sample.component.html',
  styleUrls: ['./precast-sample.component.scss'],
})
export class PrecastSampleComponent {
  @Input() previewData : REPORTPREVIEW;
}
