// prevent-spacebar-propagation.directive.ts

import { Directive, HostListener, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appPreventSpacebarPropagation]',
})
export class PreventSpacebarPropagationDirective {
  private isTextBoxFocused = false;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  @HostListener('focus', ['$event'])
  onFocus(): void {
    this.isTextBoxFocused = true;
  }

  @HostListener('blur', ['$event'])
  onBlur(): void {
    this.isTextBoxFocused = false;
  }

  @HostListener('keydown.space', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    if (this.isTextBoxFocused) {
      event.stopPropagation();
    }
  }

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent): void {
    if (this.el.nativeElement.tagName === 'INPUT') {
      event.stopPropagation();
    }
  }
}
