import { Component } from '@angular/core';
import { Subject } from 'rxjs';

import { PageLoaderService } from './page-loader.service';

@Component({
  selector: 'app-page-loader',
  templateUrl: 'page-loader.component.html',
  styleUrls: ['page-loader.component.scss'],
})
export class PageLoaderComponent {
  isLoading: Subject<boolean> = this.pageLoaderService?.isLoading;
  constructor(private pageLoaderService: PageLoaderService) {}
}
