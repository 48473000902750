export const tableColumns = {
  worksheetColumns: [
    'inHouseId',
    'Sample ID',
    'Sample Type',
    'Mix Design Code',
    'Cast Date',
    'Test',
    'Tare(gm)',
    'Diameter(mm)',
    'Height (mm)',
    'Wet + Tare(gm)',
    'Wet.TR',
    'Dry Soil Weight (gm)',
    'Loss',
    'Vol.',
    'Dry + Tare (gm)',
    'Dry TR',
    'M/C',
    'Density',
    'Tech',
    'Status',
    'Actions',
  ],
};

export const DashboardTable = [
  'projectId',
  'customer',
  'projectName',
  'soilType',
  'submitted',
  'sampleReceivedDate',
  'due',
  'rushed',
  'status',
  'actions',
];

export const AuditReportDashboardTable = [
  'timeStampConcat',
  'userName',
  'resourceType',
  'httpActionMethod',
  'jsonObjectUri',
];

export const typesData = {
  id: '',
  label: 'All',
  order: 0,
  disabled: false,
};
