import { Component, HostListener, OnInit } from '@angular/core';
import { AccountInfo } from '@azure/msal-common';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { EventMessage, EventType } from '@azure/msal-browser';
import { Subscription, filter, timer } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { UserService } from './shared/services/user.service';
import { ConfirmationDialogComponent } from './shared/components/confirmation-dialog/confirmation-dialog.component';
import { AllAdminService } from './shared/services/all-admin.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public countDown = Subscription.EMPTY;
  public counter: number;
  public tick = 1000;
  public popupIndication = false;
  private userAccount: AccountInfo | null;
  private defaultScreenLogout: number;
  private defaultWarningTime = 120;
  location: any;

  constructor(
    public userService: UserService,
    public authService: MsalService,
    public msalBroadCastService: MsalBroadcastService,
    private dialog: MatDialog,
    private adminService: AllAdminService,
    private router: Router,
  ) {
    this.checkLocalStorageUserAndRole();
    localStorage.removeItem('reviewForm');
  }

  ngOnInit() {
    this.customerCallService();
    this.countDown = timer(0, this.tick).subscribe(() => {
      if (this.counter > 0) {
        this.counter -= 1;
        sessionStorage.setItem('logout-counter', this.counter.toString());
        if (this.counter === 0) {
          if (this.popupIndication === true) {
            this.logOut();
          } else if (this.userAccount) {
            if (!window.location.pathname.includes('/in-house-dashboard')) {
              // Disabling Auto logoff for TV dashboard screen
              this.openLogoutConfirmationDialog();
              this.resetCounter(this.defaultScreenLogout);
            }
          }
        }
      }
    });
  }

  customerCallService() {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      const currentUrl = this.router.url;
      // let adminCalling = false;
      if (
        !currentUrl.includes('/customer')
        && !currentUrl.includes('/review')
        && !currentUrl.includes('/sampleform/')
      ) {
        // Team Don't remove the condition if you remove the condition its affect customer way
        // Execute the code only if the URL does not contain /customer, /review, or /sampleform
        this.adminService.getAdmin().subscribe({
          next: (responseList) => {
            const adminObject = responseList.find((item: any) => item.key === 'defaultscreenlogout');
            this.adminService.updateConfig(responseList);
            if (adminObject) {
              this.counter = adminObject.value * 60;
              this.defaultScreenLogout = adminObject.value * 60;
            } else {
              console.log('Key "defaultscreenlogout" not found in the response list');
            }
          },
        });
      }
    });
  }

  @HostListener('window:focus', ['$event'])
  onFocus(): void {
    this.resetCounter(this.defaultScreenLogout);
  }

  @HostListener('window:blur', ['$event'])
  onBlur(): void {
    // this.resetCounter(0);
  }

  @HostListener('mouseup', ['$event'])
  onMouseup(event: MouseEvent) {
    if (event) {
      this.resetCounter();
    }
  }

  @HostListener('mousemove', ['$event'])
  onMousemove(event: MouseEvent) {
    if (event) {
      this.resetCounter();
    }
  }

  @HostListener('keyup', ['$event'])
  onKeyUp(event: any) {
    if (event) {
      this.resetCounter();
    }
  }

  scrolling: boolean;
  @HostListener('window:scroll', ['$event']) onScrollEvent($event: any) {
    if ($event) {
      this.resetCounter();
    }
  }

  resetCounter(counterTime: number = this.defaultScreenLogout) {
    this.counter = counterTime || this.defaultScreenLogout;
  }

  openLogoutConfirmationDialog() {
    this.popupIndication = true;
    this.resetCounter(this.defaultScreenLogout);
    this.dialog
      .open(ConfirmationDialogComponent, {
        panelClass: 'confirmation-dialog',
        data: {
          title: 'User Inactivity - Logout',
          content:
            'Please save your work as you will soon be logged out due to inactivity. Do you wish to Continue or logoff?',
          cancelText: 'Stay Logged In',
          continueText: 'Logoff',
        },
        disableClose: true,
      })
      .afterClosed()
      .subscribe((response?: string) => {
        this.popupIndication = false;
        if (response === 'NO') {
          this.resetCounter();
        } else if (response === 'close') {
          this.resetCounter();
        } else {
          this.logOut();
        }
      });
  }

  checkLocalStorageUserAndRole() {
    this.msalBroadCastService.msalSubject$
      .pipe(filter((msg: EventMessage) => msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS))
      .subscribe({
        next: (result: any) => {
          const account = result?.payload?.account;
          if (account && this.isGeoThermUser(account.username)) {
            this.authService.instance.setActiveAccount(account);
            this.userService.userAccount.next(account);
            this.userService.userRole.next(account.idTokenClaims?.roles || null);
            this.userAccount = account;
          } else {
            this.authService.logoutRedirect();
          }
        },
        error: () => {},
      });
    const accountKey: string[] = JSON.parse(localStorage.getItem('msal.account.keys') || '{}');
    if (Object.keys(accountKey).length > 0) {
      const account: AccountInfo = JSON.parse(localStorage.getItem(accountKey[0]) || '{}');
      this.userService.userAccount.next(account);
      this.userAccount = account;
    }
  }

  isGeoThermUser(userName: string): boolean {
    return userName.indexOf('geothermusa.com') > 1;
  }

  logOut() {
    sessionStorage.clear();
    sessionStorage.removeItem('logout-counter');
    sessionStorage.removeItem('Refresh-counter');
    this.router
      .navigateByUrl('dashboard')
      .then(() => {
        this.authService.logout();
      })
      .finally(() => { });
  }
}
