<div class="d-flex justify-content-md-center align-items-center" style="min-height: 70.5vh; max-width: 100%;">
  <div class="row align-items-center justify-content-md-center">
    <div class="col-md-3">
      <img src="../../../assets/icons/Search-404.svg" alt="Page not found. Searching for the Page"/>
    </div>
    <div class="col-md-12" style="align-items: center;">
      <h3 class="text-align c-primary oops-text">Ooops....</h3>
      <p style="text-align: center; display: block;">
        The page you requested dose not exist. Please check your link and try again.
      </p>
    </div>
    <div class="col-md-2">
      <a class="text-align primary" [routerLink]="['/dashboard']" mat-raised-button *ngIf="userAccount">Dashboard</a>
      <a class="text-align primary" [routerLink]="['/customer']" mat-raised-button *ngIf="!userAccount">Home
        Page</a>
    </div>
  </div>
</div>