export const PdfNativeTableColumns = [
  'sampleId',
  'depth',
  'effort',
  'description',
  'wet',
  'dry',
  'moistureContent',
  'density',
];

export const PdfPrecastTableColumns = [
  'sampleId',
  'collectionDate',
  'matrialType',
  'moistureContent',
  'density',
  'dry',
  'wet',
];
