import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgChartsModule } from 'ng2-charts';
import { MatSelectModule } from '@angular/material/select';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatSliderModule } from '@angular/material/slider';
import { MatRadioModule } from '@angular/material/radio';
import { FormsModule } from '@angular/forms';
import { ModalContentComponent } from './components/modal-content/modal-content.component';
import { LineChartComponent } from './components/line-chart/line-chart.component';
import { GeoMapContainerComponent } from './geo-map-container.component';
import { GeoMapRoutingModule } from './geo-map.routing.module';

@NgModule({
  declarations: [GeoMapContainerComponent, ModalContentComponent, LineChartComponent],
  imports: [
    CommonModule,
    GeoMapRoutingModule,
    MatIconModule,
    MatSelectModule,
    MatDialogModule,
    NgChartsModule,
    MatButtonModule,
    MatSliderModule,
    MatRadioModule,
    FormsModule,
  ],
})
export class GeoMapModule {}
