import { IMessage } from '../models/message.modal';

export const errorMessages = {
  badRequest: <IMessage>{
    title: 'Error',
    message:
    'There is a technical error.Please see below for more details. If this issue persists, please contact # team.',
    type: 'WARNING',
  },
  autehntication: <IMessage>{
    title: 'Error',
    message:
      'Sorry, It seems that you are not authorized to access this resource.'
      + '<br>Please check your credentials and try again, if the issue persists contact # team.',
    type: 'WARNING',
  },
  accessDenied: <IMessage>{
    title: 'Error',
    message:
      'Sorry, you don\'t have the necessary permissions to access this resource.'
      + '<br>Please check your credentials and try again, if the issue persists contact # team.',
    type: 'WARNING',
  },
  pageNotFound: <IMessage>{
    title: 'Error',
    message:
      'The page you are looking for could not be found. Please contact administrator.'
      + '<br>If the issue persists contact # team.',
    type: 'WARNING',
  },
  internalServer: <IMessage>{
    title: 'Error',
    message:
      'Sorry, Something went wrong. Please try again later.If the issue persists contact # team.',
    type: 'WARNING',
  },
  serviceUnavailable: <IMessage>{
    title: 'Error',
    message:
      'Service unavailable. Please try again later.If the issue persists contact # team.',
    type: 'WARNING',
  },
  uiError: <IMessage>{ // TODO comment
    title: 'Error',
    message:
      'Sorry, Something went wrong!'
      + '<br>Please try refreshing the page, if issue persists contact support team.',
    type: 'WARNING',
  },
  internetConnection: <IMessage>{
    title: 'Error',
    message:
      'It seems like there is an issue with your internet connection.'
      + '<br>Check your connection and try again, if issue persists please contact # team.',
    type: 'WARNING',
  },
  formSubmitErrorMessage: <IMessage>{
    title: 'Error Submitted Form',
    message:
      'There is a technical error while submitting your form. Please try again.<br>If this issue persists, please contact # team.',
    type: 'WARNING',
  },

};
