import { Component, Input } from '@angular/core';

import { IProjectCountResponse } from '../../models/iprojects-count.modal';
import { IKeypair } from '../../models/i-keypair.modal';

@Component({
  selector: 'app-project-count',
  templateUrl: './project-count.component.html',
  styleUrls: ['./project-count.component.scss'],
})
export class ProjectCountComponent {
  public filteredProjectCount: IKeypair[] = [];
  @Input() set projectCount(value: IProjectCountResponse[]) {
    this.separateCounts(value);
  }

  // TODO: Need to move the harcoded text to the constant file
  private separateCounts(projectCount: IProjectCountResponse[]): void {
    this.filteredProjectCount = [
      {
        key: 'Due to Arrive',
        value: `${this.GetCount(projectCount, 'Samples Due To Arrive')}`,
      },
      {
        key: 'Shipment Rcvd',
        value: `${this.GetCount(projectCount, 'Shipment Received')}`,
      },
      {
        key: 'In Progress',
        value: `${
          this.GetCount(projectCount, 'Awaiting Instructions')
          + this.GetCount(projectCount, 'Testing In Progress')
          + this.GetCount(projectCount, 'Lab Manager Review')
          + this.GetCount(projectCount, 'Ready To Report')
          + this.GetCount(projectCount, 'Initial Review')
          + this.GetCount(projectCount, 'Management Review')
          + this.GetCount(projectCount, 'Management Approved')
          + this.GetCount(projectCount, 'Accounting')
          + this.GetCount(projectCount, 'Invoiced & Report Not Sent')
        }`,
      },
    ];
  }

  private GetCount(projectCount: IProjectCountResponse[], key: string): number {
    const count = projectCount?.find((x) => x?.key === key);
    return count ? count?.value : 0;
  }
}
