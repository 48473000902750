import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'app-message-modal',
  templateUrl: './message-modal.component.html',
  styleUrls: ['./message-modal.component.scss'],
})
export class MessageModalComponent {
  config: any; // TODO We need to remove this any datatype

  constructor(
    private mdr: MatDialogRef<MessageModalComponent>,
    @Inject(MAT_DIALOG_DATA) data: MatDialog,
    private clipboard: Clipboard,
  ) {
    this.config = data;
  }

  closeDialog(): void {
    this.mdr?.close(false);
  }

  copyToClipBoard(errorId: string): void {
    this.clipboard.copy(`ErrorID: ${errorId}`);
  }
}
