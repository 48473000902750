import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function ValidateNoDecimal(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const noDecimalRegEx = /^(?!0\d)([1-9]\d{0,4}|99999)$/g;
    if (control.value !== 0 && !noDecimalRegEx.test(control.value)) {
      return {
        valid: false,
      };
    }
    return null;
  };
}
