import { Injectable, ErrorHandler } from '@angular/core';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { errorMessages } from '../constants/error-message.constants';
import { LoggingService } from './logging.service';
import { CustomException } from '../models/i-custom-exception.modal';
import { IMessage } from '../models/message.modal';

@Injectable({
  providedIn: 'root',
})
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private loggingService: LoggingService, private dialogService: DialogService) {}
  handleError(error: any): void {
    console.log(error);
    if (error instanceof Error) {
      const newGuid: string = this.loggingService.generateGuid();
      const logError: CustomException = {
        frondendID: newGuid,
        message: '',
        backendID: '',
        stack: error.stack,
      };
      this.loggingService.logEvent('UI Error', logError);
      const errorMessage: IMessage = errorMessages.uiError;
      errorMessage.errorID = newGuid;
      this.dialogService.uiErrorOpen(errorMessage);
    }
  }
}
