<h2 mat-dialog-title class="mat-h2">
  Set Status
  <button mat-icon-button class="close-button" (click)="closeDialog('close')">
    <mat-icon>close</mat-icon>
  </button>
</h2>
<mat-dialog-content>
  <form [formGroup]="statusForm">
    <div class="row">
      <div class="col-md-12">
        <mat-label class="mat-subtitle-2">Status<span class="required">*</span></mat-label>
        <mat-form-field appearance="outline">
          <mat-select placeholder="Select" formControlName="statusId" required>
            <mat-option *ngFor="let data of lookupDropdown" [value]="data?.id" [disabled]="data?.disabled">
              {{ data?.label }}
            </mat-option>
          </mat-select>
          <mat-error class="status-err-text" *ngIf="statusForm?.get('statusId')?.hasError('required')">
            Please select status.
          </mat-error>
        </mat-form-field>
      </div>
      <!-- <div class="col-md-12 mar-top-16">
        <mat-label class="mat-subtitle-2">Additional Notes</mat-label>
        <mat-form-field appearance="outline" class="text-area">
          <textarea
            class="textarea-popup"
            matInput
            formControlName="note"
            rows="3"
            maxlength="500"
            placeholder="Max 500 characters"
            oninput="this.value = this.value.replace(/[^A-Za-z0-9-,.;'&/.() ]|^ /g,'')"></textarea>
        </mat-form-field>
      </div> -->
    </div>
  </form>
</mat-dialog-content>
<div>
  <mat-divider></mat-divider>
  <mat-dialog-actions align="end">
    <button mat-raised-button (click)="closeDialog('close')">CANCEL</button>
    <button mat-raised-button color="primary" (click)="updateStatus()">SAVE</button>
  </mat-dialog-actions>
</div>
