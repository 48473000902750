import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: 'input[appNumbersOnly]',
})
export class NumberDirective {
  @Input() allowCommas: boolean;
  @Input() allowPlus: boolean;
  @Input() maxLength = 255;
  @Input() precession = 1;
  @Input() decimal: boolean;
  constructor(private element: ElementRef) {}

  @HostListener('input', ['$event']) onInputChange(event: KeyboardEvent) {
    const initialValue = this.element.nativeElement.value.replace(/^0+/, '');
    if (this.allowCommas) {
      this.element.nativeElement.value = initialValue.replace(/[^0-9.]*/g, '');
      let value = initialValue.replace(/[^0-9,]*/g, '');
      if (value.slice(-2) === ',,') {
        value = value.slice(0, -1);
      }
      let formattedValue = value.replace(/(\d{3})(?=\d)/g, '$1,');
      const hasZeros = /,0/.test(formattedValue);
      if (hasZeros) {
        formattedValue = formattedValue.slice(0, -1);
      }
      this.element.nativeElement.value = formattedValue;
    } else if (this.allowPlus) {
      this.element.nativeElement.value = initialValue.replace(/[^0-9]*/g, '');
    } else if (this.decimal) {
      this.element.nativeElement.value = initialValue.replace(/[^0-9.]*/g, '');
      const decimalCount: number = initialValue.split('.').length;
      if (decimalCount > 1) {
        // More than one decimal point, remove the extra ones
        const parts: string[] = initialValue.split('.');
        const integerPart: string = parts[0];
        const decimalPart: string = parts[1].substring(0, 1);
        this.element.nativeElement.value = `${integerPart}.${decimalPart}`;
      }
    } else {
      this.element.nativeElement.value = initialValue.replace(/[^0-9]*/g, '');
    }
    if (initialValue !== this.element.nativeElement.value) {
      event.stopPropagation();
    }
  }
}
