<div class="report-ppoup-wrapper">
  <h2 mat-dialog-title class="mat-h">
    Manage Report
    <button mat-icon-button class="close-button" (click)="closeDialog()">
      <mat-icon>close</mat-icon>
    </button>
  </h2>
  <mat-dialog-content>
    <div class="flex-container">
      <p class="c-primary" *ngIf="!config?.dueDate">
        Please check Due Date is updated. <br />
        Cannot download report without any updated Due Date.
      </p>
    </div>
    <div class="flex-container">
      <div class="flex-child">
        <p>{{ config?.title }}</p>
        <p style="font-weight: normal">Assigned To: {{ config?.assignedUser }}</p>
      </div>
      <div class="flex-child">
        <button
          mat-raised-button
          (click)="closeDialog()"
          [routerLink]="['/report/edit', config.type, config.id]"
          [disabled]="!config?.dueDate  || config?.geothermField"
          [queryParams]="{ edit: 'true' }">
          EDIT
        </button>
        <button
          mat-raised-button
          color="primary"
          (click)="closeDialog()"
          [disabled]="!config?.dueDate"
          [routerLink]="['/report/view', config.type, config.id]">
          VIEW
        </button>
      </div>
    </div>
    <div class="flex-container">
      <div class="flex-child">
        <span
          [class.c-success]="config?.status === 'Management Approved'"
          [class.c-primary]="config?.status !== 'Management Approved'">
          {{ config?.status }}
        </span>
      </div>
      <div class="flex-child"></div>
    </div>
    <div class="flex-container" *ngIf="config?.geothermField">
      <div class="project-column">
        <mat-chip class="chipset-green">
          <img src="./../../../assets/icons/pre-approval-tick.svg" class="chipset-green-icon" alt="Geo-Testing-Success" />
          <span class="text-white chip-text">Geotherm Field Testing </span>
        </mat-chip>
      </div>
    </div>
  </mat-dialog-content>
  <mat-divider></mat-divider>
  <mat-dialog-actions align="end">
    <button class="close" mat-raised-button (click)="closeDialog()">CANCEL</button>
  </mat-dialog-actions>
</div>
