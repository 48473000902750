import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PageLoaderService {
  isLoading = new Subject<boolean>();
  loaderCount = 0;

  addLoaderCount() {
    this.loaderCount += 1;
    this.show();
  }

  reduceLoaderCount() {
    if (this.loaderCount > 0) {
      this.loaderCount -= 1;
    }
    if (this.loaderCount <= 0) {
      this.hide();
    }
  }

  show() {
    this.isLoading.next(true);
  }

  hide() {
    this.isLoading.next(false);
  }
}
