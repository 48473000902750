import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatRadioModule } from '@angular/material/radio';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatTableModule } from '@angular/material/table';

import { TechnicalInstructionsComponent } from './components/technical-instructions/technical-instructions.component';
import { SampleDataNativeSoilComponent } from './components/sample-data-nativeSoil/sample-data-nativeSoil.component';
import { CompanyInformationComponent } from './components/company-information/company-information.component';
import { RequirementCommentComponent } from './components/requirement-comment/requirement-comment.component';
import { SampleDataPrecastComponent } from './components/sample-data-precast/sample-data-precast.component';
import { PreventSpacebarPropagationDirective } from '../../shared/directives/prevent-space-bar.directive';
import { SampleCollectionFormRoutingModule } from './sample-collection-form.routing.module';
import { SampleCollectionFormComponent } from './sample-collection-form.component';
import { SharedModule } from '../../shared/shared.module';

@NgModule({
  declarations: [
    CompanyInformationComponent,
    RequirementCommentComponent,
    SampleDataPrecastComponent,
    SampleDataNativeSoilComponent,
    SampleCollectionFormComponent,
    TechnicalInstructionsComponent,
    PreventSpacebarPropagationDirective,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    SampleCollectionFormRoutingModule,
    MatStepperModule,
    MatTableModule,
    MatDialogModule,
    MatIconModule,
    MatCardModule,
    MatFormFieldModule,
    MatTooltipModule,
    MatRadioModule,
    MatExpansionModule,
    MatDatepickerModule,
    MatOptionModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    MatRadioModule,
    MatButtonModule,
    MatSlideToggleModule,
  ],
})
export class SampleCollectionFormModule {}
