<h2 mat-dialog-title class="mat-h2">
  Assign
  <button mat-icon-button class="close-button" (click)="closeDialog(false)">
    <mat-icon>close</mat-icon>
  </button>
</h2>
<mat-dialog-content>
  <form [formGroup]="assignToLabtechForm">
    <div class="row">
      <div class="col-md-12">
        <mat-label class="mat-subtitle-2">Assign To<span class="required">*</span></mat-label>
        <mat-form-field appearance="outline">
          <mat-select placeholder="Select" formControlName="userId" required>
            <mat-option *ngFor="let data of userDropDown" [value]="data?.id">
              {{ data?.givenName }} {{ data?.surname }}
            </mat-option>
          </mat-select>
          <mat-error class="assign-err-text" *ngIf="assignToLabtechForm.get('userId')?.hasError('required')">
            Please select the Assignee.
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-12 mar-top-16" *ngIf="assignTypeProject">
        <mat-checkbox
          color="primary"
          class="mat-subtitle-2 mar-text-align"
          [checked]="false"
          formControlName="assignAll">
          <span class="text-sample">Assign to all samples/rows</span>
        </mat-checkbox>
      </div>
      <div class="col-md-12">
        <mat-label class="mat-subtitle-2">Additional Notes</mat-label>
        <mat-form-field appearance="outline" class="text-area">
          <textarea
            class="textarea-popup"
            matInput
            formControlName="additionalNotes"
            rows="3"
            maxlength="500"
            placeholder="Max 500 characters"
            oninput="this.value = this.value.replace(/[^A-Za-z0-9-,.;'&/.() ]|^ /g,'')"></textarea>
        </mat-form-field>
      </div>
    </div>
  </form>
</mat-dialog-content>
<mat-divider></mat-divider>
<mat-dialog-actions align="end">
  <button mat-raised-button (click)="closeDialog(false)">CANCEL</button>
  <button mat-raised-button color="primary" [disabled]="this.assignToLabtechForm.invalid" (click)="assignToLabtech()">
    ASSIGN
  </button>
</mat-dialog-actions>
