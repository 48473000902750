<h2 mat-dialog-title class="header-title">Testing Instructions</h2>
<mat-dialog-content class="instructions-content">
  <p>For bulk samples, all sample testing instructions are required for testing to begin.</p>
  <ol>
    <li>Proctor - (Modified or Standard) Please select the applicable Proctor results</li>
    <li>Compaction Effort(s) - At what effort will the material will be installed (example: 85, 95, etc.)</li>
    <li>Test Moisture Content(s) - Please select the applicable Moisture Content</li>
  </ol>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button color="primary" (click)="closeDialog()">CLOSE</button>
</mat-dialog-actions>
