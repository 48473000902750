import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { messagesConfig } from 'src/app/shared/constants/message.constants';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/shared/services/user.service';
import { ValidateOneDecimal } from 'src/app/shared/validators/one-decimal.validator';
import { ValidateNoDecimal } from 'src/app/shared/validators/no-decimal.validator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PrecastWorksheet } from '../../models/precast-worksheet.modal';
import { WorksheetService } from '../../services/worksheet.service';

@Component({
  selector: 'app-edit-precast-worksheet',
  templateUrl: './edit-precast-worksheet.component.html',
  styleUrls: ['./edit-precast-worksheet.component.scss'],
})
export class EditPrecastWorksheetComponent implements OnInit, OnDestroy {
  public editWorksheetForm: FormGroup;

  public worksheetRow = new PrecastWorksheet();
  public currentIndex = -1;
  public worksheets: PrecastWorksheet[];
  public mode: string | undefined;
  public titleError = false;
  public selectedIndex = 0;
  public validWeight = true;
  public validTR = true;
  public moveTo: string;
  public editWorksheetSubscription = Subscription.EMPTY;

  public isRowCountDifferent: boolean;
  public countRow: number;
  errorMsg: string = ''; // Error message if numbers are not similar
  private previousCountRow: number | null = null; // Holds the previous value of countRow
  previousSampleId = '';

  constructor(
    private mdr: MatDialogRef<EditPrecastWorksheetComponent>,
    @Inject(MAT_DIALOG_DATA) data: any,
    private worksheetService: WorksheetService,
    public userService: UserService,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
  ) {
    this.mode = data.mode;
    this.worksheets = data.worksheets;
    this.currentIndex = this.worksheets.findIndex((x: PrecastWorksheet) => x.id === data.element.id);
    this.worksheetRow = this.worksheets[this.currentIndex];
  }

  ngOnInit() {
    this.buildEditForm();
  }

  buildEditForm(): void {
    const wetValues = [
      {
        wetTr: this.worksheetRow.wetTr,
        wetPlusTare: this.worksheetRow.wetPlusTare,
      },
      ...this.worksheetRow.multiPointTests,
    ];
    this.editWorksheetForm = this.fb.group({
      inHouseId: [this.worksheetRow?.inHouseId, [Validators.required]],
      tare: [this.worksheetRow?.tare, [Validators.required, ValidateNoDecimal()]],
      mould: [this.worksheetRow?.mould, [ValidateNoDecimal()]],
      sampleDiameter: [this.worksheetRow?.sampleDiameter, [Validators.required, ValidateOneDecimal()]],
      sampleHeight: [this.worksheetRow?.sampleHeight, [Validators.required, ValidateOneDecimal()]],
      multiPointTests: this.fb.array([]),
      dryPlusTare: [this.worksheetRow?.dryPlusTare, [Validators.required, ValidateNoDecimal()]],
      dryTr: [this.worksheetRow?.dryTr, [Validators.required, ValidateNoDecimal()]],
    });
    this.isRowCountDifferent = this.worksheetRow.isSetRowCountDifferent;

    this.checkCountRow(wetValues.length);

    wetValues.forEach((wetValue, index) => {
      this.getmultiPointTests.push(this.createItem());
      this.getmultiPointTests.at(index).patchValue(wetValue);
    });
  }

  /**
   * Updates the count row and validates if the sample has the same test points as other samples in the set.
   *
   * @param {number} countRownumber - The number of the row to be checked.
   *
   * This method performs several checks to ensure that the sample being processed has the same test points
   * as the other samples in the set. If any of the conditions fail, an appropriate error message is set.
   */
  checkCountRow(countRownumber: number) {
    this.countRow = countRownumber;
    this.errorMsg = '';

    const message = 'Please ensure the sample has same test points as the other samples in the set.';
    const isSameSample = this.previousSampleId === this.worksheetRow.sampleId;
    const isCountDifferent = this.previousCountRow !== null && this.previousCountRow !== this.countRow;
    if (this.worksheets.length > 1) {
      if (this.isRowCountDifferent) {
        if (this.previousSampleId) {
          if (isSameSample && isCountDifferent) {
            this.errorMsg = message;
          } else if (!isSameSample) {
            this.errorMsg = message;
          }
        } else {
          this.errorMsg = message;
        }
      } else if (this.previousSampleId && isSameSample && isCountDifferent) {
        this.errorMsg = message;
      }
    }
    this.previousCountRow = this.countRow;
    this.previousSampleId = this.worksheetRow.sampleId!;
  }

  createItem(): FormGroup {
    return this.fb.group({
      wetPlusTare: ['', [Validators.required, ValidateNoDecimal()]],
      wetTr: ['', [Validators.required, ValidateNoDecimal()]],
    });
  }

  get getmultiPointTests(): FormArray {
    return this.editWorksheetForm.controls['multiPointTests'] as FormArray;
  }

  addSample(): void {
    if (this.getmultiPointTests.length < 4) {
      this.getmultiPointTests.push(this.createItem());
      this.checkCountRow(this.countRow + 1);
    }
  }

  removeItem(index: number) {
    this.getmultiPointTests.removeAt(index);
    this.editWorksheetForm.markAsDirty();
    this.checkCountRow(this.countRow - 1);
  }

  saveWorksheetData(isSave?: boolean) {
    if (isSave) {
      this.moveTo = '';
    }
    const updated = this.editWorksheetForm.value as PrecastWorksheet;
    const [mainWet, ...restWet] = this.getmultiPointTests.value;
    this.worksheetRow.inHouseId = updated.inHouseId;
    this.worksheetRow.tare = updated.tare;
    this.worksheetRow.mould = updated.mould;
    this.worksheetRow.sampleDiameter = updated.sampleDiameter;
    this.worksheetRow.sampleHeight = updated.sampleHeight;
    this.worksheetRow.dryPlusTare = updated.dryPlusTare;
    this.worksheetRow.dryTr = updated.dryTr;
    this.worksheetRow.wetPlusTare = mainWet.wetPlusTare;
    this.worksheetRow.wetTr = mainWet.wetTr;
    this.worksheetRow.multiPointTests = restWet;
    this.validWeight = this.validateValues(this.getmultiPointTests.value, updated.dryPlusTare, true);
    this.validTR = this.validateValues(this.getmultiPointTests.value, updated.dryTr, false);
    if (this.validWeight && this.validTR) {
      this.worksheetRow.status = this.checkMarkAsComplete();
      this.postDataToApi(this.worksheetRow);
    }
  }

  validateValues(sampleArray: any, dryValue: number, weightOrTR: boolean): boolean {
    let returnValue = true;
    sampleArray.forEach((samples: any) => {
      if (weightOrTR) {
        if (samples.wetPlusTare !== 0 && dryValue !== 0 && Number(samples.wetPlusTare) < Number(dryValue)) {
          returnValue = false;
        }
      } else if (samples.wetTr !== 0 && dryValue !== 0 && Number(samples.wetTr) > Number(dryValue)) {
        returnValue = false;
      }
    });
    return returnValue;
  }

  postDataToApi(payload: PrecastWorksheet): void {
    this.editWorksheetSubscription = this.worksheetService.updateWorksheet(payload).subscribe({
      next: (response: PrecastWorksheet) => {
        this.worksheetRow = response;
        this.snackBar.open(messagesConfig.generalSubmitSuccessMessage.message, 'DONE', {
          duration: 3000,
          verticalPosition: 'top',
          panelClass: ['default-snackbar'],
        });
        this.editWorksheetForm.markAsPristine();
        if (this.moveTo === 'DONE') {
          this.closeDialog('');
        } else if (this.moveTo === 'PREVIOUS') {
          this.currentIndex -= 1;
          this.worksheetRow = this.worksheets[this.currentIndex];
        } else if (this.moveTo === 'NEXT') {
          this.currentIndex += 1;
          this.worksheetRow = this.worksheets[this.currentIndex];
        }
        this.buildEditForm();
        this.selectedIndex = 0;
      },
      error: () => {},
    });
  }

  navigateRecords(moveTo: string): void {
    this.moveTo = moveTo;
    if (this.editWorksheetForm.dirty && this.editWorksheetForm.valid) {
      this.saveWorksheetData();
    } else if (this.moveTo === 'PREVIOUS') {
      this.currentIndex -= 1;
      this.worksheetRow = this.worksheets[this.currentIndex];
      this.buildEditForm();
      this.selectedIndex = 0;
    } else if (this.moveTo === 'NEXT') {
      this.currentIndex += 1;
      this.worksheetRow = this.worksheets[this.currentIndex];
      this.buildEditForm();
      this.selectedIndex = 0;
    } else if (this.moveTo === 'DONE') {
      this.closeDialog('');
    }
  }

  worksheetStatus(index: number): boolean {
    return this.worksheets[index].status;
  }

  updateModalTitle(): void {
    const inHouseId = this.editWorksheetForm?.controls['inHouseId']?.value.toLowerCase();
    const isRepeatedInhouseId = this.worksheets?.findIndex(
      (x: PrecastWorksheet) => x.inHouseId?.toLowerCase() === inHouseId,
    );
    if (isRepeatedInhouseId >= 0 && isRepeatedInhouseId !== this.currentIndex) {
      this.titleError = true;
    } else {
      this.titleError = false;
    }
  }

  closeDialog(mode: string): void {
    this.mdr.close(mode);
  }

  checkMarkAsComplete(): boolean {
    const { tare, sampleDiameter, sampleHeight, dryPlusTare, dryTr, wetPlusTare, wetTr, mould } = this.worksheetRow;
    const isSampleTypeValid = this.worksheetRow.sampleType === 'FTB' || this.worksheetRow.sampleType === 'GROUT/SLURRY';
    if (
      tare !== 0 &&
      sampleDiameter !== 0 &&
      sampleHeight !== 0 &&
      dryPlusTare !== 0 &&
      dryTr !== 0 &&
      wetPlusTare !== 0 &&
      wetTr !== 0 &&
      (isSampleTypeValid ? mould !== 0 : true)
    ) {
      return true;
    }
    return false;
  }

  ngOnDestroy() {
    this.editWorksheetSubscription.unsubscribe();
  }
}
