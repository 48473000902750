import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  constructor(private http: HttpClient) {}

  public getAllTabs() {
    return this.http.get<any>(`${environment.baseUrl}admin/getalltabs`);
  }

  public getTabConfigItems(tabName: string) {
    return this.http.get<any>(`${environment.baseUrl}admin/gettabconfig/${tabName}`);
  }

  public updateSettings(payload: any, tabId: string): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}admin/updatetabconfig/${tabId}`, payload);
  }

  public getAllRoles() {
    return this.http.get<any[]>(`${environment.baseUrl}admin/getallroles`);
  }
}
