import { Component, Input } from '@angular/core';
import { REPORTPREVIEW } from 'src/app/shared/models/i-report-preview.modal';

@Component({
  selector: 'app-native-sample',
  templateUrl: './native-sample.component.html',
  styleUrls: ['./native-sample.component.scss'],
})
export class NativeSampleComponent {
  @Input() previewData : REPORTPREVIEW;

  getBoldProject(content: string | undefined): string | undefined {
    if (content) {
      return content.replace(this.previewData?.companyName, `<strong>${this.previewData?.companyName}</strong>`);
    }
    return content;
  }
}
