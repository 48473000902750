/* eslint-disable object-curly-newline */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { INativeSampleData } from 'src/app/shared/models/i-native-sample.modal';
import { DecimalValidator } from 'src/app/shared/validators/one-decimal.validator';
import { IDropdown } from '../../../../shared/models/i-dropdown-type.modal';
import { PrecastService } from '../../../../shared/services/precast.service';
import { messagesConfig } from '../../../../shared/constants/message.constants';
import { DialogService } from '../../../../shared/services/dialog.service';
import { TechnicalInstructionsComponent } from '../technical-instructions/technical-instructions.component';

@Component({
  selector: 'app-sample-data-nativesoil',
  templateUrl: 'sample-data-nativeSoil.component.html',
  styleUrls: ['sample-data-nativeSoil.component.scss'],
})
export class SampleDataNativeSoilComponent implements OnInit {
  @Output() sampleDataNativeData: EventEmitter<INativeSampleData> = new EventEmitter();
  @Input() data: any;
  @Input() set successfullSubmission(value: boolean) {
    if (value) {
      this.sampleDataNativeSoilForm.reset();
      const formLength = this.getSampleControls.controls.length;
      for (let i = 0; i < formLength; i++) {
        this.removeItem(0);
      }
      this.addSample();
    }
  }

  sampleDataNativeSoilForm: FormGroup;
  step = -1;
  public nativeSoilSampleTypes: IDropdown[];
  currentDate = new Date(Date.now());
  selectedIndex = -1;
  compactionEffort = '';
  newControlDisable = false;
  makeSampleEditable = true;
  readytoEmit = false;
  isDepthRequired = false;
  isSpecifiedDisabled = true;
  @Input() set makeEditable(value: boolean) {
    this.makeSampleEditable = value;
    if (!value) {
      this.newControlDisable = true;
    }
  }

  constructor(
    private precastService: PrecastService,
    private dialogService: DialogService,
    private fb: FormBuilder,
    private dialog: MatDialog,
  ) {
    this.sampleDataNativeSoilForm = this.fb?.group({
      samples: this.fb?.array([]),
    });
  }

  ngOnInit(): void {
    this.precastService?.fetchNativeSampleTypes()?.subscribe({
      next: (response) => {
        this.nativeSoilSampleTypes = response;
        const nativesoilType = this.nativeSoilSampleTypes.find((x) => x.label === 'Tube');

        if (this.data) {
          const sampleLength = this.data.length;
          if (sampleLength > 0) {
            this.step = -1;
            for (let i = 0; i < sampleLength; i++) {
              this.getSampleControls.push(this.createItem());
              if (this.data[i].sampleType === nativesoilType?.id) {
                this.removeValidationForTube(this.getSampleControls.controls[i]);
              } else {
                this.setValidatorsForBulk(this.getSampleControls.controls[i]);
              }
            }
          } else {
            this.step = 0;
          }
          this.data.forEach((elem: any, i: number) => {
            this.disabledSpecifiedMC(this.getSampleControls.controls[i]);
            if (elem.moistureOptimum) {
              elem.highestMoistureContent = '1';
            } else if (elem.moistureAsReceived) {
              elem.highestMoistureContent = '2';
            } else if (elem.specifiedMoistureContent) {
              elem.highestMoistureContent = '3';
              this.enableSpecifiedMC(this.getSampleControls.controls[i]);
            } else {
              elem.highestMoistureContent = null;
            }
          });

          const patchValue = {
            samples: this.data,
          };
          let selectedCompactionEffortIndex = -1;
          patchValue.samples.forEach((element: any, i: number) => {
            if (element?.applyCompactionEffort) {
              selectedCompactionEffortIndex = i;
              this.compactionEffort = element.compactionEffort;
              this.newControlDisable = true;
            }
            if (element?.stockPile) {
              const { controls } = this.sampleDataNativeSoilForm.controls['samples'] as FormArray;
              const currentControl = controls[i];
              currentControl.get('sampleDepth')?.patchValue(null);
              currentControl.get('sampleDepth')?.disable();
              currentControl.get('sampleEndDepth')?.patchValue(null);
              currentControl.get('sampleEndDepth')?.disable();
            }
          });
          if (selectedCompactionEffortIndex > -1) {
            this.setApplyCompactionEffort(selectedCompactionEffortIndex);
          }
          this.sampleDataNativeSoilForm.patchValue(patchValue);
        } else {
          this.step = 0;
          this.getSampleControls.push(this.createItem());
        }
        this.readytoEmit = true;
      },
    });
    this.sampleDataNativeSoilForm?.valueChanges?.subscribe((frmGrp) => {
      if (this.readytoEmit) {
        this.sampleDataNativeData?.emit({
          value: frmGrp?.samples,
          valid: this.sampleDataNativeSoilForm?.valid,
          dirty: this.sampleDataNativeSoilForm?.dirty,
        });
      }
    });
  }

  setApplyCompactionEffort(index: number): void {
    const { controls } = this.sampleDataNativeSoilForm.controls['samples'] as FormArray;
    for (let i = 0; i < controls.length; i++) {
      if (i !== index) {
        const nativesoilType = this.nativeSoilSampleTypes.find((x) => x.label === 'Tube');
        if (controls[i].get('sampleType')?.value !== nativesoilType?.id) {
          controls[i].get('applyCompactionEffort')?.patchValue(this.newControlDisable);
          controls[i].get('applyCompactionEffort')?.disable();
          controls[i].get('applyCompactionEffort')?.updateValueAndValidity();
        }
      }
    }
  }

  enableSpecifiedMC(sample: any): void {
    const currentSample = sample as FormGroup;
    currentSample.get('specifiedMoistureContent')?.enable();
  }

  disabledSpecifiedMC(sample: any): void {
    const currentSample = sample as FormGroup;
    currentSample.get('specifiedMoistureContent')?.disable();
  }

  removeApplyCompactionEffortDisable(): void {
    const { controls } = this.sampleDataNativeSoilForm.controls['samples'] as FormArray;
    for (let i = 0; i < controls.length; i++) {
      const nativesoilType = this.nativeSoilSampleTypes.find((x) => x.label === 'Tube');
      if (controls[i].get('sampleType')?.value !== nativesoilType?.id) {
        controls[i].get('applyCompactionEffort')?.patchValue(false);
        controls[i].get('applyCompactionEffort')?.enable();
        controls[i].get('applyCompactionEffort')?.updateValueAndValidity();
      }
    }
  }

  private setValidatorsForBulk(sample: any): void {
    const currentSample = sample as FormGroup;
    currentSample.get('compactionEffort')?.setValidators(Validators.required);
    currentSample.get('compactionEffort')?.updateValueAndValidity();
    currentSample.get('highestMoistureContent')?.setValidators(Validators.required);
    currentSample.get('highestMoistureContent')?.updateValueAndValidity();
    currentSample.get('proctorType')?.setValidators(Validators.required);
    currentSample.get('proctorType')?.updateValueAndValidity();
  }

  public addSample(): void {
    if (this.getSampleControls.length < 50) {
      this.getSampleControls.push(this.createItem());
      // eslint-disable-next-line no-plusplus
      this.step = this.getSampleControls.length - 1;
    } else {
      this.dialogService.open(messagesConfig.maxSampleWarning);
    }
  }

  public removeItem(index: number) {
    this.getSampleControls.removeAt(index);
  }

  public setStep(index: number) {
    this.step = index;
  }

  public onNativeSoilSampleTypeChange(sample: any): void {
    const currentSample = sample as FormGroup;
    this.resetHighestMoistureContent(currentSample);
    switch (currentSample.get('highestMoistureContent')?.value) {
      case '1':
        currentSample.get('moistureOptimum')?.setValue(true);
        currentSample.get('specifiedMoistureContent')?.setValue(null);
        currentSample.get('moistureAsReceived')?.setValue(false);
        currentSample.get('specifiedMoistureContent')?.disable();
        break;
      case '2':
        currentSample.get('moistureOptimum')?.setValue(false);
        currentSample.get('specifiedMoistureContent')?.setValue(null);
        currentSample.get('moistureAsReceived')?.setValue(true);
        currentSample.get('specifiedMoistureContent')?.disable();
        break;
      case '3':
        currentSample.get('specifiedMoistureContent')?.enable();
        currentSample.get('moistureOptimum')?.setValue(false);
        currentSample
          .get('specifiedMoistureContent')
          ?.setValidators([Validators.required, Validators.maxLength(5), DecimalValidator()]);
        currentSample.get('specifiedMoistureContent')?.updateValueAndValidity();
        currentSample.get('moistureAsReceived')?.setValue(false);
        break;
      default:
        break;
    }
  }

  removeValidationForTube(sample: any): void {
    const currentSample = sample as FormGroup;
    currentSample.get('compactionEffort')?.removeValidators(Validators.required);
    currentSample.get('compactionEffort')?.setValue('');
    currentSample.get('compactionEffort')?.updateValueAndValidity();
    currentSample.get('highestMoistureContent')?.removeValidators(Validators.required);
    currentSample.get('highestMoistureContent')?.setValue(null);
    currentSample.get('highestMoistureContent')?.updateValueAndValidity();
    currentSample.get('specifiedMoistureContent')?.removeValidators(Validators.required);
    currentSample.get('specifiedMoistureContent')?.setValue(null);
    currentSample.get('specifiedMoistureContent')?.updateValueAndValidity();
    currentSample.get('proctorType')?.removeValidators(Validators.required);
    currentSample.get('proctorType')?.setValue(null);
    currentSample.get('proctorType')?.updateValueAndValidity();
    currentSample.get('moistureOptimum')?.setValue(false);
    currentSample.get('moistureOptimum')?.updateValueAndValidity();
    currentSample.get('moistureAsReceived')?.setValue(false);
    currentSample.get('moistureAsReceived')?.updateValueAndValidity();
    currentSample.get('maxDryDensity')?.setValue(null);
    currentSample.get('maxDryDensity')?.updateValueAndValidity();
    currentSample.get('optimumMoistureContent')?.setValue(null);
    currentSample.get('optimumMoistureContent')?.updateValueAndValidity();
  }

  public onNativeSoilTypeChange(sample: any): void {
    const nativesoilType = this.nativeSoilSampleTypes.find((x) => x.label === 'Tube');
    const currentSample = sample as FormGroup;
    if (currentSample.get('sampleType')?.value === nativesoilType?.id) {
      this.removeValidationForTube(sample);
      if (!currentSample.get('applyCompactionEffort')?.disabled && currentSample.get('applyCompactionEffort')?.value) {
        this.newControlDisable = false;
        this.compactionEffort = '';
        this.removeApplyCompactionEffortDisable();
      }

      currentSample.get('applyCompactionEffort')?.patchValue(false);
      currentSample.get('applyCompactionEffort')?.enable();
      currentSample.get('applyCompactionEffort')?.updateValueAndValidity();
    } else {
      this.setValidatorsForBulk(sample);
      if (this.newControlDisable) {
        currentSample.get('compactionEffort')?.setValue(this.compactionEffort);
        currentSample.get('compactionEffort')?.updateValueAndValidity();
        currentSample.get('applyCompactionEffort')?.patchValue(this.newControlDisable);
        currentSample.get('applyCompactionEffort')?.disable();
        currentSample.get('applyCompactionEffort')?.updateValueAndValidity();
      }
    }
    // this.setStep(index);
  }

  public datechange(data: Date, i: number): void {
    const controlArray: FormArray = <FormArray> this.sampleDataNativeSoilForm.get('samples');
    controlArray?.controls[i]?.get('sampleCollectionDate')?.setValue(data);
  }

  get getSampleControls(): FormArray {
    return this.sampleDataNativeSoilForm?.controls['samples'] as FormArray;
  }

  public checksoilType(index: number): string {
    const { controls } = this.sampleDataNativeSoilForm.controls['samples'] as FormArray;
    return controls[index].get('sampleType')?.value;
  }

  public onStockPileCheckBoxClicked(currentIndex: number): void {
    const { controls } = this.sampleDataNativeSoilForm.controls['samples'] as FormArray;
    const currentControl = controls[currentIndex];
    if (currentControl.get('stockPile')?.value) {
      currentControl.get('sampleDepth')?.patchValue(null);
      currentControl.get('sampleDepth')?.disable();
      currentControl.get('sampleEndDepth')?.patchValue(null);
      currentControl.get('sampleEndDepth')?.disable();
    } else {
      currentControl.get('sampleDepth')?.clearValidators();
      currentControl.get('sampleDepth')?.enable();
      currentControl.get('sampleEndDepth')?.clearValidators();
      currentControl.get('sampleEndDepth')?.enable();
    }
  }

  public onChangeUseCompactionCheckBox(currentIndex: number): void {
    const { controls } = this.sampleDataNativeSoilForm.controls['samples'] as FormArray;
    const currentControl = controls[currentIndex];
    this.compactionEffort = currentControl.get('applyCompactionEffort')?.value
      ? currentControl.get('compactionEffort')?.value
      : '';
    this.newControlDisable = currentControl.get('applyCompactionEffort')?.value;
    this.selectedIndex = this.newControlDisable ? currentIndex : -1;
    for (let i = 0; i < controls.length; i++) {
      if (i !== currentIndex) {
        const nativesoilType = this.nativeSoilSampleTypes.find((x) => x.label === 'Tube');
        if (controls[i].get('sampleType')?.value !== nativesoilType?.id) {
          controls[i].get('compactionEffort')?.patchValue(this.compactionEffort);
          controls[i].get('applyCompactionEffort')?.patchValue(this.newControlDisable);
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          this.newControlDisable
            ? controls[i].get('applyCompactionEffort')?.disable()
            : controls[i].get('applyCompactionEffort')?.enable();
        }
      }
    }
  }

  public checkProctorTypeIsValid(sample: any): string {
    const currentSample = sample as FormGroup;
    return currentSample.get('proctorType')?.touched && currentSample.get('proctorType')?.hasError('required')
      ? 'Please select Proctor Type.'
      : '';
  }

  public checkhighestMoistureContentIsValid(sample: any): string {
    const currentSample = sample as FormGroup;
    return currentSample.get('highestMoistureContent')?.touched
      && currentSample.get('highestMoistureContent')?.hasError('required')
      ? 'Please select Highest Moisture Content Type.'
      : '';
  }

  public openInstructionsDialog() {
    this.dialog.open(TechnicalInstructionsComponent, {
      data: {},
      disableClose: true,
    });
  }

  private createItem(): FormGroup {
    return this.fb?.group({
      id: [null],
      sampleType: [
        {
          value: '',
          disabled: !this.makeSampleEditable,
        },
        Validators.required,
      ],
      sampleId: ['', Validators.required],
      proctorType: ['', Validators.required],
      collectionDate: [''],
      sampleDepth: [null],
      sampleEndDepth: [null],
      soilDescription: [''],
      stockPile: false,
      maxDryDensity: [
        {
          value: null,
          disabled: !this.makeSampleEditable,
        },
        DecimalValidator(),
      ],
      optimumMoistureContent: [
        {
          value: null,
          disabled: !this.makeSampleEditable,
        },
        DecimalValidator(),
      ],
      compactionEffort: [
        {
          value: this.compactionEffort,
          disabled: !this.makeSampleEditable,
        },
      ],
      applyCompactionEffort: [
        {
          value: this.newControlDisable,
          disabled: this.newControlDisable,
        },
      ],
      highestMoistureContent: [
        {
          value: null,
          disabled: !this.makeSampleEditable,
        },
        Validators.required,
      ],
      moistureOptimum: [
        {
          value: false,
          disabled: !this.makeSampleEditable,
        },
      ],
      moistureAsReceived: [
        {
          value: false,
          disabled: !this.makeSampleEditable,
        },
      ],
      specifiedMoistureContent: [
        {
          value: '',
          disabled: !this.makeSampleEditable,
        },
      ],
    });
  }

  private resetHighestMoistureContent(currentSample: FormGroup): void {
    currentSample.get('specifiedMoistureContent')?.removeValidators(Validators.required);
    currentSample.get('specifiedMoistureContent')?.updateValueAndValidity();
  }

  handlePanelClick(event: Event): void {
    event.stopPropagation();
    event.preventDefault();
  }

  setDepthValidation(index: number, controlName: string, alternateControl: string): void {
    const { controls } = this.sampleDataNativeSoilForm.controls['samples'] as FormArray;
    const currentControl = controls[index];
    const controlDepth = currentControl.get(controlName)?.value;

    if (controlDepth !== '' && controlDepth !== null) {
      currentControl
        .get(alternateControl)
        ?.setValidators([
          Validators.required,
          DecimalValidator(),
          controlName === 'sampleDepth' ? Validators.min(controlDepth) : Validators.max(controlDepth),
        ]);
      currentControl.get(alternateControl)?.updateValueAndValidity();
    } else {
      const alternateControlDepth = currentControl.get(alternateControl)?.value;
      if (alternateControlDepth !== '' && alternateControlDepth !== null) {
        currentControl
          .get(controlName)
          ?.setValidators([
            Validators.required,
            DecimalValidator(),
            controlName === 'sampleDepth' ? Validators.min(controlDepth) : Validators.max(controlDepth),
          ]);
        currentControl.get(controlName)?.updateValueAndValidity();
      } else {
        currentControl.get(alternateControl)?.setValidators(null);
        currentControl.get(alternateControl)?.updateValueAndValidity();
        currentControl.get(controlName)?.setValidators(null);
        currentControl.get(controlName)?.updateValueAndValidity();
      }
    }
  }

  clearDepthValidators(index: number): void {
    const { controls } = this.sampleDataNativeSoilForm.controls['samples'] as FormArray;
    const currentControl = controls[index];
    currentControl.get('sampleDepth')?.clearValidators();
    currentControl.get('sampleEndDepth')?.clearValidators();
    currentControl.get('sampleDepth')?.updateValueAndValidity();
    currentControl.get('sampleEndDepth')?.updateValueAndValidity();
  }

  public depthError(sample: any): string {
    const currentSample = sample as FormGroup;
    let requiredError;
    requiredError = currentSample.get('sampleDepth')?.hasError('required');
    const startDepth = currentSample.get('sampleDepth')?.value;
    const endDepth = currentSample.get('sampleEndDepth')?.value;
    let message = '';
    if (startDepth === '' && endDepth === '') {
      return message;
    }

    if (!requiredError) {
      requiredError = currentSample.get('sampleEndDepth')?.hasError('required');
    }

    if (requiredError) {
      message = 'Please enter the depth value, it cannot be blank.';
    } else if (
      startDepth !== null
      && endDepth !== null
      && Number(endDepth) <= Number(startDepth)
      && !currentSample.get('stockPile')?.value
    ) {
      requiredError = currentSample.get('sampleEndDepth')?.hasError('min');
      message = 'The End depth cannot be less than or equal to Start depth.';
      currentSample.get('sampleEndDepth')?.setValidators([Validators.min(Number(startDepth + 1))]);
      currentSample.get('sampleEndDepth')?.updateValueAndValidity();
    } else if (startDepth !== null && endDepth !== null && !currentSample.get('stockPile')?.value) {
      currentSample.get('sampleEndDepth')?.setValidators(DecimalValidator());
      currentSample.get('sampleEndDepth')?.updateValueAndValidity();
    } else {
      currentSample.get('sampleEndDepth')?.clearValidators();
      currentSample.get('sampleEndDepth')?.updateValueAndValidity();
    }
    currentSample.updateValueAndValidity();
    return message;
  }

  public optimumMoistureContentError(sample: any): string {
    const currentSample = sample as FormGroup;
    const value = currentSample.get('optimumMoistureContent')?.value;
    let message = '';
    message = this.checkForValidDecimal(value);
    return message;
  }

  public maxDryDensityError(sample: any): string {
    const currentSample = sample as FormGroup;
    const value = currentSample.get('maxDryDensity')?.value;
    let message = '';
    message = this.checkForValidDecimal(value);
    return message;
  }

  public specifiedMoistureContentError(sample: any): string {
    const currentSample = sample as FormGroup;
    const value = currentSample.get('specifiedMoistureContent')?.value;
    const hm = currentSample.get('highestMoistureContent')?.value;
    let message = '';
    if (Number(hm) === 3) {
      message = this.checkForValidDecimal(value);
    }
    return message;
  }

  checkForValidDecimal(value: string): string {
    let message = '';
    if (typeof value === 'string') {
      const decimalIndex = value.indexOf('.');
      if (decimalIndex > -1) {
        if (decimalIndex === value.length - 1) {
          message = 'Please enter a valid decimal value.';
        } else {
          message = '';
        }
      }
    }
    return message;
  }
}
