<mat-table
  #table
  [dataSource]="dataSource"
  cdkDropList
  (cdkDropListDropped)="dragDrop($event)"
  cdkDropListData="dataSource"
  [cdkDropListDisabled]="dragDisabled">
  <mat-header-row *matHeaderRowDef="tableColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: tableColumns" cdkDrag [cdkDragData]="row"></mat-row>
  <!--  Column No -->
  <ng-container matColumnDef="columnNo">
    <mat-header-cell class="header-text" *matHeaderCellDef> Column No </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{ element.sortOrder }} </mat-cell>
  </ng-container>

  <!-- Name  -->
  <ng-container matColumnDef="name">
    <mat-header-cell class="header-text" *matHeaderCellDef> Name </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{ element.displayName }} </mat-cell>
  </ng-container>

  <!-- Dynamic show all toggle column reportwriter, admin,lab manager, labtech colum-->
  <ng-container *ngFor="let columnName of this.appRoles" [matColumnDef]="columnName.roleName">
    <mat-header-cell class="header-text" *matHeaderCellDef>{{ columnName.description }}</mat-header-cell>
    <mat-cell *matCellDef="let element">
      <mat-slide-toggle
        [checked]="getRoleVisibility(element.roles, columnName.roleName)"
        (change)="toggleRoleVisibility($event.checked, element.roles, columnName.roleName)"></mat-slide-toggle>
    </mat-cell>
  </ng-container>

  <!-- Sort order column -->
  <ng-container matColumnDef="sort" sticky>
    <mat-header-cell class="header-text" *matHeaderCellDef> </mat-header-cell>
    <mat-cell *matCellDef="let element">
      <mat-icon class="dragCursor" (mousedown)="dragDisabled = false">drag_indicator</mat-icon>
    </mat-cell>
  </ng-container>
</mat-table>
