import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GeoMapContainerComponent } from './geo-map-container.component';

const routes: Routes = [
  {
    path: '',
    component: GeoMapContainerComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class GeoMapRoutingModule {}
