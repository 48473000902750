/* eslint-disable no-useless-escape */
import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appDecimalOnly]',
})
export class DecimalOnlyDirective {
  private regex = /^\d*\.?\d*$/;

  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event']) onInputChange(event: any) {
    const initialValue: string = this.el.nativeElement.value;
    this.el.nativeElement.value = initialValue.replace(/[^0-9\.]*/g, '');
    if (this.el.nativeElement.value !== initialValue) {
      event.stopPropagation();
    }
  }
}
