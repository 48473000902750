<div class="edit-report-wrapper mat-elevation-z1">
  <form>
    <h1>Recommendations</h1>
    <app-richtext-editor 
      (contentChanged)="onContentChanged($event)"
      [content]="editorContent.reportRecommendation"
    ></app-richtext-editor>
    <div class="button-wrapper">
      <button mat-raised-button color="primary" (click)="saveBtn()">SAVE</button>
    </div>
  </form>
</div>
