import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AccountInfo } from '@azure/msal-common';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { IUsers } from 'src/app/features/models/iuser.modal';
import { AzureGroups } from '../models/azure-model/i-group.modal';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  userAccount = new BehaviorSubject<AccountInfo | null>(null);
  userRole = new BehaviorSubject<string[] | null>(null);
  captchaValidated = false;

  constructor(private http: HttpClient) {}

  roleCheck(roles: string[]): boolean {
    let isAuthorised = false;
    const account$ = this.userAccount;
    const userRole$ = this.userRole;
    combineLatest([account$, userRole$]).subscribe((data) => {
      const account = data[0];
      const userroles = data[1];
      if (account) {
        if (Object.keys(account).length > 0) {
          userroles?.forEach((userrole) => {
            if (roles.includes(userrole)) {
              isAuthorised = true;
            }
          });
        }
      } else {
        isAuthorised = true;
      }
    });
    return isAuthorised;
  }

  getGroupsGraphApi() {
    return this.http.get<AzureGroups>('https://graph.microsoft.com/v1.0/groups');
  }

  getUsersByGroupIdGraphApi(groupId: string | undefined): Observable<IUsers> {
    return this.http.get<IUsers>(`https://graph.microsoft.com/v1.0/groups/${groupId}/members`);
  }

  userGraphApi() {
    return this.http.get('https://graph.microsoft.com/v1.0/me');
  }
}
