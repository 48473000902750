import { Injectable } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LoggingService {
  appInsights: ApplicationInsights;
  constructor() {
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: environment.appInsights.instrumentationKey,
        enableAutoRouteTracking: false,
      },
    });
    this.appInsights.loadAppInsights();
  }

  logPageView(name?: string, url?: string) {
    // option to call manually
    this.appInsights.trackPageView({
      name,
      uri: url,
    });
  }

  logEvent(name: string, properties?: { [key: string]: any }) {
    this.appInsights.trackEvent({
      name,
    }, properties);
  }

  trackEventWithCallback(eventName :any, properties : any) {
    return new Promise((resolve, reject) => {
      try {
        this.appInsights.trackEvent(eventName, properties);
        // If the function reaches this point, it means the trackEvent call was successful
        resolve(1);
      } catch (error) {
        // If an error occurs during trackEvent, reject the Promise with the error
        reject(error);
      }
    });
  }

  logMetric(name: string, average: number, properties?: { [key: string]: any }) {
    this.appInsights.trackMetric(
      {
        name,
        average,
      },
      properties,
    );
  }

  logException(exception: Error, severityLevel?: number) {
    this.appInsights.trackException({
      exception,
      severityLevel,
    });
  }

  logTrace(message: string, properties?: { [key: string]: any }) {
    this.appInsights.trackTrace(
      {
        message,
      },
      properties,
    );
  }

  generateGuid(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      // eslint-disable-next-line no-bitwise
      const r = (Math.random() * 16) | 0;
      // eslint-disable-next-line no-mixed-operators, no-bitwise
      const v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }
}
