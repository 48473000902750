<h2 mat-dialog-title class="mat-h1">
    <mat-icon class="success-ic success-popup-title-icon" href="javascript:void(0)">check_circle</mat-icon>
    {{ config?.title }} 
    <button mat-icon-button class="close-button" (click)="closeDialog()">
        <mat-icon>close</mat-icon>
    </button>
  </h2>
  <mat-dialog-content> 
    <p class="mat-subtitle-1">Thank you for your submission.</p>
    <p>Please don’t forget to include your record with your shipment.</p>
    <p>For any questions regarding your order, please contact <a href="mailto:lab@geothermusa.com">geotherm@usa.com</a>.</p>
  </mat-dialog-content>
  <mat-divider></mat-divider>
  <mat-dialog-actions align="end">
    <button mat-raised-button color="primary" (click)="closeDialog()">CLOSE</button>
  </mat-dialog-actions>
  
 