import { Component, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { PlatformBrowserService } from 'src/app/shared/services/platform-browser.service';
import { googleCaptcha } from '../../../environments/environment';
import { UserService } from '../../shared/services/user.service';

@Component({
  selector: 'app-customer-validation',
  templateUrl: './customer-validation.component.html',
  styleUrls: ['./customer-validation.component.scss'],
})
export class CustomerValidationComponent implements OnDestroy {
  public materialTypeForm: FormGroup;
  public isCaptchaResolved = false;
  public captchaSiteKey = googleCaptcha.siteKey;
  public isBrowserWarning = false;
  editViewSubmission = false;
  constructor(
    public dialog: MatDialog,
    private router: Router,
    private formBuilder: FormBuilder,
    public userService: UserService,
    public platformBrowserService:PlatformBrowserService,
  ) {
    this.isCaptchaResolved = this.userService.captchaValidated;
    this.userService.userAccount.subscribe((account) => {
      if (account) {
        this.router?.navigate(['dashboard']);
      }
    });
    this.materialTypeForm = this.formBuilder.group({
      materialType: [null, Validators.required],
    });
    if (this.platformBrowserService.getBrowserName() !== 'chrome' && this.platformBrowserService.getBrowserName() !== 'edge') {
      this.isBrowserWarning = true;
    }
  }

  ngOnDestroy(): void {
    if (this.materialTypeForm?.valid && this.isCaptchaResolved) {
      this.userService.captchaValidated = true;
    }
  }

  public resolved(captchaResponse: string): void {
    this.isCaptchaResolved = !!captchaResponse;
  }

  public onClickSubmit(): void {
    if (this.materialTypeForm?.valid && this.isCaptchaResolved) {
      this.editViewSubmission = true;
      sessionStorage.setItem('editViewSubmission', JSON.stringify(this.editViewSubmission));
      this.router?.navigate([`sampleform/${this.materialTypeForm.value.materialType}/create`]);
    }
  }
}
