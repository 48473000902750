<div class="edit-report-wrapper mat-elevation-z1" *ngIf="previewData && !exportShow">
  <form [formGroup]="form">
    <h1>Report</h1>
    <p>Date Modified: {{ previewData.updatedAt | utcToLocal }}</p>
    <div class="form-wrapper">
      <div class="col-2 b1">
        <mat-label class="mat-label">Submittal Date</mat-label>
        <mat-form-field appearance="outline">
          <input class="mat-date-input" readonly matInput formControlName="reportDate" [matDatepicker]="picker" />
          <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="col-2 online-type b2">
        <mat-label class="mat-label">Address To</mat-label>
        <mat-form-field appearance="outline" class="mat-form-field-width">
          <mat-select disableOptionCentering formControlName="isBilling" placeholder="Select">
            <mat-option [value]="false">Company</mat-option>
            <mat-option *ngIf="hasBillingInfo" [value]="true">Billing</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-11 b3">
        <mat-label class="mat-label">Main Body</mat-label>
        <div class="c-w">
          <mat-form-field appearance="outline" *ngIf="soilType === 'nativesoil'">
            <input matInput formControlName="mainBodyIntro" />
          </mat-form-field>
          <textarea matInput formControlName="mainBody" rows="5"></textarea>
        </div>
      </div>
      <div class="col-11 b3 b4">
        <mat-label class="mat-label">Comments</mat-label>
        <div class="c-w">
          <span>Customer Comments</span>
          <div class="comment-disabled">
            <div>
              <span *ngIf="soilType === 'precast' && form.value?.commentPart1">Concrete</span>
              <mat-form-field appearance="outline" *ngIf="form.value?.commentPart1">
                <input matInput formControlName="commentPart1" />
              </mat-form-field>
            </div>
            <div>
              <span *ngIf="soilType === 'precast' && form.value?.commentPart2">FTB</span>
              <mat-form-field appearance="outline" *ngIf="form.value?.commentPart2">
                <input matInput formControlName="commentPart2" />
              </mat-form-field>
            </div>
            <div *ngIf="soilType === 'precast'">
              <div *ngIf="form.value?.commentPart3">
                <span>Grout/Slurry</span>
                <mat-form-field appearance="outline">
                  <input matInput formControlName="commentPart3" />
                </mat-form-field>
              </div>
              <div *ngIf="form.value?.commentPart4">
                <span>Other</span>
                <mat-form-field appearance="outline">
                  <input matInput formControlName="commentPart4" />
                </mat-form-field>
              </div>
              <div>
                <mat-form-field appearance="outline">
                  <input matInput formControlName="commentPart5" />
                </mat-form-field>
              </div>
            </div>
          </div>
          <span>Geotherm Comments</span>
            <app-richtext-editor
            [minHeight]="'100px'"
            [content]="geothermComment"
            (contentChanged)="onContentChanged($event)"
          ></app-richtext-editor>
        </div>
      </div>
      <div class="col-2 b4">
        <mat-label class="mat-label">Signature</mat-label>
        <mat-form-field appearance="outline">
          <mat-select disableOptionCentering formControlName="signatureTypeId" placeholder="Select">
            <mat-option *ngFor="let sig of sigArray" [value]="sig.id" class="arrow">
              {{ sig.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="button-wrapper">
        <button mat-raised-button [disabled]="previewDisabled" (click)="preview()">PREVIEW</button>
        <button mat-raised-button color="primary" (click)="save()" [disabled]="!form.valid">SAVE</button>
      </div>
    </div>
  </form>
</div>

<div class="default-error" *ngIf="exportShow">
  <span >Please generate the report locally.</span>
</div>
<div class="edit-report-wrapper mat-elevation-z1">
  <h1>Figures</h1> 
  <p *ngIf="previewData">Date Modified: {{ previewData.updatedAt | utcToLocal }}</p>
  <div class="progress-wrapper">
    <div class="tx-w">
      <span class="l">Progress</span>
      <span class="r">{{ figureCompletedCount }} of {{ figureCount }}</span>
      <div class="clearfix"></div>
    </div>
    <div>
      <div
        class="gr"
        *ngIf="figureCompletedCount !== 0"
        [style.width.%]="(figureCompletedCount / figureCount) * 100"></div>
      <div class="sh"></div>
    </div>
    <div class="e-button-wrapper">
      <button mat-raised-button color="primary" [routerLink]="['/report/thermal', soilType, projectId]">EDIT</button>
    </div>
  </div>
</div>
