import { IDropdown } from '../models/i-dropdown-type.modal';

export const sampleData: IDropdown[] = [
  {
    id: 'CONCRETE',
    label: 'Concrete',
    order: 1,
    disabled: false,
  },
  {
    id: 'FTB',
    label: 'FTB',
    order: 2,
    disabled: false,
  },
  {
    id: 'GROUT/SLURRY',
    label: 'Grout/Slurry',
    order: 3,
    disabled: false,
  },
  {
    id: 'OTHER',
    label: 'Other',
    order: 4,
    disabled: false,
  },
];
