<div class="map-container" class="row gy-5">
  <div class="map-filters col-3">
    <nav aria-label="breadcrumb" class="mt-8 mb-8">
      <ol class="breadcrumb">
        <li class="breadcrumb-item active breadcrumb cursor" aria-current="page" (click)="redirectToDasboard()"
          (keypress)="redirectToDasboard()" tabindex="0">
          Dashboard
        </li>
      </ol>
    </nav>
    <h1 class="mat-h1">GeoXplore</h1>

    <mat-label class="mat-subtitle-2">Soil Type</mat-label>
    <div class="radio-group-wrapper">
      <mat-radio-group aria-label="Select an option" [(ngModel)]="selectedSoilType"
      (change)="onSoilTypeChange($event)" color="primary" required>
        <mat-radio-button value="native-soil">Native Soil</mat-radio-button>
        <mat-radio-button value="precast">Pre Cast</mat-radio-button>
      </mat-radio-group>
    </div>

    <mat-label class="mat-subtitle-2">Country <span class="required">*</span></mat-label>
    <mat-form-field appearance="outline">
      <mat-select [(value)]="selectedCountry" (selectionChange)="onCountrySelection()">
        <mat-option *ngFor="let country of countries" [value]="country">{{ country }}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-label class="mat-subtitle-2">State/Province <span class="required">*</span></mat-label>
    <mat-form-field appearance="outline">
      <mat-select [(value)]="selectedState" (selectionChange)="onStateSelection()">
        <mat-option *ngFor="let state of states" [value]="state">{{ state }}</mat-option>
      </mat-select>
    </mat-form-field>

   
    <mat-label class="mat-subtitle-2"> Radius (1-99 miles)</mat-label>
    <mat-slider min="1" max="99" discrete color="primary">
      <input matSliderThumb (input)="onSliderChange($event)" [value]="sliderValue" />
    </mat-slider>

  </div>
  <div id="map" class="match-parent col-9"></div>
</div>
