import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-customsnackmessage-component',
  template: '<p [innerHtml]="data"> </p>',
})
export class CustomSnackMessageComponent {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: string) {}
}
