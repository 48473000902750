import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';

@Component({
  selector: 'app-submission-success',
  templateUrl: './submission-success.component.html',
  styleUrls: ['./submission-success.component.scss'],
})
export class SubmissionSuccessComponent {
  config: any; // TODO We need to remove this any datatype

  constructor(private mdr: MatDialogRef<SubmissionSuccessComponent>, @Inject(MAT_DIALOG_DATA) data: MatDialog) {
    this.config = data;
  }

  closeDialog(): void {
    this.mdr?.close(false);
  }
}
