export const PdfStyles = {
  header: {
    fontSize: 11,
    fontFamily: 'Arial',
    bold: true,
    margin: [0, 0, 0, 10],
  },
  header1: {
    fontSize: 18,
    bold: true,
    fontFamily: 'Arial',
    margin: [0, 0, 0, 10],
  },
  header2: {
    fontSize: 16,
    bold: true,
    fontFamily: 'Arial',
    margin: [0, 0, 0, 10],
  },
  header3: {
    fontSize: 14,
    fontFamily: 'Arial',
  },
  header4: {
    fontFamily: 'Arial',
    fontSize: 12,
  },
  header5: {
    fontSize: 10,
  },
  header6: {
    fontSize: 8,
  },
  subheader: {
    fontSize: 11,
    fontFamily: 'Arial',
    bold: true,
    margin: [0, 10, 0, 5],
  },
  alignRight: {
    alignment: 'right',
    fontSize: 11,
    fontFamily: 'Arial',
  },
  blueLink: {
    color: 'blue',
    decoration: 'underline',
  },
  datebold: {
    bold: true,
    fontFamily: 'Arial',
    margin: [0, 0, 0, 0],
  },
  bold: {
    bold: true,
  },
  centerTitle: {
    alignment: 'center',
    fontSize: 11,
    fontFamily: 'Arial',
    bold: true,
  },
  centerTitle2: {
    alignment: 'center',
    fontSize: 11,
    fontFamily: 'Arial',
    bold: true,
    margin: [10, 0, 0, 20],
  },
  mainBody: {
    margin: [30, 10, 30, 10],
    fontSize: 11,
    lineHeight: 1.3,
    alignment: 'justify',

  },
  mainBodyTitle: {
    fontSize: 11,
    lineHeight: 1.3,
    bold: true,
    fontFamily: 'Arial',
  },
  tableHeaderStyle: {
    alignment: 'center',
    fontFamily: 'Arial',
    fontSize: 9,
    bold: true,
    margin: [0, 10, 0, 0],
    fillColor: '#f1f1f1',
  },
  tableDataStyle: {
    alignment: 'center',
    fontFamily: 'Arial',
    fontSize: 9,
  },
  tableHeaderMerged: {
    alignment: 'center',
    fontFamily: 'Arial',
    fontSize: 9,
    bold: true,
    fillColor: '#f1f1f1',
  },
  imageStyle: {
    alignment: 'center',
  },
  canvasStyle: {
    alignment: 'center',
    bold: true,
    fontSize: 9,
    margin: [10, 0, 0, 10],
  },
  canvasStylef: {
    alignment: 'center',
    bold: true,
    fontSize: 9,
    margin: [10, 20, 0, 10],
  },
  figureStyle: {
    alignment: 'right',
    bold: true,
    fontSize: 9,
    margin: [10, 10, 75, 0],
  },
  bottomRightDate: {
    alignment: 'left',
    fontFamily: 'Arial',
    bold: true,
    fontSize: 9,
    margin: [70, 10, 0, 0],

  },
  sampleHead: {
    bold: true,
    decoration: 'underline',
    fontFamily: 'Arial',
    margin: [30, 0, 30, 15],
    fontSize: 11,
  },
  footer: {
    alignment: 'center',
    fontFamily: 'Arial',
    fontSize: 9,
  },
  footer1: {
    alignment: 'center',
    fontFamily: 'Arial',
    fontSize: 9,
  },
  imageTitle: {
    alignment: 'center',
  },
  strong: {
    bold: true,
  },
  paragraph: {
    fontSize: 11,
    lineHeight: 1.3,
    alignment: 'justify',
    margin: [12, 80, 12, 80],

  },

  geothermtitle: {
    fontSize: 11,
    lineHeight: 1.3,
    bold: true,
    decoration: 'underline',
  },

  geocomment: {
    fontFamily: 'Arial',
    fontSize: 11,
    lineHeight: 1.3,
  },
};
