/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-useless-escape */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable default-case */
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { AdminDashboard } from 'src/app/shared/models/admin-dashboard.modal';
import { IMessage } from 'src/app/shared/models/message.modal';
import { messagesConfig } from 'src/app/shared/constants/message.constants';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MessageModalComponent } from 'src/app/shared/components/message-modal/message-modal.component';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AdminService } from '../admin.service';

@Component({
  selector: 'app-admin-settings',
  templateUrl: './admin-settings.component.html',
  styleUrls: ['./admin-settings.component.scss'],
})
export class AdminSettingsComponent implements OnInit {
  @Input() tabId: string;
  adminData: AdminDashboard[] = [];
  InitialLevel: AdminDashboard[] = [];
  companyAddress: AdminDashboard[] = [];
  contact: AdminDashboard[] = [];
  successMessage: IMessage;
  public updateSuccess: MatDialogRef<MessageModalComponent>;
  saveButtonDisabled = true;
  constructor(
    private adminService: AdminService,
    public router: Router,
    public dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private snackBar: MatSnackBar,
  ) {}

  ngOnInit(): void {
    this.fetchAdminTabs();
    this.handleInputChange();
  }

  handleInputChange() {
    this.checkInputsFilled();
    this.contactInputsFilled();
  }

  fetchAdminTabs(): void {
    this.adminService.getTabConfigItems(this.tabId)?.subscribe({
      next: (response: AdminDashboard[]) => {
        this.adminData = response;
        this.companyAddress = this.sortByKey(response, ['street1', 'street2', 'city', 'state', 'zipcode']);
        this.contact = this.sortByKey(response, ['contactnumber', 'email', 'weburl']);
        this.InitialLevel = response.filter((item) => !item.parentId).sort((a, b) => a.sortOrder - b.sortOrder);
      },
    });
  }

  sortByKey(data: AdminDashboard[], keys: string[]): AdminDashboard[] {
    return data.filter((item) => keys.includes(item.key)).sort((a, b) => a.sortOrder - b.sortOrder);
  }

  showCompanyAddres(subItemKey:string):boolean {
    return ['street1', 'street2', 'city'].includes(subItemKey);
  }

  showSubItem(subItemKey: string): boolean {
    return ['contactnumber', 'email', 'weburl'].includes(subItemKey);
  }

  onKeyPress(event: KeyboardEvent, key: string): void {
    let pattern: RegExp;
    let maxLength: number;
    let errorMessageId = '';
    let minLength = 0;
    switch (key) {
      case 'contactnumber':
        pattern = /^[\+\d\s\-()]*$/;// Only Allow number, hyphens and plus sign
        maxLength = 14;
        minLength = 10;
        errorMessageId = 'contactnumber-error';
        break;
      case 'email':
        pattern = /^[a-zA-Z0-9._%+\-@,]*$/; // Allow alphanumeric, dots, underscores, percent, plus, hyphen, @, and commas
        maxLength = 250;
        minLength = 10;
        errorMessageId = 'email-error';
        break;
      case 'weburl':
        pattern = /^[a-zA-Z0-9.-]*$/; // Allow alphanumeric, dots, and hyphens
        maxLength = 250;
        minLength = 10;
        errorMessageId = 'weburl-error';
        break;
      default:
        return;
    }

    const inputElement = event.currentTarget as HTMLInputElement;
    if (!inputElement) {
      return;
    }
    const errorMessageElement = document.getElementById(errorMessageId);
    // Length check
    if (inputElement.value.length >= maxLength && event.keyCode !== 8) {
      event.preventDefault();
      if (errorMessageElement) {
        errorMessageElement.innerText = 'Maximum length exceeded';
      }
      return;
    }
    // Validation
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();
      if (errorMessageElement) {
        errorMessageElement.innerText = 'Invalid character';
      }
    } else if (errorMessageElement) {
      errorMessageElement.innerText = '';
    }

    if (key === 'contactnumber') {
      this.contactInputsFilled();
    }
  }

  adminSaveAPi(): void {
    this.adminService.updateSettings(this.adminData, this.tabId)?.subscribe({
      next: (response: AdminDashboard[]) => {
        this.adminData = response;
        this.snackBar.open(messagesConfig.changeSuccessMeaage.message, 'DONE', {
          duration: 3000,
          verticalPosition: 'top',
          panelClass: ['default-snackbar'],
        });
        this.fetchAdminTabs();
      },
      error: (err) => console.error('Error API:', err),
    });
  }

  validatenumberOnly(event: KeyboardEvent): boolean {
    const { key } = event;
    return key >= '0' && key <= '9';
  }

  onBlur(event: FocusEvent, key: string): void {
    const inputElement = event.target as HTMLInputElement;
    const errorMessageElement = document.getElementById(`${key}-error`);
    let isValid = true;

    switch (key) {
      case 'email':
        isValid = this.validateEmails(inputElement.value);
        if (!isValid && errorMessageElement) {
          errorMessageElement.innerText = 'Invalid email format';
        }
        break;
      case 'weburl':
        isValid = /^(https?:\/\/)?([a-zA-Z0-9.-]+\.[a-zA-Z]{2,})(\/[a-zA-Z0-9.-]*)*$/.test(inputElement?.value);
        if (!isValid && errorMessageElement) {
          errorMessageElement.innerText = 'Invalid URL format';
        }
        break;
      default:
        return;
    }

    if (isValid && errorMessageElement) {
      errorMessageElement.innerText = '';
    }
  }

  validateEmails(emails: string): boolean {
    const emailArray = emails.split(',').map((email) => email.trim());
    const emailPattern = /^[a-zA-Z0-9._%+\-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailArray.every((email) => emailPattern.test(email));
  }

  checkInputsFilled() {
    const allInputsValid = this.adminData.every((item) => {
      const trimmedValue = item.value.trim();
      return trimmedValue !== '' && trimmedValue !== '0' && trimmedValue !== '00' && trimmedValue !== '000';
    });
    this.saveButtonDisabled = !allInputsValid;
    this.cdr.detectChanges();
  }

  contactInputsFilled() {
    const contactField = this.contact.find((item) => item.key === 'contactnumber');
    const contactErrorMessageElement = document.getElementById('contactnumber-error');
    if (contactField && contactField.value.length < 10) {
      if (contactErrorMessageElement) {
        contactErrorMessageElement.innerText = 'Minimum length is 10 characters';
      }
    } else if (contactErrorMessageElement) {
      contactErrorMessageElement.innerText = '';
    }
    if (contactField && contactField.value.length >= 10) {
      this.saveButtonDisabled = false;
    } else {
      this.saveButtonDisabled = true;
    }
    this.cdr.detectChanges();
  }
}
