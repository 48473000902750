import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[formControlName][appPhoneMask]',
})
export class PhoneMaskDirective {
  constructor(public ngControl: NgControl, private el: ElementRef) { }

  @HostListener('ngModelChange', ['$event'])
  onModelChange(event: any) {
    this.onInputChange(event, false);
  }

  @HostListener('keydown.backspace', ['$event'])
  keydownBackspace(event: any) {
    this.onInputChange(event.target.value, true);
  }

  @HostListener('focus', ['$event'])
  onFocusPhoneNumber(event: any) {
    this.onInputChange(event.target.value, false);
  }

  onInputChange(event: any, backspace: any) {
    let newVal = event.replace(/\D/g, '');
    if (backspace === true) {
      this.handleBackspace();
    }

    if (newVal.length === 0) {
      newVal = '';
    } else if (newVal.length <= 3) {
      newVal = newVal.replace(/^(\d{0,3})/, '($1');
    } else if (newVal.length <= 6) {
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, '($1)-$2');
    } else if (newVal.length <= 10) {
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1)-$2-$3');
    } else {
      newVal = newVal.substring(0, 10);
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1)-$2-$3');
    }
    this.ngControl?.valueAccessor?.writeValue(newVal);
  }

  handleBackspace() {
    const input = this.el.nativeElement.value;
    const { selectionStart } = this.el.nativeElement;
    // If the cursor is at the start, do nothing
    if (selectionStart === 0) return;
    let newValue;
    // Identify the character at the current cursor position
    const charBeforeCursor = input[selectionStart - 1];
    if (charBeforeCursor === ')') {
      // If the character is a closing parenthesis, remove the parenthesis and the space before it
      newValue = input.slice(0, selectionStart - 4) + input.slice(selectionStart);
    } else if (charBeforeCursor === '-') {
      // If the character is a hyphen, just remove it
      newValue = input.slice(0, selectionStart - 1) + input.slice(selectionStart);
    } else if (charBeforeCursor === ' ') {
      // If the character is a space, remove it along with the previous character if needed
      newValue = input.slice(0, selectionStart - 2) + input.slice(selectionStart);
    } else {
      // For any other character, just remove the character
      newValue = input.slice(0, selectionStart - 1) + input.slice(selectionStart);
    }
    // Update the input field and model
    this.el.nativeElement.value = newValue;
    this.onInputChange(newValue, false);
  }
}
