import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appCommaNumber]',
})
export class CommaNumberDirective {
  constructor(private ngControl: NgControl) { }

  @HostListener('input', ['$event'])
  onInput(event: Event) {
    const input = event.target as HTMLInputElement;
    let value = input.value.replace(/[^0-9, ]/g, '');
    value = value.replace(/,{2,}/g, ',');
    value = value.replace(/(?<!,) /g, '');
    if (value.slice(0, 1) === '0' || value.slice(0, 1) === ',') {
      if (value.length === 1) {
        value = value.slice(0, -1);
      } else {
        value = value.substring(1);
      }
    }
    value = value.replace(/(\d{3})(?=\d)/g, '$1, ');
    const hasZeros = /,0/.test(value);
    if (hasZeros) {
      value = value.slice(0, -1);
    }
    value = value.replace(', ,', ',');
    value = value.replace(',,', ',');
    value = value.replace(',0', '');
    value = value.replace(', 0', ',');
    this.ngControl.control?.setValue(value);
  }
}
