/* eslint-disable @typescript-eslint/no-shadow */
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PlatformBrowserService } from '../../services/platform-browser.service';
import { IPrecastRecord } from '../../models/i-precast-record.modal';
import { FormService } from '../../services/form.service';
import { messagesConfig } from '../../constants/message.constants';
import { UploadedFile } from '../../models/file-upload.model';
import { MessageModalComponent } from '../message-modal/message-modal.component';
import { IMessage } from '../../models/message.modal';

@Component({
  selector: 'app-landing-dialog',
  templateUrl: './landing-dialog.component.html',
  styleUrls: ['./landing-dialog.component.scss'],
})
export class LandingDialogComponent implements OnInit {
  public materialTypeForm!: FormGroup;
  public isBrowserWarning = false;
  selectedFiles: File[] = [];
  uploadedFiles: UploadedFile[] = [];
  filePickDisabled = false;
  showFileUpload = false;
  apiInProgress = false;
  autoFillClicked = false;
  maxFileSize: number = 10 * 1024 * 1024;
  errorMessage = '';
  formData: IPrecastRecord;
  errorMsg: MatDialogRef<MessageModalComponent>;
  constructor(
    private mdr: MatDialogRef<LandingDialogComponent>,
    private router: Router,
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private formService: FormService,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    public platformBrowserService: PlatformBrowserService,
  ) {
    if (
      this.platformBrowserService.getBrowserName() !== 'chrome'
      && this.platformBrowserService.getBrowserName() !== 'edge'
    ) {
      this.isBrowserWarning = true;
    }
  }

  ngOnInit(): void {
    this.materialTypeForm = this.formBuilder?.group({
      materialType: ['', Validators?.required],
    });
  }

  closeDialog(): void {
    this.mdr?.close(false);
  }

  handleButtonClick(): void {
    this.toggleFileUpload();
    this.autoFillClicked = true;
    if (!this.showFileUpload) {
      if (this.apiInProgress) {
        this.onClickApiSubmit();
      }
    }
  }

  toggleFileUpload(): void {
    this.showFileUpload = !this.showFileUpload;
  }

  onClickApiSubmit() {
    if (this.materialTypeForm?.valid) {
      this.mdr?.close(false);
      const encodedMaterialType = encodeURIComponent(this.materialTypeForm.value.materialType);
      const url = `sampleform/${encodedMaterialType}/edit/null?s=1&f=d`;
      this.router?.navigateByUrl(url);
    }
  }

  public onClickSubmit(): void {
    if (this.materialTypeForm?.valid) {
      this.mdr?.close(false);
      this.router?.navigate([`sampleform/${this.materialTypeForm.value.materialType}/create`]);
    }
  }

  onFileSelected(event: any) {
    const { files } = event.target;
    for (let i = 0; i < files.length; i++) {
      this.selectedFiles.push(files[i]);
    }
    this.filePickDisabled = true;
    sessionStorage.removeItem('uploadedFile');
    this.uploadFiles();
  }

  uploadFiles() {
    this.selectedFiles.forEach((file) => {
      if (!this.uploadedFiles.some((uf) => uf.file === file)) {
        if (file.size <= this.maxFileSize) {
          this.errorMessage = '';
          this.uploadFile(file);
        } else {
          this.selectedFiles = [];
          this.errorMessage = 'The file is too large and cannot be uploaded.Please reduce the file size and try again';
          setTimeout(() => {
            this.errorMessage = '';
          }, 50000);
          this.filePickDisabled = false;
        }
      }
    });
  }

  uploadFile(file: File) {
    const API_ENDPOINTS = {
      nativeSoil: `${environment.baseUrl}ml/scannsocrfiledata`,
      precast: `${environment.baseUrl}ml/scanpcocrfiledata`,
    };
    let errorMessage: IMessage;
    let progress = 0;
    const interval = setInterval(() => {
      progress += Math.random() * 10;
      const uploadedFile = this.uploadedFiles.find((uploadedFile) => uploadedFile.file === file);
      if (uploadedFile) {
        if (progress >= 100) {
          clearInterval(interval);
          uploadedFile.progress = 100;
          uploadedFile.completed = true;
          // API endpoint based on materialTypeForm.value.materialType
          const { materialType } = this.materialTypeForm.value;
          let apiEndpoint = '';
          if (materialType === 'native-soil') {
            apiEndpoint = API_ENDPOINTS.nativeSoil;
          } else if (materialType === 'precast') {
            apiEndpoint = API_ENDPOINTS.precast;
          }
          // Call respective API endpoint
          const formData = new FormData();
          formData.append('file', file);
          this.http.post<any>(apiEndpoint, formData).subscribe(
            (response) => {
              this.snackBar.open(messagesConfig.apiUploadSuccessMessage.message, 'DONE', {
                duration: 3000,
                verticalPosition: 'top',
                panelClass: ['default-snackbar'],
              });
              const reader = new FileReader();
              reader.onload = (event) => {
                const fileContent = event?.target?.result;
                if (fileContent) {
                  const uploadedFileInfo = {
                    filename: file.name,
                    content: fileContent.toString(),
                  };
                  sessionStorage.setItem('uploadedFile', JSON.stringify(uploadedFileInfo));
                }
              };
              reader.readAsDataURL(file);
              this.formService.updateData(response);
              this.apiInProgress = true;
            },
            (error) => {
              if (error) {
                errorMessage = messagesConfig.uploadApiFailed;
                this.showErrorPopUpMessage(errorMessage);
              }
              console.error('Error uploading file:', error);
            },
          );
        } else {
          const uploadedFile = this.uploadedFiles.find((uf) => uf.file === file);
          if (uploadedFile) {
            uploadedFile.progress = progress;
          }
        }
      }
    }, 500);

    this.uploadedFiles.push({
      file,
      progress,
      completed: false,
    });
  }

  showErrorPopUpMessage(successMessage: IMessage): void {
    this.errorMsg = this.dialog.open(MessageModalComponent, {
      panelClass: 'app-dialog',
      data: successMessage,
      disableClose: true,
      enterAnimationDuration: '0ms',
      exitAnimationDuration: '0ms',
    });
    this.errorMsg.afterClosed().subscribe(() => {
      sessionStorage.removeItem('uploadedFile');
      this.closeDialog();
    });
  }

  cancelUpload(file: UploadedFile) {
    const index = this.uploadedFiles.indexOf(file);
    if (index !== -1) {
      this.uploadedFiles.splice(index, 1);
      this.selectedFiles = [];
      this.apiInProgress = false;
      sessionStorage.removeItem('uploadedFile');
    }
    const selectedFileIndex = this.selectedFiles.indexOf(file.file);
    if (selectedFileIndex !== -1) {
      this.selectedFiles.splice(selectedFileIndex, 1);
      this.apiInProgress = false;
      sessionStorage.removeItem('uploadedFile');
    }
    if (this.uploadedFiles.length === 0) {
      this.filePickDisabled = false;
      this.apiInProgress = false;
    }
  }
}
