export const PdfNativeTableHeader = [
  {
    text: 'Sample ID', rowSpan: 2, style: 'tableHeaderStyle',
  },
  {
    text: [
      {
        text: 'Depth\n',
      },
      {
        text: '(ft)', alignment: 'center',
      },
    ],
    rowSpan: 2,
    style: 'tableHeaderStyle',
  },
  {
    text: [
      {
        text: 'Effort\n',
      },
      {
        text: '(%)', alignment: 'center',
      },
    ],
    rowSpan: 2,
    style: 'tableHeaderStyle',
  },
  {
    text: 'Description', rowSpan: 2, style: 'tableHeaderStyle',
  },

  {
    text: [
      {
        text: 'Thermal Resistivity\n',
      },
      {
        text: '(°C-cm/W)', alignment: 'center',
      },
    ],
    colSpan: 2,
    style: 'tableHeaderMerged',
  },
  {
  },

  {
    text: [
      {
        text: 'Moisture Content\n',
      },
      {
        text: '(%)', alignment: 'center',
      },
    ],
    rowSpan: 2,
    style: 'tableHeaderStyle',
  },
  {
    text: [
      {
        text: 'Dry Density\n',
      },
      {
        text: '(lb/ft3)', alignment: 'center',
      },
    ],
    rowSpan: 2,
    style: 'tableHeaderStyle',
  },

];

export const PdfNativeMergedHeader = [
  '',
  '',
  '',
  '',
  {
    text: 'Dry', style: 'tableHeaderMerged',
  },
  {
    text: 'Wet', style: 'tableHeaderMerged',
  }, '', '',
];

export const PdfPrecastTableHeader = [
  {
    text: 'Sample ID', rowSpan: 2, style: 'tableHeaderStyle',
  },
  {
    text: 'Date Cast', rowSpan: 2, style: 'tableHeaderStyle',
  },
  {
    text: 'Material Type', rowSpan: 2, style: 'tableHeaderStyle',
  },
  {
    text: [
      {
        text: 'Moisture Content\n',
      },
      {
        text: '(%)', alignment: 'center',
      },
    ],
    rowSpan: 2,
    style: 'tableHeaderStyle',
  },
  {
    text: [
      {
        text: 'Dry Density\n',
      },
      {
        text: '(lb/ft3)', alignment: 'center',
      },
    ],
    rowSpan: 2,
    style: 'tableHeaderStyle',
  },
  {
    text: [
      {
        text: 'Thermal Resistivity\n',
      },
      {
        text: '(°C-cm/W)', alignment: 'center',
      },
    ],
    colSpan: 2,
    style: 'tableHeaderMerged',
  },
  {
  },
];

export const PdfPrecastMergedHeader = [
  '',
  '',
  '',
  '',
  '',
  {
    text: 'Dry', style: 'tableHeaderMerged',
  },
  {
    text: 'Wet', style: 'tableHeaderMerged',
  },
];
