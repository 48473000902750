import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-ui-message-modal',
  templateUrl: './ui-message-modal.component.html',
  styleUrls: ['./ui-message-modal.component.scss'],
})
export class UiMessageModalComponent {
  config: any; // TODO We need to remove this any datatype
  env = environment;
  constructor(private mdr: MatDialogRef<UiMessageModalComponent>, @Inject(MAT_DIALOG_DATA) data: MatDialog) {
    this.config = data;
  }

  closeDialog(): void {
    this.mdr?.close(false);
  }
}
