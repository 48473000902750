import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'format',
})
export class DynamicPipe implements PipeTransform {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(private datePipe: DatePipe) {}

  transform(value: any, pipeName: string) {
    switch (pipeName) {
      case 'truncate':
        if (value && value.length > 61) {
          value = `${value.substring(0, 15)}<br>${value.substring(15, 30)}<br>${value.substring(
            30,
            45,
          )}<br>${value.substring(45, 60)}...`;
        } else if (value && value.length > 45 && value.length < 61) {
          value = `${value.substring(0, 15)}<br>${value.substring(15, 30)}<br>${value.substring(
            30,
            45,
          )}<br>${value.substring(45, 60)}`;
        } else if (value && value.length > 45) {
          value = `${value.substring(0, 15)}<br>${value.substring(15, 30)}<br>${value.substring(
            30,
            45,
          )}<br>${value.substring(29, value.length)}`;
        } else if (value && value.length > 30) {
          value = `${value.substring(0, 15)}<br>${value.substring(15, 30)}<br>${value.substring(30, value.length)}`;
        } else if (value && value.length > 15) {
          value = `${value.substring(0, 15)}<br>${value.substring(15, value.length)}`;
        }
        return value;
      case 'date':
        return value ? this.datePipe.transform(value, 'MMM dd yyyy') : value;
      case 'effort':
        return Number(value) === 0 ? 'TUBE' : value;
      case 'compaction':
        if (value.length > 15) {
          return this.getReadableCammaSeperatedValue(value, 20);
        }
        return value;
        break;
      default:
        return value;
    }
  }

  getReadableCammaSeperatedValue(value: string, maxLength: number): string {
    let output = '';
    let next = maxLength;
    let start = 0;
    let index = 0;
    if (value.length > 0 && value[value.length - 1] === ',') {
      return value.slice(0, -1);
    }
    while (next <= value.length + 1) {
      index = this.getLastCommaIndex(value, next);
      if (index > 0) {
        output += `${value.substring(start, index)}`;
        next += value.length - index - 1;
        if (next <= value.length + 1) {
          output += ',<br/>';
        }
        start = index + 1;
      } else {
        next += value.length;
      }
    }
    if (next > value.length && index < value.length) {
      output += `${value.substring(index, value.length)}`;
    }
    if (output.length > 0 && output[output.length - 1] === ',') {
      return output.slice(0, -1);
    }
    return output;
  }

  getLastCommaIndex(input: string, maxLength: number): number {
    let index = -1;
    for (let i = maxLength - 1; i >= 0; i--) {
      if (input[i] === ',') {
        index = i;
        break;
      }
    }
    return index;
  }
}
