import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appDecimalInput]',
})
export class DecimalInputDirective {
  @Input() length = 5;
  constructor(private el: ElementRef, private control: NgControl) {}

  @HostListener('input', ['$event']) onInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    let newValue;
    let value = input.value.replace(/[^0-9.]/g, '');
    if (value.slice(-2) === '..') {
      value = value.slice(0, -1);
    }
    if (value.slice(-2) === '.0') {
      value = value.slice(0, -1);
    }
    if (value.length <= this.length) {
      const decimalIndex = value.indexOf('.');
      if (decimalIndex === 4) {
        value = value.slice(0, -1);
        newValue = value;
      } else {
        const integerPart = decimalIndex !== -1 ? value.substring(0, decimalIndex) : value;
        const limitedIntegerPart = integerPart.slice(0, 5);
        const decimalPart = decimalIndex !== -1 ? value.substring(decimalIndex + 1) : '';
        const limitedDecimalPart = decimalPart.slice(0, 1);
        newValue = decimalIndex !== -1 ? `${limitedIntegerPart}.${limitedDecimalPart}` : limitedIntegerPart;
      }
    } else {
      newValue = value.slice(0, this.length);
    }
    this.control.control?.setValue(newValue);
  }
}
