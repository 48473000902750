import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from 'src/app/shared/services/http.service';
import { MoistureWorkSheet,
  NativeSoilType,
  ReceivedMCWorkSheet,
  WorkSheet,
  ProctorSheet } from '../../models/native-soil-worksheet.modal';

@Injectable({
  providedIn: 'root',
})
export class NativeSoilService {
  constructor(private httpService: HttpService) {}
  public getNativeSoilWorkSheet(projectId: string): Observable<NativeSoilType[]> {
    return this.httpService.get(`nativesoilws/getworksheets/${projectId}`);
  }

  public deleteNativeSoilWorksheet(worksheetId: string, workSheetType: number): Observable<object> {
    return this.httpService.delete(`nativesoilws/deleteworksheetrow/${worksheetId}/${workSheetType}`);
  }

  public updateReceivedWorkSheet(payload: ReceivedMCWorkSheet): Observable<ReceivedMCWorkSheet> {
    return this.httpService.post('nativesoilws/updateasreceivedworksheet', payload);
  }

  public updateMoistureWorkSheet(payload: MoistureWorkSheet, modalType: string): Observable<MoistureWorkSheet> {
    const url = modalType === 'mswt' ? 'nativesoilws/updatemoistureworksheet' : 'nativesoilws/updatemoistureworksheetstatus';
    return this.httpService.post(url, payload);
  }

  public updateWorkSheet(payload: WorkSheet): Observable<WorkSheet> {
    return this.httpService.post('nativesoilws/updatenativesoilworksheet', payload);
  }

  public updateProctorWorkSheet(payload: ProctorSheet): Observable<ProctorSheet> {
    return this.httpService.post('nativesoilws/updateproctorworksheet', payload);
  }
}
