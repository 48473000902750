import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MessageModalComponent } from '../components/message-modal/message-modal.component';
import { UiMessageModalComponent } from '../components/ui-message-modal/ui-message-modal.component';
import { IMessage } from '../models/message.modal';

@Injectable()
export class DialogService {
  dialogRef: MatDialogRef<MessageModalComponent>;
  dialogRefUi: MatDialogRef<UiMessageModalComponent>;

  constructor(public dialog: MatDialog) { }

  open(messageObject: IMessage):void {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
    this.dialogRef = this.dialog.open(MessageModalComponent, {
      panelClass: 'app-dialog',
      data: messageObject,
      disableClose: true,
      enterAnimationDuration: '0ms',
      exitAnimationDuration: '0ms',
    });
  }

  uiErrorOpen(messageObject: IMessage):void {
    if (this.dialogRefUi) {
      this.dialogRefUi.close();
    }
    this.dialogRefUi = this.dialog.open(UiMessageModalComponent, {
      panelClass: 'app-dialog',
      data: messageObject,
      disableClose: true,
      enterAnimationDuration: '0ms',
      exitAnimationDuration: '0ms',
    });
  }

  uiErrorClose(): void {
    if (this.dialogRefUi) {
      this.dialogRefUi.close();
    }
  }

  close():void {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }
}
