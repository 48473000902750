import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatCardModule } from '@angular/material/card';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatMenuModule } from '@angular/material/menu';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { SharedModule } from '../../shared/shared.module';
import { ReviewRoutingModule } from './review-routing.module';
import { ReviewComponent } from './components/review/review.component';
import { CompanyContactInformationComponent } from './components/company-contact-information/company-contact-information.component';
import { SampleInformationComponent } from './components/sample-information/sample-information.component';
import { InstructionsSampleTypeComponent } from './components/instructions-sample-type/instructions-sample-type.component';
import { PrintInstructionsComponent } from './components/print-instructions/print-instructions.component';
import { SubmissionSuccessComponent } from './components/submission-success/submission-success.component';

@NgModule({
  declarations: [
    ReviewComponent,
    CompanyContactInformationComponent,
    SampleInformationComponent,
    InstructionsSampleTypeComponent,
    PrintInstructionsComponent,
    SubmissionSuccessComponent,
  ],
  imports: [
    FormsModule,
    CommonModule,
    ReviewRoutingModule,
    MatTableModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    MatDividerModule,
    MatCheckboxModule,
    SharedModule,
    MatCardModule,
    MatChipsModule,
    MatSnackBarModule,
    MatExpansionModule,
    MatMenuModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
  ],
})
export class ReviewModule {}
