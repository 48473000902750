import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { errorMessages } from '../constants/error-message.constants';
import { LoggingService } from '../services/logging.service';
import { CustomException } from '../models/i-custom-exception.modal';
import { IMessage } from '../models/message.modal';

@Injectable({
  providedIn: 'root',
})
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private dialogService: DialogService, public loggingService: LoggingService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      retry(1),
      catchError((error: HttpErrorResponse) => {
        this.errorHandler(error, request.url);
        return throwError(() => error);
      }),
    );
  }

  errorHandler(response: any, url: string) {
    let hasError = false;
    let errorMessage: IMessage = {
      title: 'Error',
      type: 'WARNING',
      errorID: '',
      message: 'Please try refreshing the page, if the issue persists contact support team.',
    };
    if (!navigator.onLine) {
      hasError = true;
      errorMessage = errorMessages.internetConnection;
      if (errorMessage) {
        errorMessage.message = errorMessage.message?.replace('#', `<a href='mailto:${environment.contactEmail}'>support</a>`);
        this.dialogService.open(errorMessage);
      }
    } else {
      const newGuid: string = this.loggingService.generateGuid();
      switch (response.status) {
        case 400:
          // const result = Object.keys(JSON.parse(response.error.StackTrace)).filter((key) => key.includes('$'));
          // eslint-disable-next-line no-case-declarations
          const result = Object.keys(JSON.parse(response.error.StackTrace));
          errorMessage = errorMessages.badRequest;
          hasError = true;
          if (url.includes('update') || url.includes('create') || url.includes('add')) {
            errorMessage = errorMessages.badRequest;
          }
          if (result) {
            errorMessage.errorStackMessage = `Server side validation issue: ${result.toString().replace('$', '').replace('.', '')}`;
          }

          break;
        case 401:
          errorMessage = errorMessages.autehntication;
          hasError = true;
          break;
        case 403:
          errorMessage = errorMessages.accessDenied;
          hasError = true;
          break;
        case 404:
          errorMessage = errorMessages.pageNotFound;
          hasError = true;
          break;
        case 500:
          errorMessage = errorMessages.internalServer;
          errorMessage.errorStackMessage = response.error.Message;
          hasError = true;
          break;
        case 503:
          errorMessage = errorMessages.serviceUnavailable;
          hasError = true;
          break;
        default:
      }
      let backendErrorID = '';
      let stack = {
      };

      errorMessage.errorID = newGuid;
      if (response?.error?.Id) {
        backendErrorID = response?.error?.Id;
      }
      stack = response;
      const logError: CustomException = {
        frondendID: newGuid,
        message: response?.error?.Message,
        backendID: backendErrorID,
        errorCode: response.status,
        apiUrl: url,
        stack,
      };

      this.loggingService.logEvent('Http Error', logError);
      if (errorMessage && hasError) {
        errorMessage.message = errorMessage.message?.replace('#', `<a href='mailto:${environment.contactEmail}'>support</a>`);
        this.dialogService.open(errorMessage);
      }
    }
  }
}
