<section class="precast-tabel-container">
  <mat-table #table [dataSource]="dataSource" aria-describedby="listOfWorksheets" *ngIf="dataSource">
    <mat-header-row *matHeaderRowDef="displayedColumns.concat('action'); sticky: true"></mat-header-row>
    <mat-row
      *matRowDef="let row; columns: displayedColumns.concat('action');"
      [class.gray-zebra]="row.sequence % 2 !== 0"></mat-row>

    <ng-container *ngFor="let columnName of displayedColumns" [matColumnDef]="columnName">
      <mat-header-cell
        class="header-text"
        *matHeaderCellDef
        [matTooltip]="getTooltipText(columnName)"
        [innerHTML]="getDisplayName(columnName)"></mat-header-cell>

      <mat-cell *matCellDef="let row" class="row-cell">
        <!-- Display content for 'Sample ID' column -->
        <ng-container *ngIf="columnName === 'sampleId'">
          <div class="sampleid">
            <span class="truncate-label-2line col-width-100 sp" 
            [attr.title]="row.sampleId"
            >
              {{ row.sampleId | truncate : 57 }}
            </span>
            <span
              *ngIf="row?.truckNumber"
              class="truncate-label-2line col-width-100 sp"
              #tooltip="matTooltip"
              [matTooltip]="row.truckNumber">
              Truck# {{ row?.truckNumber }}
            </span>
            <span
              *ngIf="row?.ticketNumber"
              class="truncate-label-2line col-width-100 sp"
              #tooltip="matTooltip"
              [matTooltip]="row.ticketNumber">
              Ticket# {{ row?.ticketNumber }}
            </span>
          </div>
        </ng-container>

        <!-- Display content for 'Test' column -->
        <ng-container *ngIf="columnName === 'groupOrder'">
          {{ row.groupOrder ? row.groupOrder + 1 : 1 }}
        </ng-container>

        <!-- Display  content for 'status' column -->
        <ng-container *ngIf="columnName === 'status'">
          <img alt="check" *ngIf="row.status" src="../../../../assets/icons/Check.svg" />
          <img alt="icon" *ngIf="!row.status" src="../../../../assets/icons/table icon.svg" />
        </ng-container>
        <!-- Display default content for other columns -->
        <span #tooltip="matTooltip" [matTooltip]="getRowDataByKey(row, columnName)" *ngIf="columnName !== 'status' && columnName !== 'sampleId' && columnName !== 'groupOrder'">{{
          getRowDataByKey(row, columnName)
        }}</span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="action" stickyEnd>
      <mat-header-cell class="header-text" *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let row">
        <button
          mat-icon-button
          [matMenuTriggerFor]="menu"
          *ngIf="this.userService.roleCheck(['Manager', 'Labtech', 'Admin']) && !row.groupOrder">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu class="dashboard-menu" #menu="matMenu" yPosition="below" xPosition="after">
          <button mat-menu-item (click)="openAssignToDialog(row)">
            <span class="menu-text"> <img src="/assets/icons/User-multiple.svg" alt="File" /> &nbsp; Assign To </span>
          </button>
          <button
            [disabled]="!row.Tech || (row.status && !this.userService.roleCheck(['Manager', 'Admin']))"
            mat-menu-item
            (click)="editWorksheet(row)"
            *ngIf="this.userService.roleCheck(['Manager', 'Labtech', 'Admin'])">
            <span class="menu-text">
              <img src="/assets/icons/New-window-1.svg" alt="Worksheet" /> &nbsp; Open Tech Worksheet
            </span>
          </button>
          <button
            mat-menu-item
            (click)="deleteWorksheet(row.id)"
            *ngIf="this.userService.roleCheck(['Manager', 'Admin'])"
            [disabled]="deleteBtn">
            <span class="menu-text"> <img src="/assets/icons/Delete.svg" alt="Delete-icon" /> &nbsp; Delete Row </span>
          </button>
        </mat-menu>
      </mat-cell>
    </ng-container>
  </mat-table>
</section>
