<div class="attachments-wrapper">
  <mat-drawer-container class="example-container" hasBackdrop="false" autosize="false">
    <mat-drawer #drawer mode="over" position="end">
      <div class="attachments">
        <div class="drawer-header">
          <h2 class="mat-h2 attachment-heading">Attachments</h2>
          <button mat-icon-button class="close-button" (click)="drawer.toggle()">
            <mat-icon>close</mat-icon>
          </button>
          <div class="clearfix"></div>
        </div>
        <div class="addAttachment">
          <div class="instructions" *ngIf="this.userService.roleCheck(['Reportwriter', 'Manager', 'Admin'])">
            <b>Instructions:</b>
            <ol *ngIf="projectId">
              <li>
                Maximum permissible size of any single file that can be uploaded is 10 MB. You can upload multiple
                files.
              </li>
              <li>The permissible file types that can be uploaded are .jpg, .pdf, .png.</li>
              <li>You cannot upload same file name, if it already exists.</li>
            </ol>
            <ol *ngIf="!projectId">
              <li>
                You cannot upload any file that exceeds 10 MB or the total file size for all attachments cannot exceed
                10 MB.
              </li>
              <li>The permissible file types that can be uploaded are .jpg, .pdf, .png.</li>
              <li>You cannot upload same file name, if it already exists.</li>
              <li *ngIf="!account">
                If the size of attachments file(s) is more than 10 MB, you can always send your additional attachments
                to
                <a href="mailto:samples@geothermusa.com">samples@geothermusa.com</a>.
              </li>
            </ol>
          </div>
          <div class="float-right">
            <a
              href="javascript:void(0)"
              class="testing"
              (click)="fileInput.click()"
              *ngIf="!isSuccess && this.userService.roleCheck(['Reportwriter', 'Manager', 'Admin'])">
              <span><img src="/assets/icons/add_red.svg" alt="Add Attachments" />&nbsp;Add Attachment(s)</span>
              <input
                #fileInput
                type="file"
                multiple
                [accept]="allowedFormat"
                style="display: none"
                (change)="getFileDetails($event)" />
            </a>
          </div>
          <div class="clearfix"></div>
          <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" *ngIf="this.selectedFiles.length > 0">
            <ng-container matColumnDef="fileName">
              <th mat-header-cell *matHeaderCellDef style="width: 80%">File Name</th>
              <td mat-cell *matCellDef="let element">
                <div class="row">
                  <div class="col-1">
                    <img src="/assets/icons/File.svg" style="height: 2rem" alt="File" />
                  </div>
                  <div class="col-11">
                    <span class="testing" style="cursor: auto"> {{ element.name }} </span><br />
                    <span class="mat-subtitle-2">{{ formatSize(element.size) }}</span>
                  </div>
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="deleteAttachment">
              <th mat-header-cell *matHeaderCellDef style="width: 10%"></th>
              <td mat-cell *matCellDef="let element; let i = index">
                <button mat-icon-button (click)="deleteAttachment(i)" *ngIf="!isSuccess">
                  <mat-icon>delete_outline</mat-icon>
                </button>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayAddColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayAddColumns"></tr>
          </table>
          <div class="float-right mt-8" *ngIf="projectId && this.selectedFiles.length > 0">
            <a
              mat-button
              color="primary"
              (click)="clearSelection()"
              (keypress)="clearSelection()"
              [disabled]="this.selectedFiles.length === 0">
              Cancel
            </a>
            <a
              mat-button
              color="primary"
              (click)="uploadByManager()"
              (keypress)="uploadByManager()"
              [disabled]="this.selectedFiles.length === 0">
              Upload
            </a>
          </div>
          <div class="clearfix"></div>
          <table
            mat-table
            [dataSource]="dataSourceAttached"
            class="mat-elevation-z8"
            *ngIf="projectId && attachedFiles.length > 0">
            <ng-container matColumnDef="fileName">
              <th mat-header-cell *matHeaderCellDef style="width: 90%">File Name</th>
              <td mat-cell *matCellDef="let element">
                <div class="row">
                  <div class="col-1">
                    <img src="/assets/icons/File.svg" style="vertical-align: baseline; height: 2rem" alt="File" />
                  </div>
                  <div class="col-11">
                    <a class="testing" (click)="getAttachment(element)" href="javascript:void(0)">
                      {{ element.fileName }}
                    </a>
                    <br />
                    <span class="mat-subtitle-2">{{ element.createdAt | date }}</span>
                  </div>
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="deleteStoredAttachment">
              <th mat-header-cell *matHeaderCellDef style="width: 10%"></th>
              <td mat-cell *matCellDef="let element">
                <button
                  mat-icon-button
                  (click)="deleteStoredAttachment(element)"
                  *ngIf="!this.userService.roleCheck(['Reportwriter', 'Labtech'])">
                  <mat-icon>delete_outline</mat-icon>
                </button>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayStoreColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayStoreColumns"></tr>
          </table>
        </div>
      </div>
    </mat-drawer>
    <mat-drawer-content
      [class.drawer-content-yellow]="backgroundClass === 'yellow'"
      [class.drawer-content-gray]="backgroundClass === 'gray'">
      <a href="javascript:void(0)" class="testing c-primary" (click)="drawer.toggle()">
        <img src="/assets/icons/Folder.svg" alt="Folder" style="vertical-align: inherit" /> &nbsp; Add/View
        Attachment(s) [{{ projectId ? attachedFiles.length : selectedFiles.length }}]
      </a>
    </mat-drawer-content>
  </mat-drawer-container>
</div>
