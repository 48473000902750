<div class="section-1200" id="sample-collection-container" *ngIf="userAccount || showForm">
  <div class="section-header">
    <nav aria-label="breadcrumb" class="bredcrums" *ngIf="userAccount">
      <ol class="breadcrumb">
        <li class="breadcrumb-item active breadcrumb cursor" aria-current="page">
          <span (click)="redirectUrl()" (keypress)="redirectUrl()" tabindex="0"> Dashboard </span>
        </li>
        <li class="breadcrumb-item active" aria-current="page">{{ submissionType === 'native-soil' ? 'Native Soil/Backfill' : 'Pre Cast Sample' }}
           Form</li>
      </ol>
    </nav>
    <div class="row mb-3 precast-row" [ngClass] = "{'s-head-container':!userAccount }">
      <div class="col-md-12">
        <h5 class="heading-sample">
          {{ submissionType === 'native-soil' ? 'Native Soil/Backfill' : 'Pre Cast Sample' }}
          Form
        </h5>
      </div>
      <p class="title-sampling">Sampling and Shipment of  {{ submissionType === 'native-soil' ? 'Native Soil/Backfill' : 'Pre Cast' }}
         Samples for Thermal Analysis</p>
    </div>
    <mat-stepper
      animationDuration="0ms"
      [@.disabled]="true"
      [linear]="'true'"
      #stepper
      [@.disabled]="true"
      (selectionChange)="stepChanged($event, stepper)">
      <mat-step [completed]="companyValid">
        <ng-template matStepLabel>Company Information</ng-template>
        <app-company-information
          *ngIf="checkLoadForm"
          [makeEditable]="makeSampleEditable"
          [submissionType]="submissionType"
          [data]="companyInformation"
          (companyData)="getCompanyData($event)"></app-company-information>
        <div class="stepper-buttons">
          <div class="float-right">
            <button class="mr-16" mat-raised-button (click)="onCancelButtonClick()">CANCEL</button>
            <button (click)="stepperNext(1)" mat-raised-button color="primary" [disabled]="!companyValid">
              NEXT
              <img class="arrow-right-space" src="/assets/icons/Arrow-right.svg" alt="arrow-right" />
            </button>
          </div>
          <div class="clearfix"></div>
        </div>
      </mat-step>
      <mat-step [completed]="sampleDataPrecastValid || sampleDataNativeValid">
        <ng-template matStepLabel>Sample Data</ng-template>
        <app-sample-data-nativesoil
          [data]="soilData"
          [makeEditable]="makeSampleEditable"
          (sampleDataNativeData)="getNativeData($event)"
          *ngIf="checkLoadForm && submissionType === 'native-soil'"></app-sample-data-nativesoil>
        <app-sample-data-precast
          [makeEditable]="makeSampleEditable"
          [data]="soilData"
          [companyData] = "company"
          (sampleDataPrecastData)="getPrecastData($event)"
          (sampleTypeSelected)="sampleTypeSelected($event)"
          *ngIf="checkLoadForm && submissionType === 'precast'"></app-sample-data-precast>
        <div class="stepper-buttons">
          <div class="float-left">
            <button mat-raised-button (click)="stepperPrevious(0)">
              <img class="arrow-left-space" src="/assets/icons/Arrow-left.svg" alt="arrow-left" /> BACK
            </button>
          </div>
          <div class="float-right">
            <button class="mr-16" mat-raised-button (click)="onCancelButtonClick()">CANCEL</button>
            <button
              *ngIf="submissionType === 'precast'"
              (click)="stepperNext(2)"
              mat-raised-button
              color="primary"
              [disabled]="!sampleDataPrecastValid">
              NEXT
              <img class="arrow-right-space" src="/assets/icons/Arrow-right.svg" alt="arrow-right" />
            </button>
            <button
              *ngIf="submissionType === 'native-soil'"
              (click)="stepperNext(2)"
              mat-raised-button
              color="primary"
              [disabled]="!sampleDataNativeValid">
              NEXT
              <img class="arrow-right-space" src="/assets/icons/Arrow-right.svg" alt="arrow-right" />
            </button>
          </div>
          <div class="clearfix"></div>
        </div>
      </mat-step>
      <mat-step [completed]="commentsValid">
        <ng-template matStepLabel>Comments</ng-template>
        <app-requirement-comment
          *ngIf="checkLoadForm"
          [data]="requirementComment"
          (requirementData)="getInformationData($event)"
          [submissionType]="submissionType"
          [selectedSampleTypes]="selectedSampleTypes"></app-requirement-comment>
        <div class="stepper-buttons">
          <div class="float-left">
            <button mat-raised-button (click)="stepperPrevious(1)">
              <img class="arrow-left-space" src="/assets/icons/Arrow-left.svg" alt="arrow-left" />
              BACK
            </button>
          </div>
          <div class="float-right">
            <button mat-raised-button class="mr-16" (click)="onCancelButtonClick()">CANCEL</button>
            <button mat-raised-button color="primary" (click)="review(projectId, submissionType)">REVIEW</button>
          </div>
          <div class="clearfix"></div>
        </div>
      </mat-step>
    </mat-stepper>
  </div>
</div>
