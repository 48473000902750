import { Component, Input } from '@angular/core';
import { ChartOptions } from 'chart.js';

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss'],
})
export class LineChartComponent {
  @Input() lineChartData: any;

  public lineChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'bottom',
      },
      title: {
        display: true,
        text: 'THERMAL DRYOUT CURVE',
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'MOISTURE CONTENT (% DRY WEIGHT)',
        },
      },
      y: {
        title: {
          display: true,
          text: 'THERMAL RESISTIVITY (°C -cm/W)',
        },
      },
    },
  };

  public lineChartLegend = true;
  public lineChartType = 'line';
}
