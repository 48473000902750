import { Directive, ElementRef, HostListener, Output, EventEmitter } from '@angular/core';

@Directive({
  selector: '[appScrollDown]',
})
export class ScrollDownDirective {
  @Output() scrolledDown = new EventEmitter<void>();

  constructor(private el: ElementRef) {}

  @HostListener('scroll', ['$event.target'])
  onScroll(target: HTMLElement): void {
    if (this.isScrolledToBottom(target)) {
      this.scrolledDown.emit();
    }
  }

  private isScrolledToBottom(target: HTMLElement): boolean {
    const { scrollTop } = target;
    const { scrollHeight } = target;
    const { clientHeight } = target;
    return Number(scrollTop) + Number(clientHeight) >= Number(scrollHeight) - 5;
  }

  private checkScrollability(target: HTMLElement): boolean {
    const canScroll = target.scrollHeight > target.clientHeight;
    return canScroll;
  }
}
