/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IPrecastRecord } from 'src/app/shared/models/i-precast-record.modal';
import { IDashboardItems } from '../models/idashboard-items.modal';
import { ProjectsCount } from '../models/iprojects-count.modal';

import { PrecastWorksheet } from '../../features/worksheet/models/precast-worksheet.modal';
import { WorksheetResponse } from '../../features/worksheet/models/worksheet-response.modal';
import { ISetStatus } from '../../features/models/istatus-response.modal';
import { INotes } from '../../features/models/inotes.modal';
import { environment } from '../../../environments/environment';
import { IDropdown } from '../models/i-dropdown-type.modal';
import { IUser } from '../models/i-authorization.modal';
import { ICompany } from '../models/i-company.modal';
import { HttpService } from './http.service';
import { IProject } from '../models/i-project.modal';
import { IAuditLogApiOperations,
  IAuditLogEmails,
  IAuditLogJsonUrl,
  IAuditLogReportItems,
  IAuditLogResourceType } from '../models/i-auditlog-items.modal';

@Injectable({
  providedIn: 'root',
})
export class PrecastService {
  projectStatuses: IDropdown[] = [];
  sampleEditable = false;
  constructor(private http: HttpClient, private httpService: HttpService) {}

  public addSample(
    payload: IPrecastRecord,
    submissionType: string,
    userAccount: any,
  ): Observable<{ containerName: string } | IPrecastRecord> {
    let onlineSubmissionUrl: string | undefined;
    if (submissionType === 'precast') {
      onlineSubmissionUrl = !userAccount ? 'customer/addprecast' : 'dashboard/addprecast';
    } else {
      onlineSubmissionUrl = !userAccount ? 'customer/addnativesoil' : 'dashboard/addnativesoil';
    }
    return this.http.post<{ containerName: string } | IPrecastRecord>(
      environment.baseUrl + onlineSubmissionUrl,
      payload,
    );
  }

  public fetchProjectsCount(
    page: number | string | null,
    searchKeyWord: any,
    myProjects: boolean,
    isActiveProjectFlagApi:boolean,
    isMixId:boolean,
    columnName: string,
    materialType?: string,
    projectStatus?: string,
  ): Observable<number> {
    let queryParams = `?page=${page}`;
    if (searchKeyWord !== '') {
      searchKeyWord = encodeURIComponent(searchKeyWord);
      sessionStorage.setItem('searchKeyWord', searchKeyWord);
      queryParams += `&searchValue=${searchKeyWord}`;
    }
    if (myProjects) {
      queryParams += `&myProjects=${myProjects}`;
    }
    if (materialType) {
      queryParams += `&materialType=${materialType}`;
    }
    if (projectStatus) {
      queryParams += `&projectStatus=${projectStatus}`;
    }
    if (columnName !== '') {
      queryParams += `&orderByColumns=${columnName}`;
    }
    if (isActiveProjectFlagApi) {
      queryParams += `&includeInactive=${isActiveProjectFlagApi}`;
    }
    if (isMixId) {
      queryParams += `&includeMixDesignFlag=${isMixId}`;
    }
    return this.http.get<number>(`${environment.baseUrl}dashboard/getallprojectscount${queryParams}`);
  }

  public fetchProjects(
    page: number,
    searchKeyWord: string,
    recordsPerPage: number,
    columnName: string,
    myProjects: boolean,
    isActiveProjectFlagApi:boolean,
    isMixId:boolean,
    materialType?: string,
    projectStatus?: string,
  ): Observable<IDashboardItems[]> {
    let queryParams = `?page=${page}&pageSize=${recordsPerPage}`;
    if (searchKeyWord !== '') {
      searchKeyWord = encodeURIComponent(searchKeyWord);
      queryParams += `&searchValue=${searchKeyWord}`;
    }
    if (materialType) {
      queryParams += `&materialType=${materialType}`;
    }
    if (projectStatus) {
      queryParams += `&projectStatus=${projectStatus}`;
    }
    if (columnName !== '') {
      queryParams += `&orderByColumns=${columnName}`;
    }
    if (myProjects) {
      queryParams += `&myProjects=${myProjects}`;
    }
    if (isActiveProjectFlagApi) {
      queryParams += `&includeInactive=${isActiveProjectFlagApi}`;
    }
    if (isMixId) {
      queryParams += `&includeMixDesignFlag=${isMixId}`;
    }
    return this.http.get<IDashboardItems[]>(`${environment.baseUrl}dashboard/getallprojectspaged${queryParams}`);
  }

  public fetchProjectById(projectId: string): Observable<IProject> {
    return this.http.get<IProject>(`${environment.baseUrl}project/${projectId}`);
  }

  public fetchCompanyById(projectId: string): Observable<ICompany> {
    return this.http.get<ICompany>(`${environment.baseUrl}company/getbyprojectid/${projectId}`);
  }

  public fetchSampleCount(): Observable<ProjectsCount> {
    return this.http.get<ProjectsCount>(`${environment.baseUrl}dashboard/getallsamplecounts`);
  }

  public fetchWorksheetbyId(projectId: string): Observable<PrecastWorksheet[]> {
    return this.http.get<PrecastWorksheet[]>(`${environment.baseUrl}precastws/getworksheet/${projectId}`);
  }

  public fetchWorksheetRowbyId(projectId: string): Observable<PrecastWorksheet[]> {
    return this.http.get<PrecastWorksheet[]>(`${environment.baseUrl}precastws/getworksheetrow/${projectId}`);
  }

  public generateWorksheet(projectId: string): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}precastws/generateworksheet/${projectId}`);
  }

  public fetchNativeSoilWorksheet(projectId: string): Observable<WorksheetResponse> {
    return this.http.get<WorksheetResponse>(`${environment.baseUrl}worksheet/getnativesoilworksheets/${projectId}`);
  }

  public getNotesbyId(projectId: string): Observable<INotes[]> {
    return this.httpService.get(`note/getprojectnotes/${projectId}`);
  }

  public addNotes(payload: INotes): Observable<INotes> {
    return this.httpService.post('note/add', payload);
  }

  public setStatus(payload: ISetStatus): Observable<ISetStatus> {
    return this.httpService.post('dashboard/setprojectstatus', payload);
  }

  public addWorksheet(payload: PrecastWorksheet): Observable<PrecastWorksheet> {
    return this.http.post<PrecastWorksheet>(`${environment.baseUrl}precastws/addworksheetrow`, payload);
  }

  public updateWorksheet(payload: PrecastWorksheet): Observable<PrecastWorksheet> {
    return this.http.post<PrecastWorksheet>(`${environment.baseUrl}precastws/updateworksheetrow`, payload);
  }

  public deleteWorksheet(worksheetId: string): Observable<object> {
    return this.http.delete(`${environment.baseUrl}precastws/deleteworksheetrow/${worksheetId}`);
  }

  public fetchProjectsLookup(): Observable<IDropdown[]> {
    return this.httpService.get('lookup/getprojectstatuses');
  }

  public fetchNativeSampleTypes(): Observable<IDropdown[]> {
    return this.httpService.get('lookup/getnativesampletypes');
  }

  public fetchLabTechs(): Observable<IUser[]> {
    return this.httpService.get('user/getlabtechs');
  }

  public assignToLabtech(payload: any): Observable<PrecastWorksheet> {
    return this.http.post<PrecastWorksheet>(`${environment.baseUrl}precastws/assignlabtech`, payload);
  }

  public fetchMaterialTypes(): Observable<IDropdown[]> {
    return this.httpService.get('lookup/getmaterialtypes');
  }

  public fetchProjectStatuses(): Observable<IDropdown[]> {
    return this.httpService.get('lookup/getprojectstatuses');
  }

  public assignToReportWriter(projectId: string): Observable<any> {
    return this.http.get<string>(`${environment.baseUrl}project/assigntoreporting/${projectId}`);
  }

  public assignToMyself(projectId: string) {
    return this.httpService.post(`dashboard/assigntome/${projectId}`);
  }

  public updateSample(payload: IPrecastRecord, submissionType: string): Observable<boolean | IPrecastRecord> {
    let url: string;
    if (submissionType === 'precast') {
      url = 'dashboard/updatepcform';
    } else {
      url = 'dashboard/updatensform';
    }
    return this.http.post<boolean | IPrecastRecord>(`${environment.baseUrl}${url}`, payload);
  }

  public updateDueDate(payload: IPrecastRecord, projectID: string, dueDate: string): Observable<boolean | IPrecastRecord> {
    return this.http.post<boolean | IPrecastRecord>(`${environment.baseUrl}project/updateduedate/${projectID}/${dueDate}`, payload);
  }

  public uploadAttachments(container: string, attachedFiles: FormData, userAccount: any) {
    const onlineSubmissionUrl = !userAccount ? 'customer/uploads/' : 'storage/uploads/';
    return this.http.post<any>(`${environment.baseUrl + onlineSubmissionUrl + container}`, attachedFiles);
  }

  public getAllattachments(projectId: string) {
    return this.http.get<any>(`${environment.baseUrl}project/getattachments/${projectId}`);
  }

  getAttachement(projectId: string, fileName: string) {
    let params = new HttpParams();
    params = params.set('projectId', projectId);
    params = params.set('fileName', fileName);
    return this.http.get<any>(`${environment.baseUrl}storage/getattachment`, {
      params,
    });
  }

  deleteAttchment(projectId: string, fileName: string) {
    let params = new HttpParams();
    params = params.set('projectId', projectId);
    params = params.set('fileName', fileName);
    return this.http.delete<any>(`${environment.baseUrl}storage/delete`, {
      params,
    });
  }

  getSampleStatusLookup() {
    return this.httpService.get('lookup/getsamplestatuses');
  }

  getSampleInformation(projectId: string, projectType: string) {
    const url = projectType === 'Pre Cast' ? `sample/getpc/${projectId}` : `sample/getns/${projectId}`;
    return this.httpService.get(url);
  }

  updateSampleStatus(projectType: string, payload: any) {
    const url = projectType === 'Pre Cast' ? 'sample/updatepcstatus' : 'sample/updatensstatus';
    return this.httpService.post(url, payload);
  }

  public softDeleteRecord(projectID: string, soilType: number): Observable<object> {
    return this.http.delete(`${environment.baseUrl}dashboard/deleteproject/${projectID}/${soilType}`);
  }

  public restoreDeleteRecord(projectID: string): Observable<object> {
    return this.http.patch(`${environment.baseUrl}dashboard/activateproject/${projectID}`, {
      restored: true,
    });
  }

  public makeRecordInactiveApiCall(projectID: string): Observable<object> {
    return this.http.patch(`${environment.baseUrl}dashboard/deactivateproject/${projectID}`, {
      restored: true,
    });
  }

  getInhouseId(sample: string) {
    return this.http.get<PrecastWorksheet>(`${environment.baseUrl}dashboard/checkinhouseidprefix/${sample}`);
  }

  postInhouseId(payload: any) {
    return this.http.post<PrecastWorksheet>(`${environment.baseUrl}project/updateinhouseidprefix`, payload);
  }

  public fetchAuditLogReport(
    startDate: any,
    endDate: any,
    emails: any,
    userNames: string,
    resourceTypes: any,
    apiOperations:any,
    pageNumber: number,
    pageSize: number,
    continuationToken:string | null,
  ): Observable<IAuditLogReportItems> {
    let queryParams = `?PageNumber=${pageNumber}&PageSize=${pageSize}`;
    if (startDate !== '') {
      queryParams += `&startDate=${startDate}`;
    }
    if (endDate !== '') {
      queryParams += `&endDate=${endDate}`;
    }
    if (emails !== '') {
      let queryEmails = '';
      emails.forEach((type: any) => {
        queryEmails += `&emails=${type}`;
      });
      queryParams += queryEmails;
    }
    if (userNames !== '') {
      queryParams += `&userNames=${userNames}`;
    }
    if (resourceTypes !== '') {
      let queryResource = '';
      resourceTypes.forEach((type: any) => {
        queryResource += `&resourceTypes=${type}`;
      });
      queryParams += queryResource;
    }
    if (continuationToken !== '') {
      queryParams += `&continuationToken=${continuationToken}`;
    }
    if (apiOperations !== '') {
      let queryApiOperation = '';
      apiOperations.forEach((type: any) => {
        queryApiOperation += `&apiOperations=${type}`;
      });
      queryParams += queryApiOperation;
    }
    return this.http.get<IAuditLogReportItems>(`${environment.baseUrl}auditlog/getauditlogdata${queryParams}`);
  }

  public fetchEmailsFilter(): Observable<IAuditLogEmails> {
    return this.http.get<IAuditLogEmails>(`${environment.baseUrl}lookup/getalluseremails`);
  }

  public fetchResourceTypeFilter(): Observable<IAuditLogResourceType> {
    return this.http.get<IAuditLogResourceType>(`${environment.baseUrl}lookup/getauditresourcetypes`);
  }

  public fetchApiOperationFilter(): Observable<IAuditLogApiOperations> {
    return this.http.get<IAuditLogApiOperations>(`${environment.baseUrl}lookup/getauditactiontypes`);
  }

  public fetchAuditReportJson(fileName: string): Observable<IAuditLogJsonUrl> {
    return this.http.get<IAuditLogJsonUrl>(`${environment.baseUrl}auditlog/getbloburl/${fileName}`);
  }
}
