/* eslint-disable no-useless-escape */
import { AbstractControl, FormControl, ValidatorFn, Validators } from '@angular/forms';

export class MultipleEmailValidator extends Validators {
  // eslint-disable-next-line max-len
  static emailPattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  static validateCharacters(control: FormControl) {
    if (control.value && control.value.length > 0) {
      const emailsArray = control.value.split(',');
      for (let i = 0; i < emailsArray.length; i++) {
        if (!MultipleEmailValidator.emailPattern.test(emailsArray[i])) {
          return {
            emailsError: true,
          };
        }
      }
    }
    return null;
  }

  static comaSeperatedEmailValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control instanceof FormControl) {
        if (control.value && control.value.length > 0) {
          const emailsArray = control.value.split(',');
          for (let i = 0; i < emailsArray.length; i++) {
            if (emailsArray[i].trim() !== '') {
              if (!MultipleEmailValidator.emailPattern.test(emailsArray[i].trim())) {
                return {
                  emailsError: true,
                };
              }
            }
          }
        }
        return null;
      }
      return null;
    };
  }
}
