/* eslint-disable @typescript-eslint/ban-types */
import { Subscription } from 'rxjs';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function AutoUnsubscribe(constructor: Function) {
  const original = constructor.prototype.ngOnDestroy;

  // eslint-disable-next-line func-names
  constructor.prototype.ngOnDestroy = function () {
    Object.keys(this).forEach((prop) => {
      if (Object.prototype.hasOwnProperty.call(this, prop)) {
        const property = this[prop];
        if (property instanceof Subscription) {
          property.unsubscribe();
        } else if (Array.isArray(property)) {
          property.forEach((item) => {
            if (item instanceof Subscription) {
              item.unsubscribe();
            }
          });
        }
      }
    });

    if (original && typeof original === 'function') {
      original.apply(this);
    }
  };
}
