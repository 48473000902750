<div class="gcontainer bwhite">
  <div class="row">
    <div class="col-md-11">
      <nav aria-label="breadcrumb" class="mt-8">
        <ol class="breadcrumb">
          <li
            class="breadcrumb-item active breadcrumb cursor"
            aria-current="page"
            (click)="reDirectUrl()"
            (keypress)="reDirectUrl()"
            tabindex="0">
            Dashboard
          </li>
          <li class="breadcrumb-item active" aria-current="page">Settings</li>
        </ol>
      </nav>
    </div>
    <h1>Settings</h1>

    <mat-tab-group
      [selectedIndex]="tabIndex"
      mat-stretch-tabs="false"
      mat-align-tabs="start"
      class="mat-table-view"
      animationDuration="0ms"
      (selectedIndexChange)="componentTabs($event)">
      <ng-container *ngFor="let tab of getFilteredTabs(); let i = index">
        <mat-tab [label]="getTabName(tab)" class="mt-32" (click)="handleTabClick(i)">
          <mat-divider></mat-divider>
        </mat-tab>
      </ng-container>
    </mat-tab-group>
    <ng-container #tabContent></ng-container>
  </div>
</div>
