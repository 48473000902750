import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SliderDataService {
  // BehaviorSubject to hold the selected soil type with a default value
  private selectedSoilTypeSource = new BehaviorSubject<string>('native-soil');
  // Create a BehaviorSubject with an initial value (e.g., 0)
  private sliderValueSource = new BehaviorSubject<number>(0);

  selectedSoilType$ = this.selectedSoilTypeSource.asObservable();
  // Expose the observable for components to subscribe to
  sliderValue$ = this.sliderValueSource.asObservable();

  updateSelectedSoilType(soilType: string) {
    this.selectedSoilTypeSource.next(soilType);
  }

  // Method to update the slider value
  updateSliderValue(value: number) {
    this.sliderValueSource.next(value);
  }
}
