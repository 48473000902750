import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appCommaSpace]',
})
export class CommaSpaceDirective {
  constructor(private ngControl: NgControl) { }

  @HostListener('input', ['$event'])
  onInput(event: Event) {
    const input = event.target as HTMLInputElement;
    let { value } = input;
    value = value.replace(/,{2,}/g, ',');
    value = value.replace(/(?<!,) /g, '');
    this.ngControl.control?.setValue(value);
  }
}
