import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CleanupService implements OnDestroy {
  private unsubscribe$ = new Subject<void>();

  get unsubscribe() {
    return this.unsubscribe$;
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
