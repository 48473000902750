import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { IPrecastRecord } from 'src/app/shared/models/i-precast-record.modal';
import { HttpService } from 'src/app/shared/services/http.service';

@Injectable({
  providedIn: 'root',
})
export class FormService {
  constructor(private httpService: HttpService) {}
  private savedDataSubject = new BehaviorSubject<IPrecastRecord | null>(null);
  public attachments = new BehaviorSubject<File[] | null>(null);
  savedData$ = this.savedDataSubject.asObservable();
  public fetchFormData(projectId: string | null, soilType: string): Observable<IPrecastRecord> {
    let url = '';
    if (soilType === 'precast') {
      url = `dashboard/getpcform/${projectId}`;
    } else {
      url = `dashboard/getnsform/${projectId}`;
    }
    return this.httpService.get(url);
  }

  updateData(newData: IPrecastRecord | null): void {
    this.savedDataSubject.next(newData);
  }

  getSavedData(): Observable<IPrecastRecord | null> {
    return this.savedDataSubject.asObservable();
  }

  updateAttachments(files: File[] | null) {
    this.attachments.next(files);
  }

  getAttachemnts(): Observable<File[] | null> {
    return this.attachments.asObservable();
  }
}
