export class PrecastWorksheet {
  id: string | undefined;
  inHouseId?: string | null;
  projectId?: string | undefined;
  preCastSampleId?: string | undefined;
  sampleType?: string | undefined;
  castDate?: string = '';
  sampleId?: string | undefined = '';
  mixDesignCode: string;
  isSetRowCountDifferent:boolean;
  tare = 0;
  mould = 0;
  sampleDiameter = 0;
  sampleHeight = 0;
  wetPlusTare = 0;
  dryPlusTare = 0;
  drySoilWeight = 0;
  loss = 0;
  volume = 0;
  density = 0;
  wetTr = 0;
  dryTr = 0;
  moisture = 0;
  gramPerCmCube = 0;
  Tech: string | null;
  status = false;
  createdAt?: string;
  createdBy?: string | undefined;
  updatedAt?: string;
  updatedBy?: string | undefined;
  multiPointTests: WetSample[];
  groupOrder: number | null;
  sequence?: number;
  truckNumber?:number;
  ticketNumber?:number;
  assignedTo?: string;
}

interface WetSample {
  wetTR: number;
  wetPlusTare: number;
}
