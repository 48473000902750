import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

// Validator function to check if the value is between 1 and 3
export function samplePerSetValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const { value } = control;

    // If the value is null or empty, let the required validator handle it
    if (value === null || value === undefined || value === '') {
      return null;
    }

    // Parse the value into a number
    const numValue = Number(value);

    // Check if the value is a number and falls between 1 and 3
    const isValid = numValue >= 1 && numValue <= 3;

    // If the value is not valid, return an error object, otherwise return null
    return isValid
      ? null
      : {
        outOfRange: true,
      };
  };
}
