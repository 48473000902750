export const precastColumns = [
  {
    displayName: 'Status',
    name: 'sampleStatusTypeId',
    format: '',
  },
  {
    displayName: 'ID / Station Location',
    name: 'sampleId',
    format: 'truncate',
  },
  {
    displayName: 'Type',
    name: 'sampleType',
    format: '',
  },
  {
    displayName: 'Truck #',
    name: 'truckNumber',
    format: '',
  },
  {
    displayName: 'Ticket #',
    name: 'ticketNumber',
    format: '',
  },
  {
    displayName: 'Collection Date',
    name: 'sampleCollectionDate',
    format: 'date',
  },
  {
    displayName: 'Samples Per Set',
    name: 'numSamplesPerSet',
    format: '',
  },
  {
    displayName: 'Mix Design Code',
    name: 'mixDesignCode',
    format: 'truncate',
  },
];
