import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RecaptchaModule } from 'ng-recaptcha';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { CustomerValidationRoutingModule } from './customer-validation.routing.module';
import { CustomerValidationComponent } from './customer-validation.component';

@NgModule({
  declarations: [CustomerValidationComponent],
  imports: [
    CommonModule,
    CustomerValidationRoutingModule,
    MatRadioModule,
    MatCardModule,
    ReactiveFormsModule,
    RecaptchaModule,
    MatFormFieldModule,
    MatButtonModule,
  ],
})
export class CustomerValidationModule {}
