import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[appRestrictDotFirst]',
})
export class RestrictInputDirective {
  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event'])
  onInputChange(): void {
    const inputElement = this.el.nativeElement as HTMLInputElement;
    const { value } = inputElement;

    // Remove leading dot
    if (value.startsWith('.')) {
      inputElement.value = value.substring(1);
    }
  }

  @HostListener('keypress', ['$event'])
  onKeyPress(event: KeyboardEvent): void {
    const inputElement = this.el.nativeElement as HTMLInputElement;
    const char = String.fromCharCode(event.which);

    // Prevent dot as the first character
    if (char === '.' && inputElement.value.length === 0) {
      event.preventDefault();
    }
  }
}
