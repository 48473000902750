import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { IInstructionData, IInstructionModel } from 'src/app/shared/models/i-instruction-modal';

@Component({
  selector: 'app-requirement-comment',
  templateUrl: 'requirement-comment.component.html',
  styleUrls: ['requirement-comment.component.scss'],
})
export class RequirementCommentComponent implements OnInit, OnChanges {
  @Output() requirementData: EventEmitter<IInstructionData> = new EventEmitter();
  @Input() data:IInstructionModel | null;
  @Input() selectedSampleTypes: string[];
  @Input() submissionType: string;
  requirementCommentForm: FormGroup;

  constructor(private fb: FormBuilder) {
    this.requirementCommentForm = this.fb.group({
      concreteInstruction: [''],
      ftbInstruction: [''],
      groutSlurryInstruction: [''],
      otherInstruction: [''],
      additionalCommentsNativeSoil: [''],
    });
  }

  ngOnInit(): void {
    this.requirementCommentForm?.valueChanges?.subscribe((frmGrp) => {
      this.requirementData?.emit({
        valid: this.requirementCommentForm?.valid,
        dirty: this.requirementCommentForm?.dirty,
        value: frmGrp,
      });
    });
  }

  ngOnChanges() {
    if (this.data) {
      this.requirementCommentForm.patchValue(this.data);
    }
  }
}
