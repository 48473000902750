import { Injectable, OnDestroy, Renderer2, RendererFactory2 } from '@angular/core';
import { fromEventPattern, Observable, Subject, takeUntil } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TrackEventService implements OnDestroy {
  public idle$: Subject<boolean> = new Subject();
  public wake$: Subject<boolean> = new Subject();
  isIdle = false;
  public destroy$ = new Subject();
  public onClick$: Observable<Event>;
  public onMouseMove$:Observable<Event>;
  public onMouseUp$:Observable<Event>;
  public onMKeyUp$:Observable<Event>;
  public onWindowBlur$:Observable<Event>;

  constructor(private rendererFactory2: RendererFactory2) {
    const renderer = this.rendererFactory2.createRenderer(null, null);
    this.createOnClickObservable(renderer);
    this.createOnMouseMoveObservable(renderer);
    this.createOnScrollObservable(renderer);
    this.createOnKeyUpObservable(renderer);
    this.createOnWindowBlurObservable(renderer);
  }

  ngOnDestroy() {
    this.destroy$.next(2);
    this.destroy$.complete();
  }

  private createOnClickObservable(renderer: Renderer2) {
    let removeClickEventListener: () => void;
    const createClickEventListener = (
      handler: (e: Event) => boolean | void,
    ) => {
      removeClickEventListener = renderer.listen('document', 'click', handler);
    };

    this.onClick$ = fromEventPattern<Event>(createClickEventListener, () => removeClickEventListener()).pipe(takeUntil(this.destroy$));
  }

  private createOnMouseMoveObservable(renderer: Renderer2) {
    let removeMouseMoveEventListener: () => void;
    const createMouseMoveEventListener = (
      handler: (e: Event) => boolean | void,
    ) => {
      removeMouseMoveEventListener = renderer.listen('document', 'mousemove', handler);
    };

    this.onMouseMove$ = fromEventPattern<Event>(createMouseMoveEventListener, () => removeMouseMoveEventListener()).pipe(takeUntil(this.destroy$));
  }

  private createOnScrollObservable(renderer: Renderer2) {
    let removeScrollEventListener: () => void;
    const createScrollEventListener = (
      handler: (e: Event) => boolean | void,
    ) => {
      removeScrollEventListener = renderer.listen('window', 'scroll', handler);
    };

    this.onMouseUp$ = fromEventPattern<Event>(createScrollEventListener, () => removeScrollEventListener()).pipe(takeUntil(this.destroy$));
  }

  private createOnKeyUpObservable(renderer: Renderer2) {
    let removeKeyUpEventListener: () => void;
    const createKeyUpEventListener = (
      handler: (e: Event) => boolean | void,
    ) => {
      removeKeyUpEventListener = renderer.listen('document', 'keyup', handler);
    };

    this.onMKeyUp$ = fromEventPattern<Event>(createKeyUpEventListener, () => removeKeyUpEventListener()).pipe(takeUntil(this.destroy$));
  }

  private createOnWindowBlurObservable(renderer: Renderer2) {
    let removeWindowBlurEventListener: () => void;
    const createWindowBlurEventListener = (
      handler: (e: Event) => boolean | void,
    ) => {
      removeWindowBlurEventListener = renderer.listen('window', 'blur', handler);
    };

    this.onWindowBlur$ = fromEventPattern<Event>(createWindowBlurEventListener, () => removeWindowBlurEventListener()).pipe(takeUntil(this.destroy$));
  }
}
