import { Directive, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appAlphanumeric]',
})
export class AlphanumericDirective {
  @Input() maxLength: number;
  constructor(private ngControl: NgControl) { }

  @HostListener('input', ['$event'])
  onInput(event: Event) {
    const input = event.target as HTMLInputElement;
    let value = input.value.replace(/[^a-zA-Z0-9]/g, '');
    if (this.maxLength && value.length > this.maxLength) {
      value = value.slice(0, this.maxLength);
    }
    this.ngControl.control?.setValue(value);
  }
}

// Only Alphabetic Directive
@Directive({
  selector: '[appAlphabetic]',
})
export class AlphabeticDirective {
  @Input() maxLength: number;

  constructor(private ngControl: NgControl) { }

  @HostListener('input', ['$event'])
  onInput(event: Event) {
    const input = event.target as HTMLInputElement;
    let value = input.value.replace(/[^a-zA-Z]/g, '');
    if (this.maxLength && value.length > this.maxLength) {
      value = value.slice(0, this.maxLength);
    }
    this.ngControl.control?.setValue(value);
  }
}
