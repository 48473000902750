import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SampleCollectionFormComponent } from './sample-collection-form.component';

const routes: Routes = [
  {
    path: ':sampletype/create',
    component: SampleCollectionFormComponent,
  },
  {
    path: ':sampletype/edit/:id',
    component: SampleCollectionFormComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SampleCollectionFormRoutingModule {}
