import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { SharedModule } from 'src/app/shared/shared.module';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AdminRoutingModule } from './admin-routing.module';
import { AdminSettingsComponent } from './admin-settings/admin-settings.component';
import { DashboardSettingsComponent } from './dashboard-settings/dashboard-settings.component';
import { NativeSoilSettingsComponent } from './native-soil-settings/native-soil-settings.component';
import { PrecastSettingsComponent } from './precast-settings/precast-settings.component';
import { SettingsComponent } from './settings/settings.component';

@NgModule({
  declarations: [
    SettingsComponent,
    DashboardSettingsComponent,
    AdminSettingsComponent,
    PrecastSettingsComponent,
    NativeSoilSettingsComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    AdminRoutingModule,
    SharedModule,
    MatTabsModule,
    MatDividerModule,
    MatFormFieldModule,
    MatInputModule,
    MatTableModule,
    DragDropModule,
    MatSlideToggleModule,
    MatInputModule,
    MatIconModule,
    FormsModule,
    MatSnackBarModule,
  ],
})
export class AdminModule {}
