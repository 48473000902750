<h2 mat-dialog-title class="mat-h2">
  <span class="warning-title"><mat-icon class="warning-icon">warning</mat-icon> {{ config.title }}</span>
  <button mat-icon-button class="close-button" (click)="closeDialog('close')">
    <mat-icon>close</mat-icon>
  </button>
</h2>
<mat-dialog-content>
  {{ config.content }}
</mat-dialog-content>
<mat-divider></mat-divider>
<mat-dialog-actions align="end">
  <button mat-raised-button (click)="closeDialog('NO')">
    {{ config.cancelText ? config.cancelText : 'NO' }}
  </button>
  <button mat-raised-button color="primary" (click)="closeDialog('YES')">{{config.continueText ? config.continueText : 'YES'}}</button>
</mat-dialog-actions>
