import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PlatformBrowserService {
  getBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase();
    switch (true) {
      case agent.indexOf('edg') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }

  getBrowserVersion() {
    const { userAgent } = navigator;
    let tempMatch: any;
    let versionMatch: any = userAgent.match(
      /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i,
    ) || [];
    if (/trident/i.test(versionMatch[1])) {
      tempMatch = /\brv[ :]+(\d+)/g.exec(userAgent) || [];
      return `IE ${tempMatch[1] || ''}`;
    }
    if (versionMatch[1] === 'Chrome') {
      tempMatch = userAgent.match(/\b(OPR|Edge)\/(\d+)/);
      if (tempMatch != null) {
        return tempMatch.slice(1).join(' ').replace('OPR', 'Opera');
      }
    }
    versionMatch = versionMatch[2]
      ? [versionMatch[1], versionMatch[2]]
      : [navigator.appName, navigator.appVersion, '-?'];
    // eslint-disable-next-line no-cond-assign
    if ((tempMatch = userAgent.match(/version\/(\d+)/i)) != null) { versionMatch.splice(1, 1, tempMatch[1]); }
    return versionMatch;
  }
}
