import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ChartDataset } from 'chart.js';
import { SliderDataService } from 'src/app/shared/services/slider-data.service';
import { ExportTableService } from 'src/app/features/service/export-table.service';
import { GeoMapService } from '../../services/geo-map.service';

@Component({
  selector: 'app-modal-content',
  templateUrl: './modal-content.component.html',
  styleUrls: ['./modal-content.component.scss'],
})
export class ModalContentComponent implements OnInit {
  @Output() radiusChange = new EventEmitter<number>();
  selectedSoil: { label: string; referenceKey: string; DryDensity_Avg: number; Moisture_Avg: number };
  soilTypes: { label: string; referenceKey: string; DryDensity_Avg: number; Moisture_Avg: number }[];
  selectedDensity: { label: string; referenceKey: string; DryDensity_Avg: number; Moisture_Avg: number };
  densities: { label: string; referenceKey: string; DryDensity_Avg: number; Moisture_Avg: number }[];
  selectedMoisture: { label: string; referenceKey: string; DryDensity_Avg: number; Moisture_Avg: number };
  moistures: { label: string; referenceKey: string; DryDensity_Avg: number; Moisture_Avg: number }[];
  dryDensityAvg = 0;
  moistureAvg = 0;
  chartData: any;
  filenamelist:any;
  densityDisable = false;
  moistureDisable = false;
  cityName = '';
  selectedCountry = '';
  SelectedState = '';
  SelectSoilType = '';
  selectedSoilType = '';
  sliderValue = 0;
  typeLabel = 'Soil Type';
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ModalContentComponent>,
    private geoMapService: GeoMapService,
    private sliderDataService: SliderDataService,
    private exportFile:ExportTableService,
  ) {}

  ngOnInit(): void {
    this.sliderDataService.sliderValue$.subscribe((value) => {
      this.sliderValue = value;
    });

    this.sliderDataService.selectedSoilType$.subscribe((soilType) => {
      this.selectedSoilType = soilType;
    });
    // console.log(this.sliderValue, '+', this.selectedSoilType);
    const city = {
      latitude: this.data.city.latitude,
      longitude: this.data.city.longitude,
      radius: this.sliderValue ? this.sliderValue : 50,
    };
    this.cityName = this.data.city.city;

    this.selectedCountry = this.data.country;
    this.SelectedState = this.data.state;
    this.SelectSoilType = this.data.soiltype;
    if (this.selectedSoilType === 'native-soil') {
      this.geoMapService.getCityPredictionData(city).subscribe({
        next: (res) => {
          if (res) {
            this.filenamelist = res.filenames;
            this.chartData = res.soilType;
            this.soilTypes = this.chartData.options;
          }
        },
      });
    } else {
      this.typeLabel = 'Mix Id';
      this.geoMapService.getPrecastCityPredictionData(city).subscribe({
        next: (res) => {
          if (res) {
            this.filenamelist = res.filenames;
            this.chartData = res.mixid;
            this.soilTypes = this.chartData.options;
          }
        },
      });
    }
  }

  public lineChartData: ChartDataset[] = [];
  public lineChartLabels: string[] = [];

  selectSoilType() {
    this.densities = this.chartData[this.selectedSoil.referenceKey].density.options;
    this.moistures = [];
    this.lineChartData = [];
    const graphLabels: any[] = this.chartData[this.selectedSoil.referenceKey].density.options;
    const graphDatas: any[] = this.chartData[this.selectedSoil.referenceKey].density.graphs;
    graphDatas.forEach((graphData, i) => {
      const graphPoint = [];
      for (let j = 0; j < graphData.x.length; j++) {
        graphPoint.push({
          x: graphData.x[j],
          y: graphData.y[j],
        });
      }
      this.lineChartData.push({
        type: 'line',
        data: graphPoint,
        tension: 0.3,
        label: graphLabels[i].label,
      });
    });
    this.dryDensityAvg = 0;
    this.moistureAvg = 0;
  }

  selectDensity() {
    this.moistures = [];
    this.lineChartData = [];
    this.moistures = this.chartData[this.selectedSoil.referenceKey].density[this.selectedDensity.referenceKey].moisture.options;
    const graphLabels: any[] = this.chartData[this.selectedSoil.referenceKey].density[this.selectedDensity.referenceKey].moisture.options;
    const graphDatas: any[] = this.chartData[this.selectedSoil.referenceKey].density[this.selectedDensity.referenceKey].moisture.graphs;
    graphDatas.forEach((graphData, i) => {
      const graphPoint = [];
      for (let j = 0; j < graphData.x.length; j++) {
        graphPoint.push({
          x: graphData.x[j],
          y: graphData.y[j],
        });
      }
      this.lineChartData.push({
        type: 'line',
        data: graphPoint,
        tension: 0.3,
        label: graphLabels[i].label,
      });
    });

    this.dryDensityAvg = this.selectedDensity.DryDensity_Avg;
    this.moistureAvg = this.selectedDensity.Moisture_Avg;
  }

  selectMoisture() {
    this.lineChartData = [];
    const option: any[] = this.chartData[this.selectedSoil.referenceKey].density[this.selectedDensity.referenceKey].moisture.options;
    const selectedIndex = option.findIndex((opt) => opt.label === this.selectedMoisture.label);
    const graphData: any = this.chartData[this.selectedSoil.referenceKey].density[this.selectedDensity.referenceKey].moisture.graphs[
      selectedIndex
    ];
    const graphPoint = [];
    for (let j = 0; j < graphData.x.length; j++) {
      graphPoint.push({
        x: graphData.x[j],
        y: graphData.y[j],
      });
    }
    this.lineChartData.push({
      type: 'line',
      data: graphPoint,
      tension: 0.3,
      label: this.selectedMoisture.label,
    });

    this.dryDensityAvg = this.selectedMoisture.DryDensity_Avg;
    this.moistureAvg = this.selectedMoisture.Moisture_Avg;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  downloadData() {
    this.exportFile.downloadTxtFile(
      this.filenamelist,
      this.cityName,
      this.selectedCountry,
      this.SelectedState,
      this.SelectSoilType,
    );
  }
}
