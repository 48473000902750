<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<h2 mat-dialog-title class="mat-h2">
  Add Project
  <button mat-icon-button class="close-button" (click)="closeDialog()">
    <mat-icon>close</mat-icon>
  </button>
</h2>

<mat-dialog-content>
  <p class="browser-warning" *ngIf="isBrowserWarning">
    Please use either Chrome or Microsoft Edge browsers to fill and submit the form for your sample testing.
  </p>
  <form [formGroup]="materialTypeForm" #ngForm="ngForm">
    <p>Please choose the type of samples you wish to submit for testing.</p>
    <mat-radio-group color="primary" aria-label="Select an option" formControlName="materialType" required [disabled]="autoFillClicked">
      <ul class="list-style-type-none">
        <li>
          <mat-radio-button value="native-soil">Native Soil or Backfill</mat-radio-button>
        </li>
        <li>
          <mat-radio-button value="precast">Pre Cast - Concrete, FTB, Grout/Slurry</mat-radio-button>
        </li>
      </ul>
    </mat-radio-group>
    <mat-error *ngIf="ngForm?.submitted && materialTypeForm?.get('materialType')?.invalid">
      Please Select Material Type.
    </mat-error>
    <div id="fileupload" *ngIf="showFileUpload">
      <div class="browser-container" (change)="onFileSelected($event)" #fileInput>
        <input class="cursor-pointer" title="" type="file" #fileDropRef id="fileDropRef" single [disabled]="filePickDisabled" accept=".pdf"/>
        <mat-icon class="upload-icon">cloud_upload</mat-icon>
        <p>Drag and drop submission form</p>
        <p>or</p>
        <button mat-raised-button (click)="fileInput.click()" [disabled]="filePickDisabled">Browse files</button>
        <p class="file-size">Maximum file size is 10 MB</p>
      </div>
      <!-- file upload progress-->
      <div class="files-list">
        <div class="single-file" *ngFor="let file of uploadedFiles; let i = index">
          <div class="file-icon" style="width: 50px">
            <mat-icon> insert_drive_file</mat-icon>
          </div>
          <div class="info">
            <h4 class="name">
              {{ file.file.name }}
            </h4>
            <p class="size">
              {{ file.file.size | fileSize }}
            </p>

            <div class="progress-cont">
              <div *ngIf="!file.completed && file.progress < 100">
                <mat-progress-bar mode="determinate" [value]="file.progress"></mat-progress-bar>
                <p>{{ file.progress.toFixed(0) }}%</p>
              </div>
            </div>
            
          </div>
          <div class="delete" (click)="cancelUpload(file)">
            <mat-icon>close</mat-icon>
          </div>
         
         
        </div>
      </div> 
      <p class="error-message" *ngIf="errorMessage">{{ errorMessage }}</p>
    </div>
  </form>
</mat-dialog-content>
<mat-divider></mat-divider>
<mat-dialog-actions>
  <div class="btn-class">
    <button mat-raised-button (click)="closeDialog()">CANCEL</button>
    
    <div>
      <button mat-raised-button color="primary" [disabled]="materialTypeForm.invalid"  *ngIf="!showFileUpload" (click)="handleButtonClick()" type="button">
        AUTO FILL
      </button>
      <button mat-raised-button color="primary" *ngIf="apiInProgress" (click)="handleButtonClick()" type="button">
        AUTO FILL
      </button>
      <button
        mat-raised-button
        color="primary"
        *ngIf="!showFileUpload"
        [disabled]="materialTypeForm.invalid"
        type="submit"
        (click)="onClickSubmit()">
        MANUAL
      </button>
    </div>
  </div>
</mat-dialog-actions>

