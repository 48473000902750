import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';

import { IDialogConfig } from '../../models/dialog-config.modal';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
})
export class ConfirmationDialogComponent {
  public config: IDialogConfig;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(private matDialogRef: MatDialogRef<ConfirmationDialogComponent>, @Inject(MAT_DIALOG_DATA) data: any) {
    this.config = data;
  }

  closeDialog(response?: string): void {
    this.matDialogRef?.close(response);
  }
}
