import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FeaturesRoutingModule } from './features-routing.module';

@NgModule({
  declarations: [
  ],
  imports: [CommonModule, RouterModule, FeaturesRoutingModule],
})
export class FeaturesModule {}
