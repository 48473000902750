<div class="shade-white review-each-wrapper mat-elevation-z3" *ngIf="(enableEditButton && hideShow) || viewForm">
  <div class="row">
    <div class="col-md-11">
      <div class="block-wrapper">
        <h2>{{ title }}</h2>
      </div>
    </div>
  </div>
  <div class="row review-instruction-wrapper">
    <div class="block-wrapper" *ngIf="data?.concreteInstruction">
      <h4 class="bold">Concrete Instructions</h4>
      <p>{{ data.concreteInstruction }}</p>
    </div>
    <div class="block-wrapper" *ngIf="data?.ftbInstruction">
      <h4 class="bold">FTB Instructions</h4>
      <p>{{ data.ftbInstruction }}</p>
    </div>
    <div class="block-wrapper" *ngIf="data?.groutSlurryInstruction">
      <h4 class="bold">Grout/Slurry Instructions</h4>
      <p>{{ data.groutSlurryInstruction }}</p>
    </div>
    <div class="block-wrapper" *ngIf="data?.otherInstruction">
      <h4 class="bold">Other Instructions</h4>
      <p>{{ data.otherInstruction }}</p>
    </div>
    <div class="block-wrapper" *ngIf="data?.additionalCommentsNativeSoil">
      <h4 class="bold">Comments</h4>
      <p>{{ data.additionalCommentsNativeSoil }}</p>
    </div>
  </div>
</div>

<!-- new view submission form  include checkbox-->

<div *ngIf="soiltype">
  <div class="shade-white review-each-wrapper mat-elevation-z3" *ngIf="(enableEditButton && hideShow) || !viewForm">
    <form [formGroup]="form">
      <div class="row">
        <div class="col-md-11">
          <div class="block-wrapper">
            <h2>{{ title }}</h2>
          </div>
        </div>
        <div
          class="no-print col-md-1 btn-wrap r-edit-wraper"
          formGroupName="commentInfo"
          *ngIf="enableEditButton && !hideShow && !viewForm">
          <mat-checkbox color="primary" formControlName="comments"> </mat-checkbox>
        </div>
      </div>
    </form>
    <div class="row review-instruction-wrapper">
      <div class="block-wrapper" *ngIf="data?.concreteInstruction">
        <h4 class="bold">Concrete Instructions</h4>
        <p>{{ data.concreteInstruction }}</p>
      </div>
      <div class="block-wrapper" *ngIf="data?.ftbInstruction">
        <h4 class="bold">FTB Instructions</h4>
        <p>{{ data.ftbInstruction }}</p>
      </div>
      <div class="block-wrapper" *ngIf="data?.groutSlurryInstruction">
        <h4 class="bold">Grout/Slurry Instructions</h4>
        <p>{{ data.groutSlurryInstruction }}</p>
      </div>
      <div class="block-wrapper" *ngIf="data?.otherInstruction">
        <h4 class="bold">Other Instructions</h4>
        <p>{{ data.otherInstruction }}</p>
      </div>

      <div class="block-wrapper" *ngIf="data?.additionalCommentsNativeSoil">
        <h4 class="bold">Comments</h4>
        <p>{{ data.additionalCommentsNativeSoil }}</p>
      </div>
    </div>
  </div>
</div>