/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  private baseUrl = environment.baseUrl;

  constructor(private httpClient: HttpClient) {}

  get(url: string, params?: any): Observable<any> {
    const data = {
      params,
    };
    return this.httpClient.get(this.baseUrl + url, data);
  }

  post(url: string, body?: any, params?: any): Observable<any> {
    const data = {
      params,
    };
    return this.httpClient.post(this.baseUrl + url, body, data);
  }

  put(url: string, body?: any, params?: any): Observable<any> {
    const data = {
      params,
    };
    return this.httpClient.put(this.baseUrl + url, body, data);
  }

  patch(url: string, body?: any, params?: any): Observable<any> {
    const data = {
      params,
    };
    return this.httpClient.patch(this.baseUrl + url, body, data);
  }

  delete(url: string, params?: any): Observable<any> {
    const data = {
      params,
    };
    return this.httpClient.delete(this.baseUrl + url, data);
  }
}
